import { atom } from 'recoil';
import { BulkCreateForAssembliesInput } from '@/__generated__/graphql';

export const commentsState = atom<BulkCreateForAssembliesInput | undefined | null>({
  key: 'commentsState',
  default: undefined,
});

export const openState = atom<boolean>({
  key: 'openState',
  default: false,
});

export const selectedField = atom<string>({
  key: 'seletedField',
  default: '',
});

export const titleState = atom<string>({
  key: 'titleState',
  default: '',
});

export const selectedRiskTab = atom<string>({
  key: 'selectedRiskTab',
  default: '',
});

export const riskView = atom<'calculations' | 'summary' | undefined>({
  key: 'riskView',
  default: undefined,
});

export const editorExpanded = atom<boolean>({
  key: 'editorExpanded',
  default: true,
});
