import { PropsWithChildren, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  FetchResult,
  InMemoryCache,
  Operation,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition, Observable, isNonNullObject } from '@apollo/client/utilities';
import { ApolloError } from '@apollo/client/errors';
import { print } from 'graphql';
import { Client, createClient } from 'graphql-ws';
import Router from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { useUser } from '@auth0/nextjs-auth0';
import * as state from '@/state';
import { extractStatusCodeFromApolloResponse } from '@/utils';

interface LikeCloseEvent {
  readonly code: number;
  readonly reason: string;
}

function isLikeCloseEvent(value: unknown): value is LikeCloseEvent {
  return isNonNullObject(value) && 'code' in value && 'reason' in value;
}

let email = '';
class GraphQLWsLink extends ApolloLink {
  constructor(public readonly client: Client) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((observer) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: observer.next.bind(observer),
          complete: observer.complete.bind(observer),
          error: (error) => {
            if (error instanceof Error) {
              return observer.error(error);
            }

            if (isLikeCloseEvent(error)) {
              return observer.error(
                // reason will be available on clean closes
                new Error(`Socket closed with event ${error.code} ${error.reason || ''}`)
              );
            }

            return observer.error(
              new ApolloError({
                graphQLErrors: Array.isArray(error) ? error : [error],
              })
            );
          },
        }
      );
    });
  }
}

const httpLink = (apiUrl: string) => createHttpLink({ uri: `${apiUrl}/graphql` });

// Create a WebSocket link:
const wsLink = (wsUrl: string, token?: string) => {
  return new GraphQLWsLink(
    createClient({
      url: wsUrl,
      shouldRetry: () => true,
      connectionParams: () => {
        return {
          Authorization: `Bearer ${token}`,
        };
      },
    })
  );
};

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const getLink = (API_URL: string, WS_URL: string, token?: string) => {
  return split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink(WS_URL, token),
    httpLink(API_URL)
  );
};

const authLink = (token?: string) =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

const tracingLink = new ApolloLink((operation, forward) => {
  const transaction = Sentry.startTransaction({
    name: operation.operationName,
    op: 'graphql',
  });

  const { variables } = operation;
  operation.setContext({ transaction });
  const startTime = Date.now();

  return forward(operation).map((response) => {
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    if (transaction && process.env.ENABLE_SENTRY) {
      transaction.setTag('queryParameters', JSON.stringify(variables));
      transaction.setTag('userEmail', email);
      transaction.setTag('timeTakenForRequest', `${timeTaken}ms`);

      transaction.finish();
    }
    return response;
  });
});

const errorLink = onError(({ graphQLErrors }) => {
  const statusCode = extractStatusCodeFromApolloResponse(
    graphQLErrors?.[0]?.extensions?.originalError
  );

  if (!statusCode) {
    return;
  }

  if (statusCode === 401) {
    Router.push('/api/auth/logout');
  }
});

// Some authentication errors are being returned as 200s,
// so we need to check the error message on a successful response
const customResponseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response?.errors?.[0]?.message?.includes('unauthorized_client')) {
      Router.push('/api/auth/logout');
    }
    return response;
  });
});

const custom200ResposeErrorCheckerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const errors = response?.errors;
    if (errors?.length && errors.length > 0) {
      Sentry.captureException(new Error(JSON.stringify(errors)));
    }
    return response;
  });
});

export const ConfiguredApolloClient = ({ children }: PropsWithChildren<{}>) => {
  const token = useRecoilValue(state.auth0TokenState);
  const { user } = useUser();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  email = user?.email!;

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          custom200ResposeErrorCheckerLink,
          customResponseLink,
          errorLink,
          tracingLink,
          authLink(token),
          getLink(process.env.API_URL, process.env.WS_URL, token),
        ]),
        cache: new InMemoryCache(),
        connectToDevTools: true,
        defaultOptions: {
          watchQuery: { fetchPolicy: 'no-cache' },
          query: { fetchPolicy: 'no-cache' },
          mutate: { fetchPolicy: 'no-cache' },
        },
      }),
    [token]
  );

  return token ? <ApolloProvider client={client}>{children}</ApolloProvider> : <>{children}</>;
};
