/*
This function is used to get images in correct state to compile Image Set or Spherical Image set based on the type of image.
Spherical Image is for Normal Sphericals
Images are for images that has a sensor like drone images or Rectangular Images
*/

import {
  BaseImageProps,
  ImageProps,
  SphericalImageAnchorStyles,
  SphericalImageProps,
  ImageAnchorStyles,
  ImageType,
  SphericalImageAnchorStyle,
  ImageAnchorStyle,
} from '@abyss/3d-viewer';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { ANALYSED_VIEWPOINT_COLOR, DEFAULT_COLORS } from '@/constants';
import { getLastArrayItems } from '@/utils';
import { useFindAssemblyForViewPointsQuery } from '@/__generated__/graphql';
import {
  isSphericalAnalysedRelatedToAssembly,
  useAssemblySeenByViewpoints,
} from './useAssemblySeenByViewpoints';
import { foundViewpoints as foundViewpointsState } from '../../modules/findViewpointsTool/state';

export const useImageSets = (structureId: string) => {
  const recoilStructureLocations = useRecoilValue(state.structureLocations);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const viewpointsByAssemblyId = useRecoilValue(state.viewpointsByAssemblyId);
  const visitedViewpointIDs = useRecoilValue(state.visitedViewpointIDs);
  const assemblySeenByViewpoint = useAssemblySeenByViewpoints(selectedAssemblyId);
  const foundViewpoints = useRecoilValue(foundViewpointsState);

  const foundLocationIds = useMemo(() => {
    return new Set<string>(foundViewpoints.map(({ id }) => id));
  }, [foundViewpoints]);

  const { data: assemblyViewpointsData } = useFindAssemblyForViewPointsQuery({
    variables: {
      assemblyId: selectedAssemblyId,
    },
    skip: !selectedAssemblyId,
    fetchPolicy: 'cache-first',
  });

  const selectedAssemblyViewpoints =
    assemblyViewpointsData?.assembly?.seenByViewpoints || undefined;

  const chooseViewpointStyle = useCallback(
    (
      locationID: string,
      imageType: ImageType
    ): SphericalImageAnchorStyles | ImageAnchorStyles | undefined => {
      const lastVisitedViewPointID = getLastArrayItems(visitedViewpointIDs);

      const generateStyle = (
        color?: number,
        opacity?: number
      ): SphericalImageAnchorStyle | ImageAnchorStyle => {
        if (imageType === ImageType.Rectilinear) return { fillColor: color, fillOpacity: opacity };
        return { color, opacity };
      };

      if (foundLocationIds?.has(locationID)) {
        return {
          normal: generateStyle(DEFAULT_COLORS.colors[2], 1),
          viewModeNormal: generateStyle(DEFAULT_COLORS.colors[2], 1),
        };
      }

      if (locationID === lastVisitedViewPointID) {
        return {
          normal: generateStyle(DEFAULT_COLORS.colors[0], 1),
          viewModeNormal: generateStyle(DEFAULT_COLORS.colors[0], 1),
        };
      }

      // Highlight current list of found viewpoints of "Find Viewpoints Tool"
      if (foundLocationIds?.has(locationID)) {
        return {
          normal: {
            color: DEFAULT_COLORS.colors[2],
            opacity: 1,
          },
          viewModeNormal: {
            color: DEFAULT_COLORS.colors[2],
            opacity: 1,
          },
        };
      }

      // Highlight current list of found viewpoints of "Find Viewpoints Tool"
      if (foundLocationIds?.has(locationID)) {
        return {
          normal: {
            color: DEFAULT_COLORS.colors[2],
            opacity: 1,
          },
          viewModeNormal: {
            color: DEFAULT_COLORS.colors[2],
            opacity: 1,
          },
        };
      }

      if (
        // If user is not viewing a single assembly i.e viewing full platform
        !selectedAssemblyId ||
        // If for some reason the viewpoints for the assembly are not fetched
        !selectedAssemblyViewpoints ||
        // If the assembly has no viewpoints
        selectedAssemblyViewpoints.length === 0
      ) {
        // Just return the default color
        return { normal: generateStyle(DEFAULT_COLORS.colors[0]) };
      }

      // Find the current location ID inside the currently selected assembly's seenByViewpoints array
      const currentViewpointFoundInAssemblyViewpoints = selectedAssemblyViewpoints.find(
        (viewpoint) => viewpoint.location.id === locationID
      );

      // If the location was not found, that means it is not part of the assembly
      if (!currentViewpointFoundInAssemblyViewpoints) {
        return { normal: generateStyle(DEFAULT_COLORS.colors[0]) };
      }

      // If its found and it has been analysed, return the analysed color
      if (currentViewpointFoundInAssemblyViewpoints?.analysed) {
        return {
          normal: generateStyle(ANALYSED_VIEWPOINT_COLOR, 1),
          viewModeNormal: generateStyle(ANALYSED_VIEWPOINT_COLOR, 1),
        };
      }

      return undefined;
    },
    [foundLocationIds, selectedAssemblyId, selectedAssemblyViewpoints, visitedViewpointIDs]
  );

  return useMemo(() => {
    const filesBaseUrl = localStorage.getItem('files-base-url');

    const locationsForAssembly = selectedAssemblyId
      ? viewpointsByAssemblyId[selectedAssemblyId]?.map((viewpoint) => {
          return {
            id: viewpoint.location.id,
            analysedBy: isSphericalAnalysedRelatedToAssembly({
              locationId: viewpoint.location.id,
              seenByViewpoints: assemblySeenByViewpoint,
            }),
          };
        })
      : [];

    const rectilinearImages: ImageProps[] = [];
    const sphericalImages: SphericalImageProps[] = [];

    if (
      recoilStructureLocations &&
      Array.isArray(recoilStructureLocations) &&
      recoilStructureLocations.length > 0
    ) {
      recoilStructureLocations.reduce((accumulator, location) => {
        if (
          !(
            !selectedAssemblyId ||
            locationsForAssembly?.some(
              (locationForAssembly) => locationForAssembly.id === location.id
            )
          )
        ) {
          return accumulator;
        }

        if (!location.resourcePath) return accumulator;

        const baseImage: BaseImageProps = {
          id: location.id,
          name: location.name,
          url: filesBaseUrl
            ? `${filesBaseUrl}/${structureId}/images/${location.name}.jpg`
            : getCloudfrontUrl(location.resourcePath),
          position: [location.pose?.x ?? 0, location.pose?.y ?? 0, location.pose?.z ?? 0],
          rotation: [location.pose?.roll ?? 0, location.pose?.pitch ?? 0, location.pose?.yaw ?? 0],
        };

        if (location?.imageSensor) {
          rectilinearImages.push({
            ...baseImage,
            width: location.imageSensor?.width,
            height: location.imageSensor?.height,
            focalLength: location.imageSensor?.focalLength,
            cx: location.imageSensor?.cx,
            cy: location.imageSensor?.cy,
            distance: location?.distance ?? undefined,
            styles: chooseViewpointStyle(location.id, ImageType.Rectilinear),
          });
        } else {
          sphericalImages.push({
            ...baseImage,
            imageYawOffset: location.pose?.yawOffset,
            styles: chooseViewpointStyle(location.id, ImageType.Spherical),
          });
        }
        return accumulator;
      });
    }

    return { sphericalImages, rectilinearImages };
  }, [
    assemblySeenByViewpoint,
    chooseViewpointStyle,
    recoilStructureLocations,
    selectedAssemblyId,
    structureId,
    viewpointsByAssemblyId,
  ]);
};
