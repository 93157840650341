import { useRecoilValue, useSetRecoilState } from 'recoil';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import * as state from '@/state';
import { AddPartsToAssemblyInput, useSetHiddenFlagForPartsMutation } from '@/__generated__/graphql';
import { useAddPartsToAssemblyInputForDeletePartMutation } from '@/__generated__/graphql';
import { StructureRelationships } from '@/types';

export const DeletePartCheckbox = () => {
  const selectedParts = useRecoilValue(state.selectedPartSummaries);
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const setStructureRelationships = useSetRecoilState(state.structureRelationships);
  const [isLoading, setIsLoading] = useState(false);
  const selectedZone = useRecoilValue(state.selectedZone);
  const structureId = useRecoilValue(state.selectedStructureId);
  const untaggedAssembly = useRecoilValue(state.assemblyWithUntaggedTag);
  const deletedAssembly = useRecoilValue(state.assemblyWithDeletedTag);

  const [addPartsToAssembly] = useAddPartsToAssemblyInputForDeletePartMutation();

  const disableCheckbox = useMemo(() => selectedParts.length >= 15, [selectedParts]);

  useEffect(() => setIsLoading(false), [selectedParts]);

  const isChecked = useMemo(() => {
    // multiple selected one is hidden and another is not check box is unticked
    // all are hidden check box is ticked
    // none are hidden check box is unticked
    return !selectedParts.some((selectedPart) => !selectedPart.isHidden);
  }, [selectedParts]);

  const [setHiddenFlagForPartsMutation, { error, loading }] = useSetHiddenFlagForPartsMutation();

  useEffect(() => {
    if (loading) {
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <div>
            <Alert severity="info">Updating out of scope</Alert>
          </div>
        ),
      });
    } else if (error) {
      setIsLoading(false);
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="error">Failed to update the part. Please try again later</Alert>,
      });
    }
  }, [error, loading, setSnackbarMessage]);

  const handleSetHiddenFlagForPart = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!structureId) {
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="error">No structure is selected</Alert>,
      });
      return;
    }
    if (!untaggedAssembly?.id) {
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="error">Untagged assembly does not exist</Alert>,
      });
      return;
    }
    setIsLoading(true);
    const selectedPartIds = selectedParts.map((selectedPart) => selectedPart.id);
    setHiddenFlagForPartsMutation({
      errorPolicy: 'all',
      variables: {
        parts: {
          ids: selectedPartIds,
          isHidden: selectedParts.some((selectedPart) => !selectedPart.isHidden),
          zoneId: selectedZone?.id,
          structureId,
        },
      },
      onCompleted(mutationReturn) {
        const flagsUpdated = mutationReturn.setHiddenForParts;
        const partDisplayName = flagsUpdated.length > 1 ? 'parts' : 'part';
        const isHidden = selectedParts.every((selectedPart) => selectedPart.isHidden === true);
        setSnackbarMessage({
          shouldShow: true,
          content: (
            <Alert severity="success">
              {`Successfully marked ${partDisplayName} as ${
                isHidden ? `in scope` : `out of scope`
              }`}
            </Alert>
          ),
        });

        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }

          const byPartId = currentRelationships.byPartId.asMutableStateMap();

          flagsUpdated.forEach((flag) => {
            const part = byPartId.get(flag);
            if (part) {
              byPartId.set(flag, {
                ...part,
                isHidden: selectedParts.some((selectedPart) => !selectedPart.isHidden),
              });
            }
          });

          const updatedRelationships: StructureRelationships = {
            ...currentRelationships,
            byPartId: byPartId.asStateMap(),
          };
          return updatedRelationships;
        });
      },
    });

    const updatedAssembly = event.target.checked ? deletedAssembly?.id : untaggedAssembly?.id;
    let input: AddPartsToAssemblyInput = {
      partIds: selectedPartIds,
      assemblyId: updatedAssembly || untaggedAssembly?.id,
      structureId,
    };

    if (selectedZone?.id) {
      const zone = { zoneId: selectedZone?.id };
      input = { ...input, ...zone };
    }

    const { data: updatedPartsData } = await addPartsToAssembly({
      variables: {
        input,
      },
    });

    setStructureRelationships((currentRelationships) => {
      if (!currentRelationships) {
        return currentRelationships;
      }

      const byAnnotationId = currentRelationships.byAnnotationId.asMutableStateMap();
      const byPartId = currentRelationships.byPartId.asMutableStateMap();
      const byAssemblyId = currentRelationships.byAssemblyId.asMutableStateMap();

      updatedPartsData?.addPartsToAssembly.forEach((entry) => {
        const oldPart = byPartId.get(entry.id);
        if (oldPart) {
          const part = { ...oldPart };
          part.assemblyId = entry.assembly?.id;
          byPartId.set(entry.id, part);
        }
      });

      const updatedRelationships: StructureRelationships = {
        ...currentRelationships,
        byAnnotationId: byAnnotationId.asStateMap(),
        byPartId: byPartId.asStateMap(),
        byAssemblyId: byAssemblyId.asStateMap(),
      };

      return updatedRelationships;
    });
  };

  const CheckBoxComponent = (
    <Checkbox
      checked={isChecked}
      size="medium"
      color="primary"
      onChange={handleSetHiddenFlagForPart}
      disabled={!selectedParts || isLoading}
    />
  );

  return (
    <FormControlLabel label="Out of scope" control={CheckBoxComponent} disabled={disableCheckbox} />
  );
};
