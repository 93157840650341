/* eslint-disable react/jsx-one-expression-per-line */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { CircularProgress } from '@mui/material';
import * as state from '@/state';

import { Text } from '../styles';
import { useGetLocalAnnotationIdLazyQuery } from '@/__generated__/graphql';
import { AnnotationInfo } from '@/types';
import { mapLocationToCurrentImage } from '@/components/Analysis/Viewer/hooks/useImageFromLocationId';

type SelectedAnnotationProps = {
  selectedAnnotations: (
    | (AnnotationInfo & {
        locationName: string;
      })
    | undefined
  )[];
};

const SelectedAnnotations = ({ selectedAnnotations }: SelectedAnnotationProps) => {
  const [selectedSpherical, setSelectedSpherical] = useRecoilState(state.selectedSpherical);
  const structureLocations = useRecoilValue(state.structureLocations);
  const setSphericalPointCloudMode = useSetRecoilState(state.sphericalPointCloudMode);

  const [localAnnotationId, setLocalAnnotationId] = useState<number | undefined>();

  const [getLocalAnnotationId, { loading }] = useGetLocalAnnotationIdLazyQuery();

  useEffect(() => {
    if (selectedAnnotations.length > 0 && selectedAnnotations[0]?.id) {
      getLocalAnnotationId({
        variables: { annotationId: selectedAnnotations[0]?.id },
      }).then(({ data: annotation }) => {
        setLocalAnnotationId(annotation?.annotation?.processingReferences?.localAnnotationId);
      });
    }
  }, [getLocalAnnotationId, selectedAnnotations, setLocalAnnotationId]);

  const handleSelectedAnnotationSpherical = useCallback(() => {
    const sphericalId = selectedAnnotations[0]?.locationId;
    const location = structureLocations?.find(
      (structureLocation) => structureLocation.id === sphericalId
    );

    if (location && location?.pose && location.id !== selectedSpherical?.id) {
      setSelectedSpherical(mapLocationToCurrentImage(location));
      setSphericalPointCloudMode('None');
    }
  }, [
    selectedAnnotations,
    selectedSpherical,
    setSelectedSpherical,
    setSphericalPointCloudMode,
    structureLocations,
  ]);

  if (loading) {
    return (
      <Text>
        <CircularProgress size={10} />
      </Text>
    );
  }

  if (selectedAnnotations.length === 0) {
    return <Text>(none selected)</Text>;
  }

  if (selectedAnnotations.length === 1) {
    const selectedAnnotationDisplayText = `${selectedAnnotations[0]?.locationName} [${localAnnotationId}] `;

    return (
      <Text isHighlighted onClick={handleSelectedAnnotationSpherical}>
        {selectedAnnotationDisplayText}
      </Text>
    );
  }

  return <Text>(multiple selected)</Text>;
};

export const SelectedLabels = () => {
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const selectedAnnotationIds = useRecoilValue(state.selectedAnnotationIds);
  const locations = useRecoilValue(state.structureLocations);

  const selectedAnnotations = useMemo(() => {
    return selectedAnnotationIds.map((annotationId) => {
      const annotation = structureRelationships?.byAnnotationId.get(annotationId);
      return annotation
        ? {
            ...annotation,
            locationName:
              locations?.find((location) => location.id === annotation.locationId)?.name || '',
          }
        : undefined;
    });
  }, [locations, selectedAnnotationIds, structureRelationships?.byAnnotationId]);

  return (
    <FormControl>
      <FormLabel>Selected label</FormLabel>
      <SelectedAnnotations selectedAnnotations={selectedAnnotations} />
    </FormControl>
  );
};
