import { GenericConfirmationDialog } from '@/components/Analysis/shared/GeneriConfirmationDialog';
import { Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import * as state from './state';

type Props = {
  onConfirm: () => void;
  disabled: boolean;
};
export const RevertConfirmDialog = ({ onConfirm, disabled }: Props) => {
  const [lineFixingState, setLineFixingState] = useRecoilState(state.lineFixingState);

  const onClose = () => {
    setLineFixingState({
      ...lineFixingState,
      state: 'closed',
    });
  };
  const open = lineFixingState.state === 'confirm-revert';

  return (
    <GenericConfirmationDialog
      open={open}
      onClose={onClose}
      title="Revert Line Fixing"
      description="Are you sure you want to revert the line fixing?"
    >
      <Button variant="outlined" onClick={onClose} disabled={disabled}>
        Cancel
      </Button>
      <Button variant="contained" onClick={onConfirm} disabled={disabled}>
        Confirm
      </Button>
    </GenericConfirmationDialog>
  );
};
