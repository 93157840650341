import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import * as state from '@/components/Analysis/state';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';

export const useViewpointPoiHeight = () => {
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);

  const height = useMemo(() => {
    if (!selectedSpherical || !editPointOfInterest?.pointOfInterest) return undefined;

    // Return undefined incase POI was uploaded via CSV
    // and has no 3D coordinates
    if (!editPointOfInterest?.pointOfInterest.centerPoint3d) return undefined;

    return Math.abs(
      editPointOfInterest?.pointOfInterest.centerPoint3d.z - selectedSpherical.position[2]
    );
  }, [selectedSpherical, editPointOfInterest?.pointOfInterest]);

  return height;
};
