import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useViewpointPoiHeight } from '@/components/Analysis/Viewer/hooks/useViewpointPoiHeight';
import { CustomTextField, DropDownField } from '@/components/shared/CustomField';
import { CustomDateField } from '@/components/shared/CustomField/FieldTemplates/CustomDateField';
import { PoiStatus } from '@/__generated__/graphql';
import { PoiAccessibilityEnum, UnitSystemEnum, VisibilityEnum } from '@/__generated__/graphql';
import { UseGeneralFieldsHookType } from '../hooks';
import * as state from '@/components/Analysis/state';
import { AbyssAutoComplete } from '@/components/shared/AbyssAutoComplete';
import { AutoCompleteOption } from '@/types';
import { formatEnumForDisplay } from '@/utils';
import * as poiState from '../../../state';
import { convertMetresToFeetDisplay, getMetresDisplay } from '@/utils/unitSystem';

type Props = {
  dataHook: UseGeneralFieldsHookType;
  teamsLoading?: boolean;
  teamOptions?: AutoCompleteOption[];
  currentPoiType?: string | undefined;
};

export const GeneralFieldsForm = ({
  dataHook,
  teamsLoading,
  teamOptions,
  currentPoiType = 'POI',
}: Props) => {
  const {
    state: {
      assignee,
      dueDate,
      status,
      visibility,
      name,
      poiId,
      teamIds,
      workpackNumber,
      comment,
      accessibility,
    },
    updateValue,
  } = dataHook;
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const heightToPointOfInterest = useViewpointPoiHeight();
  const unitConversionSystem = useRecoilValue(state.unitSystem);
  const isEditing = editPointOfInterest?.formState === 'Update-General';
  const poi = editPointOfInterest?.pointOfInterest;
  const selectedPoiHeight = useMemo(() => {
    const height = poi?.deckRelativeHeight;
    return unitConversionSystem === UnitSystemEnum.Imperial
      ? convertMetresToFeetDisplay(height)
      : getMetresDisplay(height);
  }, [poi, unitConversionSystem]);

  const selectedPoiViewpointRelativeHeight = useMemo(() => {
    if (poi?.createdAt && !poi.viewpointRelativeHeight) return undefined;
    const height = poi?.viewpointRelativeHeight ?? heightToPointOfInterest;
    return unitConversionSystem === UnitSystemEnum.Imperial
      ? convertMetresToFeetDisplay(height)
      : getMetresDisplay(height);
  }, [poi?.createdAt, poi?.viewpointRelativeHeight, heightToPointOfInterest, unitConversionSystem]);

  return (
    <>
      {/* Only show POI ID while editing because POI ID is not available (or editable)
      at the time of POI creation. Its generated on the server side.
      */}
      {isEditing && (
        <CustomTextField
          mode="Edit"
          id="id"
          title={'POI ID'}
          value={poiId}
          sx={{ mt: 2 }}
          disabled
        />
      )}

      <CustomTextField
        mode="Edit"
        id="Name"
        title={`${currentPoiType} name`}
        onFieldValueChange={(value) => updateValue('name', value)}
        value={name}
        sx={{ mt: 2 }}
      />

      <DropDownField
        mode="Edit"
        id="Visibility"
        title="Visibility"
        options={Object.values(VisibilityEnum)}
        value={visibility}
        sx={{ mt: 2 }}
        onFieldValueChange={(value) => updateValue('visibility', value as VisibilityEnum)}
        required
      />
      {visibility === VisibilityEnum.Custom && (
        <AbyssAutoComplete
          variant="standard"
          label="Select Teams"
          loading={teamsLoading}
          options={teamOptions || []}
          selectedValues={teamIds || []}
          handleSelected={(event) => updateValue('teamIds', event)}
          sx={{ mt: 2 }}
          required
        />
      )}
      <CustomDateField
        format="dd MMM yyyy"
        mode="Edit"
        id="Due Date"
        title="Due Date"
        value={dueDate}
        sx={{ mt: 2, mb: 0 }}
        onFieldValueChange={(value) => updateValue('dueDate', value)}
      />

      <DropDownField
        mode="Edit"
        id="Status"
        title="Status"
        options={Object.values(PoiStatus)}
        value={status}
        sx={{ mt: 2 }}
        onFieldValueChange={(value) => updateValue('status', value)}
      />

      <CustomTextField
        mode="Edit"
        id="Assign to"
        title="Assignee"
        value={assignee}
        sx={{ mt: 2 }}
        onFieldValueChange={(value) => updateValue('assignee', value)}
      />

      <CustomTextField
        mode="Edit"
        id="Workpack No"
        title="Workpack No."
        value={workpackNumber}
        sx={{ mt: 2 }}
        onFieldValueChange={(value) => updateValue('workpackNumber', value)}
      />

      <CustomTextField
        mode="Edit"
        id="Comment"
        title="Comment"
        value={comment}
        sx={{ mt: 2 }}
        onFieldValueChange={(value) => updateValue('comment', value)}
      />

      <CustomTextField
        mode="ReadOnly"
        id="deckRelativeHeight"
        title="POI Height from Deck"
        value={selectedPoiHeight ?? 'N/A'}
        sx={{ mt: 4 }}
      />
      <CustomTextField
        mode="ReadOnly"
        id="viewpointDistance"
        title="POI Height from Viewpoint"
        value={selectedPoiViewpointRelativeHeight ?? 'N/A'}
        sx={{ mt: 2 }}
      />

      <DropDownField
        mode="Edit"
        id="Accessibility"
        title="Accessibility"
        options={Object.values(PoiAccessibilityEnum).map(
          (item) => formatEnumForDisplay(item) || ''
        )}
        value={formatEnumForDisplay(accessibility)}
        sx={{ mt: 2 }}
        onFieldValueChange={(value) => updateValue('accessibility', value)}
      />
    </>
  );
};
