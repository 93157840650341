import Typography from '@mui/material/Typography';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Box, Stack } from '@mui/material';

type ProgressBarWithTextProps = {
  text?: string;
  direction?: 'row' | 'column';
};

export const ProgressBarWithText = (props: LinearProgressProps & ProgressBarWithTextProps) => {
  const { value, text, direction = 'row' } = props;
  return (
    <Stack direction={direction} spacing={1} alignItems="center" my={1}>
      <Box flex={1} width="100%">
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box flex={1}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value || 0)}% ${text || ''}`}
        </Typography>
      </Box>
    </Stack>
  );
};
