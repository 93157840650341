import { CreatedByDetails, ZoneAndHistory, ZoneStatus } from '@/__generated__/graphql';

// This determines if the user should be redirected to the tasks page
// after changing the status of a zone
export const shouldRedirectToTasks = (
  previousStatus: ZoneStatus | undefined,
  newStatus: ZoneStatus
) => {
  if (!previousStatus) return false;

  if (previousStatus === ZoneStatus.InProgress && newStatus === ZoneStatus.ReviewReady) return true;
  if (previousStatus === ZoneStatus.InReview && newStatus === ZoneStatus.Completed) return true;
  if (previousStatus === ZoneStatus.Completed && newStatus === ZoneStatus.ReviewReady) return true;
  if (previousStatus === ZoneStatus.Completed && newStatus === ZoneStatus.InProgress) return true;

  return false;
};

export const getNextZoneStatus = (zoneStatus: ZoneStatus): ZoneStatus | undefined => {
  switch (zoneStatus) {
    case ZoneStatus.NotStarted:
      // Case: Start worflow for a zone i.e move it to InProgress
      return ZoneStatus.InProgress;
    case ZoneStatus.InProgress:
      // Case: Another user assigns themselves to the zone
      // while the zone is still in progress
      return ZoneStatus.InProgress;
    case ZoneStatus.InReview:
      return ZoneStatus.InReview;
    case ZoneStatus.ReviewReady:
      return ZoneStatus.InReview;
    default:
      break;
  }
  return undefined;
};

export const getAssigneeFromHistory = (zone: ZoneAndHistory): CreatedByDetails | undefined => {
  if (zone.status === ZoneStatus.NotStarted) return undefined;

  const { histories } = zone;

  if (!histories || histories.length === 0) return undefined;

  const latestHistory = histories.at(-1);

  return latestHistory?.createdByDetails || undefined;
};

type ZoneStatusMapping = {
  [key in ZoneStatus]: string | undefined;
};

export const ZoneStatusMapper: ZoneStatusMapping = {
  [ZoneStatus.Completed]: 'Completed',
  [ZoneStatus.InProgress]: 'In Progress',
  [ZoneStatus.NotStarted]: 'Not Started',
  [ZoneStatus.InReview]: 'In Review',
  [ZoneStatus.ReviewReady]: 'Ready for Review',
};
