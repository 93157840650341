import { SxProps } from '@mui/material';

export const GenericConfirmationDialogStyles: SxProps = {
  minHeight: '15%',
  width: '30%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: 2,
};
