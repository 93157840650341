import { abyssColors } from '@/theme/colors';

export const tableCellStyle = {
  border: `1px solid ${abyssColors.primary[100]}`,
  height: '50px',
  padding: 1,
  textAlign: 'left',
};

export const nominateButtonStyles = {
  fontSize: 10,
  fontWeight: 400,
  padding: '4px 8px',
  width: '80px',
  height: '30px',
  borderRadius: '4px',
  textTransform: 'none',
};
