import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CuboidProps } from '@abyss/3d-viewer';
import { toNumber3 } from '@/utils/geometry';
import * as state from '@/state';
import * as taggingState from '@/components/Inspection/Tagging/state';
import {
  AddUpdateSplitCuboidOnPartInput,
  useAddUpdateSplitCuboidOnPartMutation,
  RemoveSplitCuboidOnPartInput,
  useRemoveSplitCuboidOnPartMutation,
} from '@/__generated__/graphql';
import { CuboidData } from '@/types';
import { makeCuboidStyle } from './useCreateCuboid';

export const makeCuboidPropsFromCuboidData = (cuboid: CuboidData, color: number): CuboidProps => {
  const { id, position, scale, rotation } = cuboid;
  const style = makeCuboidStyle(color);
  return {
    id,
    name: id,
    position: toNumber3(position),
    scale: toNumber3(scale),
    rotation: toNumber3(rotation),
    style,
  };
};

export const useCuboids = (isolatedPartId: string | undefined) => {
  const splitCuboidsByPartId = useRecoilValue(taggingState.splitCuboidsByPartId);
  const [cuboids, setCuboids] = useRecoilState(taggingState.isolatedPartCuboids);

  useEffect(() => {
    if (!splitCuboidsByPartId) return;
    if (!isolatedPartId) {
      setCuboids([]);
      return;
    }
    setCuboids(splitCuboidsByPartId.get(isolatedPartId) ?? []);
  }, [isolatedPartId, setCuboids, splitCuboidsByPartId]);

  const [addUpdateSplitCuboidOnPartMutation, { loading: updateLoading, error: updateError }] =
    useAddUpdateSplitCuboidOnPartMutation();

  const [removeSplitCuboidOnPartMutation, { loading: removeLoading, error: removeError }] =
    useRemoveSplitCuboidOnPartMutation();

  const selectedStructureId = useRecoilValue(state.selectedStructureId);
  const selectedZone = useRecoilValue(state.selectedZone);

  const addUpdateCuboid = useCallback(
    (cuboid: CuboidData) => {
      if (!isolatedPartId) return;
      if (!selectedStructureId) return;

      const input: AddUpdateSplitCuboidOnPartInput = {
        partId: isolatedPartId,
        splitCuboid: cuboid,
        structureId: selectedStructureId,
        zoneId: selectedZone?.id,
      };

      addUpdateSplitCuboidOnPartMutation({
        variables: {
          input,
        },
      });

      const hasCuboid = cuboids.reduce(
        (peviousHasCuboid, { id }) => id === cuboid.id || peviousHasCuboid,
        false
      );
      if (hasCuboid) {
        setCuboids(cuboids.map((oldCuboid) => (oldCuboid.id === cuboid.id ? cuboid : oldCuboid)));
      } else {
        setCuboids([...cuboids, cuboid]);
      }
    },
    [
      cuboids,
      setCuboids,
      addUpdateSplitCuboidOnPartMutation,
      isolatedPartId,
      selectedStructureId,
      selectedZone?.id,
    ]
  );

  const removeCuboid = useCallback(
    (cuboid: CuboidData) => {
      if (!isolatedPartId) return;
      if (!selectedStructureId) return;
      if (!cuboid.id) return;

      const input: RemoveSplitCuboidOnPartInput = {
        partId: isolatedPartId,
        splitCuboidId: cuboid.id,
        structureId: selectedStructureId,
        zoneId: selectedZone?.id,
      };

      removeSplitCuboidOnPartMutation({
        variables: {
          input,
        },
      });

      setCuboids((oldCuboids) => {
        return oldCuboids.filter(({ id }) => id !== cuboid.id);
      });
    },
    [
      setCuboids,
      removeSplitCuboidOnPartMutation,
      isolatedPartId,
      selectedStructureId,
      selectedZone?.id,
    ]
  );

  return {
    addUpdateCuboid,
    removeCuboid,
    updateLoading,
    updateError,
    removeLoading,
    removeError,
  };
};
