import React, { SyntheticEvent, useEffect, useCallback } from 'react';
import { VisibilityBoxMode, VisibilityBoxProps } from '@abyss/3d-viewer';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { useZonesForStructureForLabellingQuery } from '@/__generated__/graphql';
import { Zone } from '@/types';
import * as state from '@/state';
import { DockPanel } from '@/components/shared/DockPanel';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import { DemarcationOfZones } from './DemarcationOfZones';
import { DEFAULT_VISIBILITY_BOX } from '@/constants';

export const Navigation = () => {
  const router = useRouter();

  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const structureIdFromURL = router?.query?.inspection as string;
  const structureId = useRecoilValue(state.selectedStructureId) || structureIdFromURL || '';

  const setZones = useSetRecoilState(state.zones);
  const setNormalVisibilityRange = useSetRecoilState(state.normalVisibilityRange);

  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const setCurrentVisibilityBox = useSetRecoilState(state.currentVisibilityBox);
  const setShouldShowDemarcationOfZones = useSetRecoilState(state.shouldShowDemarcationOfZones);
  const setZoneSegmentsHeight = useSetRecoilState(state.zoneSegmentsHeight);

  const [selectedZone, setSelectedZone] = useRecoilState(state.selectedZone);

  const { data } = useZonesForStructureForLabellingQuery({
    variables: {
      structureId,
    },
  });

  useEffect(() => {
    if (data?.zonesForStructure) {
      const firstZone = data?.zonesForStructure[0] || [];
      if (firstZone?.box?.min?.z && firstZone?.box?.max?.z) {
        setZoneSegmentsHeight(firstZone.box.max.z || firstZone.box.min.z);
      }
      setZones(data.zonesForStructure);
    }
  }, [data?.zonesForStructure, setZones, setZoneSegmentsHeight]);

  useEffect(() => {
    const visibilityBox = selectedZone?.box;
    if (visibilityBox) {
      const vBoxFromZone: VisibilityBoxProps = {
        min: [visibilityBox.min.x, visibilityBox.min.y, visibilityBox.min.z],
        max: [visibilityBox.max.x, visibilityBox.max.y, visibilityBox.max.z],
        mode: VisibilityBoxMode.Enabled,
        step: [0.25, 0.25, 0.1],
      };
      setCurrentVisibilityBox(vBoxFromZone);
      setNormalVisibilityRange('ZoneOnly');

      const center = vBoxFromZone.step.reduce<number[]>((previous, __, index) => {
        return [...previous, (vBoxFromZone.min[index] + vBoxFromZone.max[index]) / 2];
      }, []);

      if (center.length === 3) {
        setCameraTarget({
          position: [center[0], center[1] + 15, center[2] + 5],
          lookAt: [center[0], center[1], center[2]],
        });
      }
      setShouldShowDemarcationOfZones(false);
    } else {
      setCurrentVisibilityBox(DEFAULT_VISIBILITY_BOX);
      setNormalVisibilityRange('All');
    }
  }, [
    selectedZone,
    setCameraTarget,
    setCurrentVisibilityBox,
    setNormalVisibilityRange,
    setShouldShowDemarcationOfZones,
  ]);

  useEffect(() => {
    const zoneIdFromParameters = router?.query?.zoneid;
    if (zoneIdFromParameters) {
      const zone = data?.zonesForStructure?.find((z) => z.id === zoneIdFromParameters);
      if (zone) {
        setSelectedZone(zone);
      }
    }
  }, [data?.zonesForStructure, router?.query?.zoneid, setSelectedZone]);

  useEffect(() => {
    if (data?.zonesForStructure) {
      const firstZone = data?.zonesForStructure[0] || [];
      if (firstZone?.box?.min?.z && firstZone?.box?.max?.z) {
        setZoneSegmentsHeight(firstZone.box.max.z || firstZone.box.min.z);
      }
      setZones(data.zonesForStructure);
    }
  }, [data?.zonesForStructure, setZoneSegmentsHeight, setZones]);

  const handleZoneChanged = useCallback(
    (_: SyntheticEvent<Element, Event> | undefined, zone: Zone | null) => {
      setSelectedZone(zone);
      if (zone?.id) {
        // This is needed because in labelling data loader, we cannot use
        // selectedzone atom as it changes its state too often,
        // so, this helps us prevent re-renders in data loader
        router.push({
          pathname: '/inspection/[inspection]',
          query: {
            inspection: structureId,
            zoneid: zone.id,
          },
        });
      }
    },
    [setSelectedZone, structureId, router]
  );

  const handleAutoCompleteChange = useCallback(
    (_: SyntheticEvent<Element, Event> | undefined, newZone: Zone | null) => {
      handleZoneChanged(undefined, newZone);
    },
    [handleZoneChanged]
  );

  return (
    <DockPanel>
      <Autocomplete
        id="zone-selector"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={data?.zonesForStructure || []}
        getOptionLabel={(option) => option.name}
        defaultValue={selectedZone}
        value={selectedZone}
        disableListWrap
        autoHighlight
        disabled={!!selectedSpherical}
        onChange={handleAutoCompleteChange}
        renderInput={(parameters) => (
          <DockPanelItem>
            <TextField {...parameters} label="Zone" variant="standard" />
          </DockPanelItem>
        )}
      />
      {selectedSpherical && (
        <>
          <DockPanelItem>
            <TextField
              label="Image viewpoint"
              variant="standard"
              fullWidth
              value={selectedSpherical.name}
              disabled
            />
          </DockPanelItem>
          <DockPanelItem>
            <Typography>ESC to exit image viewpoint</Typography>
          </DockPanelItem>
        </>
      )}
      {selectedZone ? <></> : <DemarcationOfZones />}
    </DockPanel>
  );
};
