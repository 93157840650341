import { Box, Button, Skeleton } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect } from 'react';
import * as state from '@/components/Analysis/state';
import { textButtonStyle } from './styles';
import {
  UnitSystemEnum,
  useGetUserPreferencesForUnitSelectionQuery,
  GetUserPreferencesForUnitSelectionDocument,
  useUpdatePreferencesMutation,
} from '@/__generated__/graphql';

export const UnitSelection = () => {
  const [unitSystem, setSelectedUnit] = useRecoilState(state.unitSystem);

  const { data, loading } = useGetUserPreferencesForUnitSelectionQuery({fetchPolicy: 'cache-first'});
  const [updatePreferencesMutation] = useUpdatePreferencesMutation();

  useEffect(() => {
    const unit = data?.getUserPreferences?.unit;
    if (unit) {
      setSelectedUnit(unit);
    }
  }, [data, setSelectedUnit]);

  const handleUpdateUnitSystem = useCallback(
    (unit: UnitSystemEnum) => async () => {
      await updatePreferencesMutation({
        variables: {
          input: {
            unit,
          },
        },
        refetchQueries: [GetUserPreferencesForUnitSelectionDocument],
      });
    },
    [updatePreferencesMutation]
  );

  if (loading) {
    return (
      <>
        <Skeleton />
      </>
    );
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#EDF2F9', borderRadius: '4px', p: 0.5 }}>
        <Button
          variant={unitSystem === UnitSystemEnum.Imperial ? 'contained' : 'text'}
          sx={textButtonStyle}
          onClick={handleUpdateUnitSystem(UnitSystemEnum.Imperial)}
        >
          Imperial
        </Button>
        <Button
          variant={unitSystem === UnitSystemEnum.Metric ? 'contained' : 'text'}
          sx={textButtonStyle}
          onClick={handleUpdateUnitSystem(UnitSystemEnum.Metric)}
        >
          Metric
        </Button>
      </Box>
    </>
  );
};
