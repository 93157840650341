import { useCallback, useEffect, useMemo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { SxProps, Box, Typography, FormGroup, FormControlLabel } from '@mui/material';
import { CheckboxAccordian } from '../../../../../../shared/CheckBoxAccordian';
import { PoiAccordionProps } from '@/types';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { generatePoiDisplayName } from '../../SelectedPoiContent/utils';
import { Maybe } from '@/__generated__/graphql';

type PoiCheckedCallBackParameter = {
  id: string;
  name: string;
  isChecked: boolean;
};

type PoiDataProperty = {
  id: string;
  name: string;
  poiId?: Maybe<string> | undefined;
  type: string;
  equipmentName: string;
  poiCheckCallBack: (parameters: PoiCheckedCallBackParameter) => void;
};

const rootPoiStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '8%',
  marginRight: '5%',
};

const PoiData = ({
  name,
  id,
  poiId,
  type,
  equipmentName,
  poiCheckCallBack,
  allPoiChecked,
  unCheckedPois,
}: PoiDataProperty & { allPoiChecked: boolean; unCheckedPois: string[] | undefined }) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckChange = useCallback(() => {
    setChecked((previousValue) => {
      poiCheckCallBack({
        id,
        name,
        isChecked: !previousValue,
      });
      return !previousValue;
    });
  }, [poiCheckCallBack, id, name]);

  useEffect(() => {
    if (allPoiChecked) setChecked(true);
    if (!allPoiChecked && unCheckedPois?.includes(id)) setChecked(false);
  }, [allPoiChecked, id, unCheckedPois]);

  const poiName = generatePoiDisplayName({
    name,
    poiId,
    poiType: type,
  });

  return (
    <Box sx={rootPoiStyles}>
      <FormGroup sx={{ mr: 'auto' }}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleCheckChange} />}
          label={
            <Typography mr="auto" fontWeight={400}>
              {poiName}
            </Typography>
          }
        />
      </FormGroup>
      <Typography variant="subtitle1" fontWeight={100}>
        {equipmentName}
      </Typography>
    </Box>
  );
};

export const PoiAccordion = ({
  summary,
  numericIndicator,
  openByDefault,
  groupedPOI,
  setSelectedPointOfInterests,
  selectedPointOfInterests,
}: PoiAccordionProps) => {
  const [allPoiChecked, setAllPoiChecked] = useState<boolean>(false);

  const poiCheckCallBack = useCallback(
    ({ id, name, isChecked }: PoiCheckedCallBackParameter) => {
      // if isChecked then push to SelectedPointOfInterest
      // Otherwise filter the id
      if (isChecked) {
        setSelectedPointOfInterests((poi) => {
          return [
            ...poi,
            {
              id,
              name,
            },
          ];
        });
      } else {
        setSelectedPointOfInterests((poi) => {
          return poi.filter((item) => {
            return item.id !== id;
          });
        });
      }
    },
    [setSelectedPointOfInterests]
  );

  const pointOfInterestData: PoiDataProperty[] = useMemo(() => {
    if (!groupedPOI || groupedPOI.length === 0) return [];
    return groupedPOI.map((poi) => {
      return {
        id: poi.id,
        name: poi.name || NOT_APPLICABLE_LABEL,
        type: poi.type,
        poiId: poi.poiId,
        equipmentName: poi.assembly.tagName,
        poiCheckCallBack,
      };
    });
  }, [groupedPOI, poiCheckCallBack]);

  const areAllPoiCheckedAndTheUncheckedPois = useMemo(() => {
    if (pointOfInterestData.length === 0 || !selectedPointOfInterests) {
      return { areAllPoiChecked: false, unCheckedPois: undefined };
    }
    const pointOfInterestIds = pointOfInterestData.map((poi) => poi.id).sort();
    const selectedPointOfInterestIds = new Set(
      selectedPointOfInterests.map((poi) => poi.id).sort()
    );

    const areAllPoiChecked = pointOfInterestIds.every((id) => selectedPointOfInterestIds.has(id));
    const unCheckedPois = pointOfInterestIds.filter((id) => !selectedPointOfInterestIds.has(id));

    return { areAllPoiChecked, unCheckedPois };
  }, [pointOfInterestData, selectedPointOfInterests]);

  const allPoiCheck = useCallback(() => {
    if (pointOfInterestData.length === 0) return;
    pointOfInterestData.forEach((poi) => {
      poiCheckCallBack({
        id: poi.id,
        name: poi.name,
        isChecked: !allPoiChecked,
      });
    });
  }, [allPoiChecked, poiCheckCallBack, pointOfInterestData]);

  useEffect(() => {
    setAllPoiChecked(areAllPoiCheckedAndTheUncheckedPois.areAllPoiChecked);
  }, [selectedPointOfInterests, areAllPoiCheckedAndTheUncheckedPois, setAllPoiChecked]);

  return (
    <CheckboxAccordian
      summary={summary}
      numericIndicator={numericIndicator}
      openByDefault={openByDefault}
      handleChecked={allPoiCheck}
      checked={allPoiChecked}
    >
      <Box
        sx={{
          backgroundColor: '#EDEDED',
        }}
      >
        {pointOfInterestData.length === 0 && <>{`No Point of Interest Found for ${summary}`}</>}
        {pointOfInterestData.length > 0 && (
          <>
            {pointOfInterestData.map((data) => (
              <PoiData
                key={data.id}
                {...data}
                allPoiChecked={allPoiChecked}
                unCheckedPois={areAllPoiCheckedAndTheUncheckedPois.unCheckedPois}
              />
            ))}
          </>
        )}
      </Box>
    </CheckboxAccordian>
  );
};
