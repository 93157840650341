import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  AbyssViewerNoContext as AbyssViewerComponent,
  PointCloudMaterialProps,
  PointPickResult,
  SphericalImageProps,
  SphericalImageViewerClickEvent,
  MarkerProps,
  PolygonProps,
  FloorLevelProps,
  AbyssViewerComponentProps,
  MeasurementLinesProps,
  UnitSystemEnum,
  ImageProps,
  ImageSetProps,
  SphericalImageSetProps,
  useAbyssViewerContext,
  VisibilityBoxProps,
  SvgMarkerSetProps,
} from '@abyss/3d-viewer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { MenuBar } from './MenuBar';

import * as state from '@/components/Analysis/state';
import * as globalState from '@/state';
import { poiState } from '@/components/Analysis/modules/pointOfInterest';
import { CUBE_PADDING_X, CUBE_PADDING_Y, SPHERICAL_IMAGE_VISIBILITY_RANGE } from '@/constants';
import { useDrawerOpen } from '@/hooks/useDrawerOpen';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { calculateCubePosition, getLastTwoArrayItems } from '@/utils';
import { FabricErrorBoundary } from '@/components/shared/FabricErrorBoundary';
import {
  updateCameraTargetUrlParameters,
  useQueryParametersFromState,
  useSetStateFromQueryParameters,
} from '@/components/Analysis/deeplink';
import { PageLoader } from '@/components/shared/PageLoader';
import { LowFpsPopupTrigger } from '../LowFpsPopupTrigger';
import { useViewerPerformanceAlert } from '@/hooks/useViewerPerformanceAlert';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';
import { ShadowBar } from './MenuBar/styles';
import { usePaintRegionLazyQuery } from '@/__generated__/graphql';

// const cubePadding = 24;

type Props = {
  children: React.ReactNode;
  assemblyLoading: boolean;
  octreePointCloudsJsonPath: {
    path: string;
    name?: string | string[];
    nodesToShow?: Set<string>;
    contents: {
      'part-id'?: boolean;
      'corrosion-id'?: boolean;
      'rusting-id'?: boolean;
      color?: boolean;
    }[];
  }[];
  onPointSelect?: (pickResult: PointPickResult) => void;
  onPointCloudSelection?: (pointPickResults: PointPickResult[]) => void;
  onSphericalViewClick?: (event: SphericalImageViewerClickEvent) => void;
  pointCloudMaterials: Map<string, PointCloudMaterialProps>;
  sphericalImages?: SphericalImageProps[];
  rectilinearImages?: ImageProps[];
  floorLevels: FloorLevelProps[];
  selectionColors?: { shiftKeyColor: number; altKeyColor: number };
  markers?: MarkerProps[];
  svgMarkerSets?: SvgMarkerSetProps[];
  polygons?: PolygonProps[];
  measurementLines?: MeasurementLinesProps;
  material?: string;
} & JSX.IntrinsicAttributes;

const canvasProps = {
  style: {
    background: '#263238',
    width: '100%',
    height: '100%',
  },
  gl: { antialias: true, preserveDrawingBuffer: true },
};

const keyCheck = (event: KeyboardEvent) => event.altKey && event.code === 'KeyA';

export const AbyssViewer = ({
  children,
  assemblyLoading,
  octreePointCloudsJsonPath,
  onPointSelect,
  onPointCloudSelection,
  onSphericalViewClick,
  pointCloudMaterials,
  sphericalImages,
  rectilinearImages,
  selectionColors,
  markers,
  svgMarkerSets,
  polygons,
  material = 'annotation', // Default material is annotation
  floorLevels,
  measurementLines,
}: Props) => {
  const { callbacks } = useAbyssViewerContext();
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const cameraTarget = useRecoilValue(state.cameraTarget);
  const selectedSpherical = useRecoilState(state.selectedSpherical);
  const currentVisibilityBox = useRecoilValue(state.currentVisibilityBox);
  const [applyFilters, setApplyFilters] = useRecoilState(state.applyFilters);
  const selectedPaintRegionIds = useRecoilValue(state.selectedFilteredPaintRegionIds);
  const areSphericalsVisible = useRecoilValue(state.areSphericalsVisible);
  const edlStrength = useRecoilValue(globalState.edlStrength);
  const isDrawerOpen = useDrawerOpen('right');
  const [enableViewpoints, setEnableViewpoints] = useRecoilState(state.enableViewpoints);
  const setAreSphericalsVisible = useSetRecoilState(state.areSphericalsVisible);
  const setGlobalCallbacks = useSetRecoilState(state.globalCallbacks);

  const [updatedVisibilityBox, setUpdatedVisibilityBox] = useState<VisibilityBoxProps | undefined>(
    undefined
  );

  const setVisitedViewpointIDs = useSetRecoilState(state.visitedViewpointIDs);

  const [currentSpherical, setCurrentSpherical] = useRecoilState(state.viewerCurrentSpherical);
  const [nextCurrentSpherical, setNextCurrentSpherical] = useRecoilState(
    state.viewerNextCurrentSpherical
  );
  const unitSystem = useRecoilValue(state.unitSystem);
  const isBetaUser = useFeatureFlag('beta-user');

  const blisterToAdd = useRecoilValue(poiState.blisterToAdd);
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const outerDivStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'relative',
    ...((blisterToAdd && blisterToAdd.state === 'WaitingForCenter') ||
    (blisterToAdd && blisterToAdd.state === 'WaitingForEdge') ||
    (editPointOfInterest && editPointOfInterest.state === 'WaitingForPoint')
      ? { cursor: 'crosshair' }
      : {}),
  };

  const [isInitialPointCloudLoaded, setIsInitialPointCloudLoaded] = useState(false);

  const screenSize = useWindowSize();

  useSetStateFromQueryParameters(isInitialPointCloudLoaded && !!sphericalImages);
  useQueryParametersFromState();

  const cubeSize = useMemo(() => {
    const { width } = screenSize;
    if (width && width <= 1728) {
      return 40;
    }
    if (width && width <= 1440) {
      return 50;
    }
    return 60;
  }, [screenSize]);

  const cubePadding = useMemo(() => {
    const { width } = screenSize;
    if (width && width <= 1728) {
      return 52;
    }
    return 24;
  }, [screenSize]);
  const [fetchPaintRegionVolume] = usePaintRegionLazyQuery();

  useEffect(() => {
    setApplyFilters(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (applyFilters) {
      if (selectedPaintRegionIds.length === 1) {
        fetchPaintRegionVolume({
          variables: {
            paintRegionId: selectedPaintRegionIds[0],
          },
        }).then(({ data }) => {
          if (data?.paintRegion?.volume) {
            const xValues = data?.paintRegion?.volume.inclusion.xy_polygon.map((item) => item[0]);
            const yValues = data?.paintRegion?.volume.inclusion.xy_polygon.map((item) => item[1]);

            const boxMinX = Math.min(...xValues);
            const boxMaxX = Math.max(...xValues);
            const boxMinY = Math.min(...yValues);
            const boxMaxY = Math.max(...yValues);
            const boxMinZ = data?.paintRegion?.volume?.inclusion.z_range[0];
            const boxMaxZ = data?.paintRegion?.volume?.inclusion.z_range[1];

            setUpdatedVisibilityBox({
              min: [boxMinX, boxMinY, boxMinZ],
              max: [boxMaxX, boxMaxY, boxMaxZ],
              mode: 1,
              step: [0.25, 0.25, 0.1],
            });
          }
        });
      } else {
        setUpdatedVisibilityBox(undefined);
      }
    }
  }, [applyFilters, fetchPaintRegionVolume, selectedPaintRegionIds]);

  useEffect(() => {
    // Entering image viewpoint
    if (currentSpherical) {
      setVisitedViewpointIDs((currentVisitedViewpointIDs) => {
        const lastTwoVisitedViewpoints = getLastTwoArrayItems(currentVisitedViewpointIDs);
        // Making sure only the last two visited viewpoints are stored
        if (!lastTwoVisitedViewpoints) {
          return [...currentVisitedViewpointIDs, currentSpherical?.id ?? ''];
        }
        if (lastTwoVisitedViewpoints.length === 1) {
          return [lastTwoVisitedViewpoints[0], currentSpherical?.id ?? ''];
        }
        if (lastTwoVisitedViewpoints.length > 1) {
          return [lastTwoVisitedViewpoints[1], currentSpherical?.id ?? ''];
        }
        return currentVisitedViewpointIDs;
      });
    } else {
      // Exiting image viewpoint
      if (enableViewpoints !== undefined) setAreSphericalsVisible(enableViewpoints);
      setEnableViewpoints(undefined);
      cancelPOIAdd(undefined);
      setVisitedViewpointIDs((previous) => [...previous, previous?.[previous.length - 1]]);
    }
  }, [
    currentSpherical,
    setVisitedViewpointIDs,
    enableViewpoints,
    setAreSphericalsVisible,
    setEnableViewpoints,
    cancelPOIAdd,
  ]);

  const { rightDrawerWidth } = useDrawerWidths();

  const positionMemo: [number, number, number] = useMemo(() => {
    const { width, height } = screenSize;

    if (!width || !height) {
      return [
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        CUBE_PADDING_X - rightDrawerWidth,
        CUBE_PADDING_Y,
        0,
      ];
    }

    const [x, y, z] = calculateCubePosition({ width, height, cubeSize, padding: cubePadding });

    if (isDrawerOpen) {
      return [x - rightDrawerWidth, y, z];
    }
    return [x, y, z];
  }, [cubePadding, cubeSize, isDrawerOpen, rightDrawerWidth, screenSize]);

  const pointClouds = useMemo(
    () =>
      octreePointCloudsJsonPath?.map(({ path, name, nodesToShow }) => ({
        name: `Line ${name}`,
        id: `Octree Point Cloud - ${path}`,
        url: path,
        material,
        isVisible: true,
        nodesToShow,
      })),
    [material, octreePointCloudsJsonPath]
  );

  useEffect(() => {
    if (callbacks) {
      setGlobalCallbacks(callbacks);
    }
  }, [callbacks, setGlobalCallbacks]);

  const centerOnLoad = useCallback(() => {
    setIsInitialPointCloudLoaded(true);
    if (!cameraTarget && callbacks?.resetCameraPositionToDefault) {
      callbacks.resetCameraPositionToDefault();
    }
  }, [setIsInitialPointCloudLoaded, cameraTarget, callbacks]);

  const imageSets = useMemo<ImageSetProps[]>(() => {
    return [
      {
        id: 'imageSet',
        name: 'imageSet',
        images: rectilinearImages ?? [],
        isVisible: rectilinearImages && areSphericalsVisible,
      },
    ];
  }, [rectilinearImages, areSphericalsVisible]);

  const sphericalImageSets = useMemo<SphericalImageSetProps[]>(() => {
    return [
      {
        id: 'sphericalImageSet',
        name: 'sphericalImageSet',
        sphericalImages: sphericalImages ?? [],
        isVisible: sphericalImages && areSphericalsVisible,
      },
    ];
  }, [sphericalImages, areSphericalsVisible]);

  const viewerUnitSystem = useMemo(() => {
    return {
      METRIC: UnitSystemEnum.METRIC,
      IMPERIAL: UnitSystemEnum.IMPERIAL,
    }[unitSystem];
  }, [unitSystem]);
  // TODO: update colors and font size of cube and compass
  const abyssViewerComponentProps: AbyssViewerComponentProps = useMemo(
    () => ({
      selectionBoxIsEnabled: false,
      onInitialPointCloudLoad: centerOnLoad,
      cameraTarget,
      pointCloudMaterials,
      pointClouds,
      edlStrength,
      enableEDL: !!edlStrength,
      visibilityBox: updatedVisibilityBox || currentVisibilityBox,
      viewCube: {
        position: positionMemo,
        cubeSize,
        fontSize: 60,
        colors: {
          foreground: '#000',
          background: '#CCC',
          hoverForeground: '#000',
          hoverBackground: '#44AAFF',
        },
        compass: {
          ringColor: '#CCC',
          letterStyle: { fontSize: 18, color: '#CCC', strokeColor: '#111', strokeWidth: 1 },
        },
      },
      floorLevels,
      selectionColors,
      showPointCloudsInSphericalView: true,
      imageSets,
      sphericalImageSets,
      sphericalImageVisibilityRange: SPHERICAL_IMAGE_VISIBILITY_RANGE,
      fileLoaderOptions: { withCredentials: true },
      enableLoaderRetry: true,
      polygons,
      markers,
      svgMarkerSets,
      measurementLines,
      onPointCloudSelection,
      onPointCloudClick: onPointSelect,
      currentImage: currentSpherical,
      setCurrentImage: setCurrentSpherical,
      nextCurrentImage: nextCurrentSpherical,
      setNextCurrentImage: setNextCurrentSpherical,
      onSphericalViewClick,
      unitSystem: viewerUnitSystem,
    }),
    [
      cameraTarget,
      centerOnLoad,
      cubeSize,
      currentSpherical,
      currentVisibilityBox,
      edlStrength,
      floorLevels,
      imageSets,
      markers,
      measurementLines,
      nextCurrentSpherical,
      onPointCloudSelection,
      onPointSelect,
      onSphericalViewClick,
      pointCloudMaterials,
      pointClouds,
      polygons,
      positionMemo,
      selectionColors,
      setCurrentSpherical,
      setNextCurrentSpherical,
      sphericalImageSets,
      svgMarkerSets,
      updatedVisibilityBox,
      viewerUnitSystem,
    ]
  );

  const updateCameraTarget = useCallback(() => {
    if (callbacks?.getCameraPosition) {
      const currentCameraTarget = callbacks?.getCameraPosition();
      if (currentCameraTarget) {
        updateCameraTargetUrlParameters(currentCameraTarget);
      }
    }
  }, [callbacks]);

  const [displayAnimationEditor, setDisplayAnimationEditor] = useRecoilState(
    state.displayAnimationEditor
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (keyCheck(event)) {
        setDisplayAnimationEditor((oldValue) => !oldValue);
      }
    },
    [setDisplayAnimationEditor]
  );

  const { performanceAlert, onMajorPerformanceCaveat } = useViewerPerformanceAlert();

  return (
    <div style={outerDivStyle} tabIndex={1} onKeyPress={handleKeyPress}>
      {!isInitialPointCloudLoaded || (assemblyLoading && <PageLoader background="dark" />)}
      {selectedSpherical ? (
        <ShadowBar>
          <MenuBar updateCameraTarget={updateCameraTarget} />
        </ShadowBar>
      ) : (
        <MenuBar updateCameraTarget={updateCameraTarget} />
      )}

      <FabricErrorBoundary logToSentry>
        {performanceAlert}
        <AbyssViewerComponent
          canvasProps={canvasProps}
          abyssViewerComponentProps={abyssViewerComponentProps}
          displayAnimationEditor={displayAnimationEditor}
          onMajorPerformanceCaveat={onMajorPerformanceCaveat}
        >
          {children}
        </AbyssViewerComponent>
        {isBetaUser && <LowFpsPopupTrigger />}
      </FabricErrorBoundary>
    </div>
  );
};
