import { UnitSystemEnum } from '@/__generated__/graphql';
import { NOT_APPLICABLE_LABEL } from '../constants';
import { TemplateFieldTypeEnum } from '../types';

export const METERS_TO_INCHES_FACTOR = 39.37007874;
export const INCHES_TO_METERS_FACTOR = 0.0254;
export const METERS_TO_MILLIMETERS_FACTOR = 1000;
export const MILLIMETERS_TO_METERS_FACTOR = 0.001;
export const SQFEET_TO_SQMETERS_FACTOR = 10.7639;

export const convertMetresToFeetDisplay = (
  metres?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 2
): string | undefined | null => {
  if (metres === null || metres === undefined) {
    return metres;
  }

  // Convert to feet
  const feet = metres * 3.28084;
  const feetDisplay = `${feet.toFixed(roundToDecimal)}`;
  return displayUnit ? `${feetDisplay}ft` : feetDisplay;
};

export const convertMetresSqToFeetSqDisplay = (
  metres?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 2
): string | undefined | null => {
  if (metres === null || metres === undefined) {
    return metres;
  }

  // Convert to feet sq
  const feet = metres * 10.764;
  const feetDisplay = `${feet.toFixed(roundToDecimal)}`;
  return displayUnit ? `${feetDisplay}ft²` : feetDisplay;
};

export const convertMetresToInchesDisplay = (
  metres?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 3
): string | undefined | null => {
  if (metres === null || metres === undefined) {
    return metres;
  }

  const inches = metres * METERS_TO_INCHES_FACTOR;
  return displayUnit ? `${inches.toFixed(roundToDecimal)}"` : inches.toFixed(roundToDecimal);
};

export const convertMillimetersToInchesDisplay = (
  millimeters?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 3
): string | undefined | null => {
  if (millimeters === null || millimeters === undefined) {
    return millimeters;
  }

  // Convert to Inch
  const inch = millimeters * 0.0393701;
  return displayUnit ? `${inch.toFixed(roundToDecimal)}"` : inch.toFixed(roundToDecimal);
};

export const convertSquareFeetToSquareMetersDisplay = (
  squareFeet?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 3
): string | undefined | null => {
  if (squareFeet === null || squareFeet === undefined) {
    return squareFeet;
  }

  // Convert to Square Meters
  const squareMeters = squareFeet * 0.092903;
  return displayUnit
    ? `${squareMeters.toFixed(roundToDecimal)} m²`
    : squareMeters.toFixed(roundToDecimal);
};

export const getMetresSquareDisplay = (
  metres?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 2
): string | undefined | null => {
  if (metres === null || metres === undefined) {
    return metres;
  }

  return displayUnit ? `${metres.toFixed(roundToDecimal)}m²` : metres.toFixed(roundToDecimal);
};

export const convertMetresToMillimeterDisplay = (
  meter?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 2
): string | undefined | null => {
  if (meter === null || meter === undefined) {
    return meter;
  }

  // Convert to millimeter
  const millimeter = meter * METERS_TO_MILLIMETERS_FACTOR;
  return displayUnit
    ? `${millimeter.toFixed(roundToDecimal)}mm`
    : millimeter.toFixed(roundToDecimal);
};

export const convertInchestoMetresDisplay = (inch?: number): number => {
  if (!inch) {
    return 0;
  }

  const metres = inch * 0.0254; // 1 inch = 0.0254 meters
  return metres;
};

export const convertInchestoFeetDisplay = (inch?: number): number => {
  if (!inch) {
    return 0;
  }

  const feet = inch / 12; // 1 inch = 1/12 feet
  return feet;
};

export const getMetresDisplay = (
  metres?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 2
): string | undefined | null => {
  if (metres === null || metres === undefined) {
    return metres;
  }

  return displayUnit ? `${metres.toFixed(roundToDecimal)}m` : metres.toFixed(roundToDecimal);
};

export const getMillimetresDisplay = (
  millimetres?: number | null,
  displayUnit: boolean = true,
  roundToDecimal: number = 2
): string | undefined | null => {
  if (millimetres === null || millimetres === undefined) {
    return millimetres;
  }

  return displayUnit
    ? `${millimetres.toFixed(roundToDecimal)}mm`
    : millimetres.toFixed(roundToDecimal);
};

export const convertInchestoMillimetersDisplay = (inch?: number): string | undefined | null => {
  if (inch === null || inch === undefined) {
    return inch;
  }

  const meters = convertInchestoMetresDisplay(inch);
  const millimeter = convertMetresToMillimeterDisplay(meters);
  return millimeter;
};

export const localiseMillimetreMeasurement = (
  unitSystem: UnitSystemEnum,
  measurement?: number | null,
  displayUnit: boolean = true,
  roundToDecimal?: number | undefined
): string | undefined | null => {
  if (measurement === null || measurement === undefined) {
    return measurement;
  }

  if (unitSystem === UnitSystemEnum.Imperial) {
    return convertMillimetersToInchesDisplay(measurement, displayUnit, roundToDecimal);
  }
  if (unitSystem === UnitSystemEnum.Metric) {
    return getMillimetresDisplay(measurement, displayUnit, roundToDecimal);
  }
  return undefined;
};

export const localiseAreaMeasurement = (
  unitSystem: UnitSystemEnum,
  measurement?: number | null,
  displayUnit: boolean = true,
  roundToDecimal?: number | undefined
): string | undefined | null => {
  if (measurement === null || measurement === undefined) {
    return measurement;
  }

  // check if measurement is actually a number
  if (Number.isNaN(measurement)) {
    return 'N/A';
  }

  if (unitSystem === UnitSystemEnum.Imperial) {
    return convertMetresSqToFeetSqDisplay(measurement, displayUnit, roundToDecimal);
  }
  if (unitSystem === UnitSystemEnum.Metric) {
    return getMetresSquareDisplay(measurement, displayUnit, roundToDecimal);
  }
  return undefined;
};

export const localiseBlisterHeight = (
  unitSystem: UnitSystemEnum,
  measurement?: number | null,
  displayUnit: boolean = true,
  roundToDecimal?: number | undefined
): string | undefined | null => {
  if (measurement === null || measurement === undefined) {
    return measurement;
  }
  if (measurement === 0) {
    return undefined;
  }
  if (unitSystem === UnitSystemEnum.Imperial) {
    return convertMetresToInchesDisplay(measurement, displayUnit, roundToDecimal);
  }
  if (unitSystem === UnitSystemEnum.Metric) {
    return convertMetresToMillimeterDisplay(measurement, displayUnit, roundToDecimal);
  }
  return undefined;
};

export const localiseLineDistanceMeasurement = (
  unitSystem: UnitSystemEnum,
  measurement?: number | null,
  displayUnit: boolean = true,
  roundToDecimal?: number | undefined
): string | undefined | null => {
  if (measurement === null || measurement === undefined) {
    return measurement;
  }
  if (measurement === 0) {
    return undefined;
  }
  if (unitSystem === UnitSystemEnum.Imperial) {
    return convertMetresToFeetDisplay(measurement, displayUnit, roundToDecimal);
  }
  if (unitSystem === UnitSystemEnum.Metric) {
    return getMetresDisplay(measurement, displayUnit, roundToDecimal);
  }
  return undefined;
};

export const formatTemplateFieldValue = (
  type: TemplateFieldTypeEnum | undefined,
  value: string,
  unitConversionSystem: UnitSystemEnum,
  customFallbackLabel?: string
) => {
  if (value === null || value === undefined || value?.length === 0) {
    return customFallbackLabel ?? NOT_APPLICABLE_LABEL;
  }

  if (type === TemplateFieldTypeEnum.MeasurementPaintLength) {
    return unitConversionSystem === UnitSystemEnum.Imperial
      ? String(convertMetresToFeetDisplay(Number(value)))
      : `${Number(value).toFixed(2)} m`;
  }
  if (type === TemplateFieldTypeEnum.MeasurementPaintDiameter) {
    return `${(Number(value) * METERS_TO_INCHES_FACTOR).toFixed(2)} in`;
  }
  if (type === TemplateFieldTypeEnum.MeasurementPaintArea) {
    return unitConversionSystem === UnitSystemEnum.Imperial
      ? `${convertMetresSqToFeetSqDisplay(Number(value))} ft²`
      : `${Number(value).toFixed(2)} m²`;
  }
  return value;
};
