import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  ControlsType,
  CuboidStyle,
  CuboidVariants,
  Number3,
  TransformMode,
} from '@abyss/3d-viewer';
import * as cuboidState from '../state';
import { AnalysisCuboid, CuboidClickMode } from '@/types';
import { generateMongoObjectId } from '@/utils/databaseHelpers';

export const makeCuboidStyle = (rgb: number): CuboidStyle => ({
  variant: CuboidVariants.LinesAndTransparentMesh,
  lineColor: rgb,
  lineWidth: 1,
  lineOpacity: 0.8,
  fillColor: rgb,
  fillOpacity: 0.02,
});

export const useCreateAnalysisCuboid = () => {
  const setCurrentCuboid = useSetRecoilState(cuboidState.currentCuboid);
  const setIsCuboidEditorEnabled = useSetRecoilState(cuboidState.isCuboidEditorEnabled);
  const setCuboidControlsType = useSetRecoilState(cuboidState.cuboidControlsType);
  const setCuboidTransformMode = useSetRecoilState(cuboidState.cuboidTransformMode);
  const setCuboidClickMode = useSetRecoilState(cuboidState.cuboidClickMode);

  // TODO: Implement a check later using default class from structure
  const canCreateCuboid = true;

  const createCuboid = useCallback(
    (position: Number3) => {
      const id = generateMongoObjectId();

      const newCuboid: AnalysisCuboid = {
        id,
        position,
        scale: [1, 1, 1],
        rotation: [0, 0, 0],
      };
      // Enable edit mode for the new cuboid
      setCurrentCuboid(newCuboid);
      setCuboidControlsType(ControlsType.AlignedBox);
      setCuboidTransformMode(TransformMode.Translate);
      setIsCuboidEditorEnabled(true);
      setCuboidClickMode(CuboidClickMode.Default);
    },
    [
      setCurrentCuboid,
      setCuboidControlsType,
      setCuboidTransformMode,
      setIsCuboidEditorEnabled,
      setCuboidClickMode,
    ]
  );

  return { canCreateCuboid, createCuboid };
};
