import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Fragment, useEffect, useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Alert from '@mui/material/Alert';
import * as Sentry from '@sentry/nextjs';

import * as state from '@/state';
import * as taggingState from '@/components/Inspection/Tagging/state';
import {
  useGetStructureRelationshipsForTaggingInspectionDataLoaderQuery,
  useGetStructureLocationsForTaggingDataLoaderQuery,
  usePartsAddedToAssemblySubscription,
  usePartsHiddenFlagUpdatedSubscription,
  usePartsReviewedFlagUpdatedSubscription,
  usePartSplitSubscription,
  useAssemblyTagNameAddedSubscription,
  useSplitCuboidOnPartUpdatedSubscription,
  useSplitCuboidOnPartRemovedSubscription,
  SplitCuboid,
} from '@/__generated__/graphql';

import {
  AnnotationId,
  AnnotationInfo,
  AssemblyId,
  AssemblyInfo,
  PartId,
  PartInfo,
  StructureRelationships,
} from '@/types';
import { StateMap } from '@/utils/stateMap';
import { SentryTransactionNames } from '@/utils/sentryTransactions';
import { useBatchedSubscription, useSharedTimeout } from '@/utils/batchedSubscriptions';
import { NO_TAG_ASSEMBLY_NAME } from '@/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LoadingSpinnerContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(80vh / var(--zoom));
`;

export const InspectionDataLoader = ({ ...props }) => {
  const { user } = useUser();

  const router = useRouter();
  const { inspection } = router.query;

  const setInspectionMetadata = useSetRecoilState(state.inspectionMetadata);
  const setStructureLocations = useSetRecoilState(state.structureLocations);
  // TODO: toString() may cause issues
  const inspectionString = inspection?.toString();
  const structureId = useRecoilValue(state.selectedStructureId) || inspectionString || '';
  const setStructureRelationships = useSetRecoilState(state.structureRelationships);
  const setSplitCuboidsByPartId = useSetRecoilState(taggingState.splitCuboidsByPartId);
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const selectedZone = useRecoilValue(state.selectedZone);
  const isSubscriptionsEnabled = useRecoilValue(state.isSubscriptionsEnabled);
  /**
   * On slower devices you will see a blank screen after structureRelationshipsQueryResult
   * loads because the request is finished but the main thread is busy constructing
   * the internal state. This ready state will wait for that too.
   */
  const [ready, setReady] = useState<boolean>(false);

  const structureRelationshipsQueryResult = useGetStructureRelationshipsForTaggingInspectionDataLoaderQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'no-cache',
  });

  const structureLocationsQueryResult = useGetStructureLocationsForTaggingDataLoaderQuery({
    variables: {
      structureId,
    },
  });

  // all subscription events will be batched for this period of time and processed together
  const triggerDelayedCall = useSharedTimeout(1000);

  const updatedPartsBatch = useBatchedSubscription(
    usePartsAddedToAssemblySubscription({
      variables: {
        structureId,
        userId: user?.sub!,
      },
    }).data,
    triggerDelayedCall
  );
  const partsHiddenFlagUpdatedBatch = useBatchedSubscription(
    usePartsHiddenFlagUpdatedSubscription({
      variables: {
        structureId,
        userId: user?.sub!,
      },
    }).data,
    triggerDelayedCall
  );
  const partsReviewedFlagUpdatedBatch = useBatchedSubscription(
    usePartsReviewedFlagUpdatedSubscription({
      variables: {
        structureId,
        userId: user?.sub!,
      },
    }).data,
    triggerDelayedCall
  );
  const partSplitBatch = useBatchedSubscription(
    usePartSplitSubscription({
      variables: {
        structureId,
        userId: user?.sub!,
      },
    }).data,
    triggerDelayedCall
  );
  const newlyAddedAssemblyBatch = useBatchedSubscription(
    useAssemblyTagNameAddedSubscription({
      variables: {
        structureId,
      },
    }).data,
    triggerDelayedCall
  );

  const splitCuboidOnPartUpdatedBatch = useBatchedSubscription(
    useSplitCuboidOnPartUpdatedSubscription({
      variables: {
        structureId,
        userId: user?.sub!,
      },
    }).data,
    triggerDelayedCall
  );

  const splitCuboidOnPartRemovedBatch = useBatchedSubscription(
    useSplitCuboidOnPartRemovedSubscription({
      variables: {
        structureId,
        userId: user?.sub!,
      },
    }).data,
    triggerDelayedCall
  );

  useEffect(() => {
    if (structureLocationsQueryResult.data?.allLocations) {
      setStructureLocations(structureLocationsQueryResult.data?.allLocations);
    }
  }, [setStructureLocations, structureLocationsQueryResult.data?.allLocations]);

  /*
  This handles the first time structure relationships is loaded
  by transforming the data to a format that will be easier for the 3d viewer to use
*/
  useEffect(() => {
    const transaction = Sentry.startTransaction({
      name: SentryTransactionNames.BUILDING_STRUCTURE_RELATIONSHIP,
    });
    const relationships: StructureRelationships = {
      byAnnotation3dReference: new StateMap<number, AnnotationId>(),
      byAnnotationId: new StateMap<AnnotationId, AnnotationInfo>(),
      byPartId: new StateMap<PartId, PartInfo>(),
      byAssemblyId: new StateMap<AssemblyId, AssemblyInfo>(),
    };

    // const start = performance.now();

    if (structureRelationshipsQueryResult.data) {
      const byAssemblyId = structureRelationshipsQueryResult.data.allAssemblies.reduce(
        (accumulator, current) => {
          const { id, tagName, isNewlyAdded, __typename: typename, originalTagname, superCategory } = current;
          accumulator.set(id, {
            id,
            tagName,
            originalTagname: originalTagname || undefined,
            superCategory: superCategory || undefined,
            isNewlyAdded: isNewlyAdded === null ? undefined : isNewlyAdded,
            __typename: typename,
          });
          return accumulator;
        },
        relationships.byAssemblyId.asMutableStateMap()
      );

      const byPartId = structureRelationshipsQueryResult.data.allParts.reduce(
        (accumulator, current) => {
          const { id, assemblyId, class: classs, isReviewed, isHidden } = current;
          accumulator.set(id, {
            assemblyId,
            class: classs,
            isReviewed,
            isHidden,
            annotationIds: new Set<AnnotationId>(),
          });
          return accumulator;
        },
        relationships.byPartId.asMutableStateMap()
      );

      setSplitCuboidsByPartId(
        structureRelationshipsQueryResult.data.allParts
          .reduce((accumulator, current) => {
            const { id, splitCuboids } = current;
            accumulator.set(id, splitCuboids ?? []);
            return accumulator;
          }, new StateMap<PartId, SplitCuboid[]>().asMutableStateMap())
          .asStateMap()
      );

      const byAnnotationId = relationships.byAnnotationId.asMutableStateMap();
      const byAnnotation3dReference = relationships.byAnnotation3dReference.asMutableStateMap();

      structureRelationshipsQueryResult.data.allAnnotations.forEach((annotation) => {
        const {
          annotationId: annotation3dReference,
          partId: annotationPartId,
          id: annotationId,
          locationId,
        } = annotation;
        if (annotationPartId) {
          const part = byPartId.get(annotationPartId);

          if (!part) {
            Sentry.captureMessage(`Part data not found on initial load`, {
              user: { sub: user?.sub },
              extra: { annotationId, partId: annotationPartId },
            });
            return;
          }

          const { assemblyId } = part;
          const assembly = assemblyId ? byAssemblyId.get(assemblyId) : undefined;

          if (!assembly) {
            Sentry.captureMessage(`Assembly data not found on initial load`, {
              user: { sub: user?.sub },
              extra: { annotationId, partId: annotationPartId, assemblyId },
            });
            return;
          }

          const { annotationIds } = part;
          annotationIds.add(annotationId);

          byAnnotationId.set(annotationId, {
            annotation3dReference,
            partId: annotationPartId,
            locationId,
            id: annotationId,
          });

          byAnnotation3dReference.set(annotation3dReference, annotationId);
        }
      });

      relationships.byAnnotationId = byAnnotationId.asStateMap();
      relationships.byPartId = byPartId.asStateMap();
      relationships.byAssemblyId = byAssemblyId.asStateMap();
      relationships.byAnnotation3dReference = byAnnotation3dReference.asStateMap();
      setReady(true);
    }
    // console.log(`Initial structureRelationships effect took: ${performance.now() - start}ms`);

    setStructureRelationships(relationships);
    transaction?.finish();
  }, [
    setStructureRelationships,
    structureRelationshipsQueryResult.data,
    user?.sub,
    setSplitCuboidsByPartId,
  ]);

  useEffect(() => {
    const transaction = Sentry.startTransaction({
      name: SentryTransactionNames.PART_SPLIT_EVENT_RECEIVED,
    });
    partSplitBatch.consumeEvents().forEach((partSplitItem) => {
      if (!partSplitItem) return;
      const { partSplit: partSplitData } = partSplitItem;
      if (!selectedZone?.id || (selectedZone?.id && partSplitData.zoneId === selectedZone?.id)) {
        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }

          const { newPartId, originalPartId, updatedAnnotationIds } = partSplitData;

          const byAssemblyId = currentRelationships.byAssemblyId.asMutableStateMap();
          const byPartId = currentRelationships.byPartId.asMutableStateMap();
          const byAnnotationId = currentRelationships.byAnnotationId.asMutableStateMap();

          const originalPart = byPartId.get(originalPartId);
          let newPart = byPartId.get(newPartId);
          if (!newPart) {
            if (originalPart) {
              newPart = {
                ...originalPart,
                annotationIds: new Set<AnnotationId>(),
              };
              byPartId.set(newPartId, newPart);
            } else {
              Sentry.captureMessage(`Part split: original part not found`, {
                user: { sub: user?.sub },
                extra: { partSplitData },
              });
              return currentRelationships;
            }
          }

          let newAssembly: AssemblyInfo | undefined;
          if (newPart?.assemblyId) {
            newAssembly = byAssemblyId.get(newPart?.assemblyId);
            if (!newAssembly) {
              Sentry.captureMessage(`Part split: new assembly not found`, {
                user: { sub: user?.sub },
                extra: { newAssemblyId: newPart?.assemblyId, partSplitData },
              });
            }
          }

          updatedAnnotationIds.forEach((annotationId) => {
            const annotation = byAnnotationId.get(annotationId);
            if (annotation) {
              // remove from old part
              const oldPart = byPartId.get(annotation.partId);
              oldPart?.annotationIds.delete(annotationId);

              if (newPart) {
                // add annotations to new part
                newPart.annotationIds.add(annotationId);
                // update annotation assembly
                byAnnotationId.set(annotationId, {
                  ...annotation,
                  partId: newPartId,
                });
              }
            }
          });

          return {
            ...currentRelationships,
            byPartId: byPartId.asStateMap(),
            byAnnotationId: byAnnotationId.asStateMap(),
          };
        });
      }
      setSnackbarMessage({
        shouldShow: isSubscriptionsEnabled,
        content: <Alert severity="info">Part successfully split</Alert>,
      });
    });
    transaction?.finish();
  }, [
    setStructureRelationships,
    setSnackbarMessage,
    partSplitBatch.consumeEvents,
    user?.sub,
    selectedZone?.id,
    partSplitBatch,
    isSubscriptionsEnabled,
  ]);

  useEffect(() => {
    const transaction = Sentry.startTransaction({
      name: SentryTransactionNames.PART_HIDDEN_FLAG_UPDATED_EVENT_RECEIVED,
    });
    partsHiddenFlagUpdatedBatch.consumeEvents().forEach((partsHiddenFlagUpdatedItem) => {
      if (!partsHiddenFlagUpdatedItem) return;
      const { partsHiddenFlagUpdated: flagsUpdated } = partsHiddenFlagUpdatedItem;
      if (
        !selectedZone?.id ||
        (selectedZone?.id && flagsUpdated.length > 0 && flagsUpdated[0].zoneId === selectedZone?.id)
      ) {
        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }

          const byPartId = currentRelationships.byPartId.asMutableStateMap();

          flagsUpdated.forEach((flag) => {
            const { id: partId, isHidden, isReviewed } = flag;
            const part = byPartId.get(partId);
            if (part) {
              byPartId.set(partId, {
                ...part,
                isReviewed,
                isHidden,
              });
            } else {
              Sentry.captureMessage(`Part isHidden update: part not found`, {
                user: { sub: user?.sub },
                extra: { flag },
              });
            }
          });

          const updatedRelationships: StructureRelationships = {
            ...currentRelationships,
            byPartId: byPartId.asStateMap(),
          };
          return updatedRelationships;
        });

        const firstUpdatedFlag = flagsUpdated[0];
        const partDisplayName = flagsUpdated.length > 1 ? 'parts' : 'part';
        const message = `Successfully marked ${partDisplayName} as ${
          firstUpdatedFlag.isHidden ? `out of scope` : `in scope`
        }`;
        if (firstUpdatedFlag) {
          setSnackbarMessage({
            shouldShow: isSubscriptionsEnabled,
            content: <Alert severity="info">{message}</Alert>,
          });
        }
        transaction?.finish();
      }
    });
  }, [
    setStructureRelationships,
    partsHiddenFlagUpdatedBatch.consumeEvents,
    setSnackbarMessage,
    selectedZone?.id,
    user?.sub,
    partsHiddenFlagUpdatedBatch,
    isSubscriptionsEnabled,
  ]);

  useEffect(() => {
    const transaction = Sentry.startTransaction({
      name: SentryTransactionNames.ASSEMBLY_PARTS_UPDATED_EVENT,
    });
    partsReviewedFlagUpdatedBatch.consumeEvents().forEach((partsReviewedFlagUpdatedItem) => {
      if (!partsReviewedFlagUpdatedItem) return;
      const { partsReviewedFlagUpdated: partsUpdated } = partsReviewedFlagUpdatedItem;
      if (
        !selectedZone?.id ||
        (selectedZone?.id && partsUpdated.length > 0 && partsUpdated[0].zoneId === selectedZone?.id)
      ) {
        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }

          const byPartId = currentRelationships.byPartId.asMutableStateMap();

          partsUpdated.forEach((updatedPart) => {
            const part = byPartId.get(updatedPart.id);
            if (part) {
              byPartId.set(updatedPart.id, {
                ...part,
                isReviewed: updatedPart.isReviewed,
              });
            } else {
              Sentry.captureMessage(`Part isReviewed update: part not found`, {
                user: { sub: user?.sub },
                extra: { updatedPart },
              });
            }
          });

          const updatedRelationships: StructureRelationships = {
            ...currentRelationships,
            byPartId: byPartId.asStateMap(),
          };

          return updatedRelationships;
        });
      }
      if (partsUpdated.length === 0) return;

      const firstUpdatedPart = partsUpdated[0];
      const firstUpdatedPartTagName = firstUpdatedPart.assemblyName;
      const partDisplayName =
        partsUpdated.length > 1
          ? 'multiple parts'
          : firstUpdatedPartTagName && firstUpdatedPartTagName !== NO_TAG_ASSEMBLY_NAME
            ? firstUpdatedPartTagName
            : 'part';
      const message = firstUpdatedPart.isReviewed
        ? `Reviewed ${partDisplayName}`
        : `Removed review for ${partDisplayName}`;

      setSnackbarMessage({
        shouldShow: isSubscriptionsEnabled,
        content: <Alert severity="info">{message}</Alert>,
      });
    });
    transaction?.finish();
  }, [
    setStructureRelationships,
    partsReviewedFlagUpdatedBatch.consumeEvents,
    setSnackbarMessage,
    selectedZone?.id,
    user?.sub,
    partsReviewedFlagUpdatedBatch,
    isSubscriptionsEnabled,
  ]);

  useEffect(() => {
    if (structureRelationshipsQueryResult.data?.structure) {
      setInspectionMetadata(structureRelationshipsQueryResult.data?.structure);
    }
  }, [setInspectionMetadata, structureRelationshipsQueryResult.data?.structure]);

  useEffect(() => {
    const transaction = Sentry.startTransaction({
      name: SentryTransactionNames.PART_ADDED_TO_ASSEMBLY_EVENT,
    });
    // const start = performance.now();
    updatedPartsBatch.consumeEvents().forEach((updatedPartsData) => {
      if (
        !updatedPartsData?.partsAddedToAssembly ||
        updatedPartsData.partsAddedToAssembly.length === 0
      ) {
        return;
      }
      if (
        !selectedZone?.id ||
        (selectedZone?.id &&
          updatedPartsData?.partsAddedToAssembly.length !== 0 &&
          updatedPartsData?.partsAddedToAssembly[0].zoneId === selectedZone?.id)
      ) {
        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }

          // Update structureRelationships
          //     - update byPartId[each updated part].assemblyId
          //     - update byAnnotationId[each annotation id of each updated part].assemblyId

          const byAnnotationId = currentRelationships.byAnnotationId.asMutableStateMap();
          const byPartId = currentRelationships.byPartId.asMutableStateMap();
          const byAssemblyId = currentRelationships.byAssemblyId.asMutableStateMap();

          updatedPartsData.partsAddedToAssembly.forEach((entry) => {
            const { partId, assemblyId } = entry;
            const oldPart = byPartId.get(partId);
            if (oldPart) {
              const part = { ...oldPart };
              part.assemblyId = assemblyId;
              if (part.annotationIds.size > 0) {
                part.annotationIds.forEach((annotationId) => {
                  const annotation = byAnnotationId.get(annotationId);
                  if (annotation) {
                    byAnnotationId.set(annotationId, {
                      ...annotation,
                    });
                  }
                });
              } else {
                Sentry.captureMessage(`Part has no annotation ids in local state`, {
                  user: { sub: user?.sub },
                  extra: { partId },
                });
              }
              byPartId.set(partId, part);
            } else {
              Sentry.captureMessage(`Part does not exist in local state`, {
                user: { sub: user?.sub },
                extra: { partId },
              });
            }
          });

          const updatedRelationships: StructureRelationships = {
            ...currentRelationships,
            byAnnotationId: byAnnotationId.asStateMap(),
            byPartId: byPartId.asStateMap(),
            byAssemblyId: byAssemblyId.asStateMap(),
          };

          return updatedRelationships;
        });
      }
      const newAssemblyName = updatedPartsData.partsAddedToAssembly[0]?.assemblyName;
      const message =
        !newAssemblyName || newAssemblyName === NO_TAG_ASSEMBLY_NAME
          ? 'Removed equipment tag'
          : `Updated equipment tag to ${newAssemblyName}`;
      setSnackbarMessage({
        shouldShow: isSubscriptionsEnabled,
        content: <Alert severity="info">{message}</Alert>,
      });
    });
    // console.log('Part assignment effect took:', performance.now() - start);
    transaction?.finish();
  }, [
    setSnackbarMessage,
    setStructureRelationships,
    updatedPartsBatch.consumeEvents,
    user?.sub,
    selectedZone?.id,
    updatedPartsBatch,
    isSubscriptionsEnabled,
  ]);

  useEffect(() => {
    newlyAddedAssemblyBatch.consumeEvents().forEach((newlyAddedAssembly) => {
      if (!newlyAddedAssembly) return;
      if (
        newlyAddedAssembly?.assemblyTagNameAdded &&
        newlyAddedAssembly?.assemblyTagNameAdded?.isNewlyAdded
      ) {
        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }
          if (
            newlyAddedAssembly.assemblyTagNameAdded?.id &&
            newlyAddedAssembly.assemblyTagNameAdded.tagName
          ) {
            const byAssemblyId = currentRelationships.byAssemblyId.asMutableStateMap();
            const { id, tagName, isNewlyAdded } = newlyAddedAssembly.assemblyTagNameAdded;
            byAssemblyId.set(id, {
              id,
              tagName,
              isNewlyAdded,
              __typename: 'Assembly',
            });

            const updatedRelationships: StructureRelationships = {
              ...currentRelationships,
              byAssemblyId: byAssemblyId.asStateMap(),
            };
            return updatedRelationships;
          }
          return currentRelationships;
        });
      }
    });
  }, [newlyAddedAssemblyBatch, newlyAddedAssemblyBatch.consumeEvents, setStructureRelationships]);

  useEffect(() => {
    splitCuboidOnPartUpdatedBatch.consumeEvents().forEach((entry) => {
      if (!entry) return;
      const { splitCuboidOnPartUpdated } = entry;

      setSplitCuboidsByPartId((current) => {
        if (!current) {
          return current;
        }
        const splitCuboidsByPartId = current.asMutableStateMap();

        splitCuboidOnPartUpdated.forEach((notification) => {
          const { partId, splitCuboid } = notification;
          const partCuboids = splitCuboidsByPartId.get(partId) ?? [];
          const hasCuboid = partCuboids.reduce(
            (peviousHasCuboid, { id }) => id === splitCuboid.id || peviousHasCuboid,
            false
          );
          if (hasCuboid) {
            splitCuboidsByPartId.set(
              partId,
              partCuboids.map((oldCuboid) =>
                oldCuboid.id === splitCuboid.id ? splitCuboid : oldCuboid
              )
            );
          } else {
            splitCuboidsByPartId.set(partId, [...partCuboids, splitCuboid]);
          }
        });
        return splitCuboidsByPartId.asStateMap();
      });
    });
  }, [
    splitCuboidOnPartUpdatedBatch,
    splitCuboidOnPartUpdatedBatch.consumeEvents,
    setSplitCuboidsByPartId,
  ]);

  useEffect(() => {
    splitCuboidOnPartRemovedBatch.consumeEvents().forEach((entry) => {
      if (!entry) return;
      const { splitCuboidOnPartRemoved } = entry;

      setSplitCuboidsByPartId((current) => {
        if (!current) {
          return current;
        }
        const splitCuboidsByPartId = current.asMutableStateMap();

        splitCuboidOnPartRemoved.forEach((notification) => {
          const { partId, splitCuboidId } = notification;
          const partCuboids = splitCuboidsByPartId.get(partId) ?? [];
          splitCuboidsByPartId.set(
            partId,
            partCuboids.filter((oldCuboid) => oldCuboid.id !== splitCuboidId)
          );
        });
        return splitCuboidsByPartId.asStateMap();
      });
    });
  }, [
    splitCuboidOnPartRemovedBatch,
    splitCuboidOnPartRemovedBatch.consumeEvents,
    setSplitCuboidsByPartId,
  ]);

  if (structureRelationshipsQueryResult.error) {
    return <div>{`Error: ${structureRelationshipsQueryResult.error?.message}`}</div>;
  }

  if (structureRelationshipsQueryResult.loading || !ready) {
    return (
      <Wrapper>
        <LoadingSpinnerContainer>
          <CircularProgress size="10rem" />
        </LoadingSpinnerContainer>
      </Wrapper>
    );
  }

  if (!structureRelationshipsQueryResult.data) {
    throw new Error('Annotation metrics has not been initialized');
  }

  return (
    <Wrapper>
      <Fragment {...props} />
    </Wrapper>
  );
};
