import { SeenByViewpointsWithLocationAndAnalysis } from '@/types';
import { useFindAssemblyForViewPointsQuery } from '@/__generated__/graphql';

export const useAssemblySeenByViewpoints = (
  assemblyId: string | undefined
): SeenByViewpointsWithLocationAndAnalysis[] | undefined => {
  const { data } = useFindAssemblyForViewPointsQuery({
    variables: {
      assemblyId,
    },
    skip: !assemblyId,
    fetchPolicy: 'cache-first',
  });

  const assemblyViewpoints = data?.assembly?.seenByViewpoints;
  return assemblyViewpoints || [];
};

export const isSphericalAnalysedRelatedToAssembly = ({
  seenByViewpoints,
  locationId,
}: {
  seenByViewpoints: SeenByViewpointsWithLocationAndAnalysis[] | undefined;
  locationId: string;
}): boolean => {
  if (!seenByViewpoints) return false;
  return !!seenByViewpoints?.find((view) => view.location.id === locationId)?.analysed;
};
