import { abyssColors } from '@/theme/colors';

export const paginationStyles = {
  '& .MuiTablePagination-toolbar': { p: 0, minHeight: '32px' },
  '& .MuiTablePagination-actions': { m: 0 },
  '& .MuiTablePagination-actions button': { p: 0 },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
    fontSize: '1rem',
  },
  '& .MuiTablePagination-input': {
    fontSize: '1rem',
  },
};

export const corrosionIndicatorStyles = {
  width: 8,
  height: 8,
  borderRadius: '50%',
  marginRight: 0.5,
  mt: 0.3,
};

export const tableCellStyles = {
  border: `1px solid ${abyssColors.primary[100]}`,
  padding: 1,
};
