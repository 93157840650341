import {
  assemblyTemplate as assemblyTemplateState,
  selectedAssemblyId,
} from '@/components/Analysis/state';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Add as AddIcon, ModeEdit as EditIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  AssemblyTemplateDataDocument,
  useAssemblyTemplateDataQuery,
  useUpdateScCategoryMutation,
} from '@/__generated__/graphql';
import { outlinedButtonStyles } from '@/components/shared/ReportsRevamped/styles';
import { abyssColors, SC_CATEGORY_COLORS } from '@/theme/colors';
import { useFeatureFlag } from '@/hooks';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { ColorIndicator } from './styles';

const defaultScCategoryColor = 'black';

export const ScCategory = () => {
  const assemblyId = useRecoilValue(selectedAssemblyId);
  const assemblyTemplate = useRecoilValue(assemblyTemplateState);
  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>(undefined);
  const canAccessAitTools = useFeatureFlag(['ait-engineer', 'admin']);
  const structureId = useGetStructureId();

  const isMenuOpen = Boolean(anchorElement);

  const { data, loading } = useAssemblyTemplateDataQuery({
    variables: { assemblyId: assemblyId ?? '' },
    skip: !assemblyId,
  });

  const [updateScCategory, { loading: updateScCategoryLoading }] = useUpdateScCategoryMutation({
    refetchQueries: [AssemblyTemplateDataDocument],
  });

  const scCategoryField = useMemo(() => {
    return assemblyTemplate?.fields?.find(({ key }) => key === 'governing_sc_category');
  }, [assemblyTemplate]);

  const assemblyScCategory = useMemo(() => {
    return data?.assembly?.templateFieldData?.governing_sc_category;
  }, [data?.assembly?.templateFieldData]);

  const handleUpdateScCategory = async (scCategory: string) => {
    if (updateScCategoryLoading || !assemblyId || !scCategory || !structureId) return;
    setAnchorElement(undefined);
    try {
      await updateScCategory({ variables: { input: { assemblyId, scCategory, structureId } } });
    } catch {
      showSnackBar('Failed to update SC Category', 'error');
    }
  };

  if (!scCategoryField || loading) return <></>;

  return (
    <>
      {assemblyScCategory ? (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 2 }}>
          <Box>
            <Typography sx={{ width: 'fit-content', color: 'info.main', fontSize: '12px' }}>
              SC Category
            </Typography>
          </Box>
          <ButtonBase
            disabled={!canAccessAitTools || updateScCategoryLoading}
            onClick={(event) => setAnchorElement(event.currentTarget)}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: '1px solid',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                cursor: 'pointer',
                borderColor: abyssColors.primary[500],
              }}
            >
              <ColorIndicator
                sx={{
                  mr: 1,
                  backgroundColor: SC_CATEGORY_COLORS[assemblyScCategory] ?? defaultScCategoryColor,
                }}
              />
              <Typography fontSize="12px">{assemblyScCategory}</Typography>
              {canAccessAitTools && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 1, borderColor: abyssColors.primary[500] }}
                  />
                  {updateScCategoryLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <EditIcon sx={{ fontSize: 16 }} />
                  )}
                </>
              )}
            </Stack>
          </ButtonBase>
        </Stack>
      ) : (
        <Button
          size="small"
          variant="outlined"
          sx={{ ...outlinedButtonStyles, my: 2, height: 28, px: 1, fontWeight: 500 }}
          startIcon={<AddIcon />}
          onClick={(event) => setAnchorElement(event.currentTarget)}
        >
          Add SC Category
        </Button>
      )}
      <Divider sx={{ mb: 2, mt: 1 }} />
      {isMenuOpen && (
        <Menu
          anchorEl={anchorElement}
          open={isMenuOpen}
          onClose={() => setAnchorElement(undefined)}
          sx={{ mt: 0.2 }}
        >
          {scCategoryField.options?.map((option) => (
            <MenuItem
              key={option}
              sx={{ width: 152, py: 0 }}
              onClick={() => handleUpdateScCategory(option)}
            >
              <ListItemIcon>
                <ColorIndicator
                  sx={{ backgroundColor: SC_CATEGORY_COLORS[option] ?? defaultScCategoryColor }}
                />
              </ListItemIcon>
              <ListItemText>{option}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
