import { memo, useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { PointOfInterestDocument } from '@/types';

import { EmptyBlisters } from './Blisters/styles';
import { PointOfInterest, StringMeta } from '@/__generated__/graphql';
import { editPointOfInterest } from '../state';
import { generatePoiDisplayName } from '../PointOfInterestTab/SelectedPoiContent/utils';

const PointOfInterestTableBase = ({
  pointOfInterests,
}: {
  pointOfInterests: PointOfInterestDocument[] | undefined;
}) => {
  const setEditPointOfInterest = useSetRecoilState(editPointOfInterest);

  const handleClick = useCallback(
    (poi: PointOfInterest) => {
      setEditPointOfInterest({
        state: 'WaitingForDetail',
        template: poi.template!,
        pointOfInterest: poi as PointOfInterest,
        formState: 'Create',
      });
    },
    [setEditPointOfInterest]
  );

  if (!pointOfInterests || pointOfInterests.length === 0) {
    return (
      <EmptyBlisters>
        <Typography>Add Points of Interest using the spatial viewer</Typography>
      </EmptyBlisters>
    );
  }

  const findComment = (poi: PointOfInterestDocument) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (poi?.meta || []).find((m: any) => m.__typename === 'StringMeta' && m.key === 'comment') as
      | StringMeta
      | undefined;

  const mappedPointOfInterests = (pointOfInterests || []).map((poi) => {
    const poiName = generatePoiDisplayName({
      name: poi.name,
      poiId: poi.poiId,
      poiType: poi.type,
    });
    return (
      <TableRow
        hover
        key={poi.id}
        onClick={() => {
          handleClick(poi);
        }}
      >
        <TableCell>{poiName}</TableCell>
        <TableCell>{findComment(poi)?.value || ''}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer>
      <Table sx={{ minWidth: 200 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{mappedPointOfInterests}</TableBody>
      </Table>
    </TableContainer>
  );
};

export const PointOfInterestTable = memo(PointOfInterestTableBase);
