import { MarkerProps, MarkerShapeType } from '@abyss/3d-viewer';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { poiState } from '../../modules/pointOfInterest';

import * as localState from '@/components/Analysis/state';
import { abyssColors } from '@/theme/colors';

export const useBlisterClickMarkers = () => {
  const blisterToAdd = useRecoilValue(poiState.blisterToAdd);
  const blisterPolygons = useRecoilValue(poiState.blisterPolygons);
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const selectedSpherical = useRecoilValue(localState.selectedSpherical);

  const blisterMarkers: MarkerProps[] | undefined = useMemo(() => {
    let returnArray: MarkerProps[] = [];
    if (!selectedSpherical) return undefined;
    if (blisterPolygons && blisterPolygons.some((polygon) => polygon.isVisible)) return undefined;
    if (blisterToAdd?.state === 'Reviewing') return undefined;
    if (editPointOfInterest.pointOfInterest?.centerPoint3d) {
      const worldCoordinates = editPointOfInterest.pointOfInterest?.centerPoint3d;
      if (worldCoordinates) {
        returnArray = [
          {
            id:
              editPointOfInterest.pointOfInterest.id ?? Object.values(worldCoordinates).toString(),
            position: [worldCoordinates.x, worldCoordinates.y, worldCoordinates.z],
            rotation: [0, 0, 0],
            shapeType: MarkerShapeType.SPHERE,
            style: {
              color: abyssColors.warning[300],
              opacity: 1,
              size: 0.005,
            },
          },
        ];
      }
    }

    return returnArray;
  }, [editPointOfInterest, selectedSpherical, blisterPolygons, blisterToAdd]);
  return blisterMarkers;
};
