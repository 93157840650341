import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MeasurementToolIcon } from '@/constants/AnnotationIcons';
import { localiseBlisterHeight } from '@/utils/unitSystem';
import {
  unitSystem as unitSystemState,
  allPointOfInterestTemplates,
} from '@/components/Analysis/state';
import { UnitSystemEnum } from '@/__generated__/graphql';
import { BlisterFieldsData } from '../hooks/useBlisterData';
import { useScCategoryOptionsQuery } from '@/__generated__/graphql';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { DropDownField } from '@/components/shared/CustomField';
import { ErrorChip } from './styles';
import {
  UNCERTAIN_PROFILE_SHORT_LABEL,
  ESTIMATED_BLISTER_HEIGHT_LABEL,
  ESTIMATED_PIT_DEPTH_LABEL,
  PIT,
  BLISTER,
  PIT_TYPE,
  BLISTER_TYPE,
} from '@/constants';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import { convertTemplateNameToPoiType } from '@/utils';
import { PointOfInterestTemplateDocument, TextFormat } from '@/types';

type BlisterFormFieldsProps = {
  blisterData: BlisterFieldsData | undefined;
  handleManualHeightChange: (height: string) => void;
  handleChangeScCategory: (value: string) => void;
  handleMeasureIconClick: () => void;
  onFieldChange?: (value: boolean) => void;
};

export const BlisterFormFields = ({
  blisterData,
  handleManualHeightChange,
  handleChangeScCategory,
  handleMeasureIconClick,
  onFieldChange,
}: BlisterFormFieldsProps) => {
  const unitSystem = useRecoilValue(unitSystemState);
  const allPoiTemplates = useRecoilValue(allPointOfInterestTemplates);
  const [editPointOfInterest, setEditPointOfInterest] = useRecoilState(
    poiState.editPointOfInterest
  );

  const structureId = useGetStructureId();
  const scCategoryOptions = useScCategoryOptionsQuery({
    variables: { structureId },
    fetchPolicy: 'cache-first',
  });

  const template = editPointOfInterest?.template;
  const poiType = convertTemplateNameToPoiType(template?.name);

  const isPitCheckBoxEnabled = poiType === PIT_TYPE;

  const formatValue = useCallback(
    (value: string | undefined) => {
      if (!value) return '';
      return (localiseBlisterHeight(unitSystem, Number(value)) ?? '').replace(/[^\d.]+$/, '');
    },
    [unitSystem]
  );

  const unit = useMemo(() => {
    return unitSystem === UnitSystemEnum.Imperial ? '"' : 'mm';
  }, [unitSystem]);

  const blisterHeight = useMemo(() => {
    if (!blisterData) return undefined;
    return formatValue(blisterData.estimatedHeight);
  }, [blisterData, formatValue]);

  const estimatedHeightColor = useMemo(() => {
    if (blisterData?.manualSetHeight || blisterData?.userManualSetHeight) return '#b0b3b7';
    return 'inherit';
  }, [blisterData?.manualSetHeight, blisterData?.userManualSetHeight]);

  const handleTemplateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked;

      const newTemplate = allPoiTemplates.find(
        (item) => item.name === (newValue ? PIT : BLISTER)
      ) as PointOfInterestTemplateDocument | undefined;

      if (newTemplate) {
        setEditPointOfInterest((current) => ({
          ...current,
          template: newTemplate,
        }));
      }

      if (onFieldChange) onFieldChange(newValue);
    },
    [allPoiTemplates, onFieldChange, setEditPointOfInterest]
  );

  const pitTemplateExists = useMemo(() => {
    return allPoiTemplates.find(
      (poiTemplate) => poiTemplate.name.toUpperCase() === PIT.toUpperCase()
    );
  }, [allPoiTemplates]);

  const estimatedHeightLabel = useMemo(() => {
    switch (poiType) {
      case BLISTER_TYPE:
        return ESTIMATED_BLISTER_HEIGHT_LABEL;
      case PIT_TYPE:
        return ESTIMATED_PIT_DEPTH_LABEL;
      default:
        return '';
    }
  }, [poiType]);

  return (
    <Stack spacing={3} sx={{ width: '100%', marginTop: 3.5 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ color: estimatedHeightColor }}
      >
        <Typography sx={{ fontSize: '1.4rem' }}>{estimatedHeightLabel}</Typography>
        {blisterHeight ? (
          <Typography sx={{ fontWeight: 600, fontSize: '1.6rem' }}>
            {blisterHeight + unit}
          </Typography>
        ) : (
          <ErrorChip>{UNCERTAIN_PROFILE_SHORT_LABEL}</ErrorChip>
        )}
      </Stack>
      <TextField
        label="Manual Set Height"
        name="userManualSetHeight"
        value={blisterData?.userManualSetHeight || formatValue(blisterData?.manualSetHeight)}
        onChange={(event) => handleManualHeightChange(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MeasurementToolIcon sx={{ cursor: 'pointer' }} onClick={handleMeasureIconClick} />
            </InputAdornment>
          ),
        }}
        variant="standard"
        fullWidth
      />
      {blisterHeight && pitTemplateExists && (
        <FormControlLabel
          control={<Checkbox checked={isPitCheckBoxEnabled} onChange={handleTemplateChange} />}
          label={`Mark this point of interest as Pit`}
        />
      )}
      {scCategoryOptions?.data?.scCategoryOptions?.length !== 0 && (
        <DropDownField
          mode="Edit"
          id="scCategory"
          title="SC Category"
          valueFormat={TextFormat.UPPER_CASE}
          sx={{ mt: 2 }}
          value={blisterData?.scCategory ?? 'SC1'}
          options={scCategoryOptions.data?.scCategoryOptions || []}
          onFieldValueChange={(value) => handleChangeScCategory(value)}
        />
      )}
    </Stack>
  );
};
