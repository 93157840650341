import { DEFAULT_VISIBILITY_BOX } from '@/constants';
import { AnalysisCuboid, CuboidClickMode, CuboidClass } from '@/types';
import { StateMap } from '@/utils/stateMap';
import { ControlsType, TransformMode, VisibilityBoxProps } from '@abyss/3d-viewer';
import { atom } from 'recoil';
import { AssemblyPoiWithCheckMark } from '../../pointOfInterest/PointOfInterestTab/SelectedPoiContent/Forms/LinkedPoiEditor/types';

export const cuboidClassById = atom<{ [id: string]: CuboidClass } | undefined>({
  key: 'AssetAnalysisCuboidClassById',
  default: undefined,
});

export const defaultCuboidClassId = atom<string | undefined>({
  key: 'AssetAnalysisDefaultCuboidClassId',
  default: undefined,
});

export const currentCuboid = atom<AnalysisCuboid | undefined>({
  key: 'AssetAnalysisCurrentCuboid',
  default: undefined,
});

export const editingCuboids = atom<StateMap<string, AnalysisCuboid>>({
  key: 'AssetAnalysisEditingCuboids',
  default: new StateMap<string, AnalysisCuboid>(),
});

export const cuboids = atom<StateMap<string, AnalysisCuboid>>({
  key: 'AssetAnalysisCuboids',
  default: new StateMap<string, AnalysisCuboid>(),
});

export const cuboidControlsType = atom<ControlsType>({
  default: ControlsType.AlignedBox,
  key: 'AssetAnalysisCuboidControlsType',
});

export const cuboidTransformMode = atom<TransformMode>({
  default: TransformMode.Translate,
  key: 'AssetAnalysisCuboidTransformMode',
});

export const cuboidClickMode = atom<CuboidClickMode>({
  default: CuboidClickMode.Default,
  key: 'AssetAnalysisCuboidClickMode',
});

export const isCuboidEditorEnabled = atom<boolean>({
  default: false,
  key: 'AssetAnalysisIsCuboidEditorEnabled',
});

export const currentCuboidVisibilityBox = atom<VisibilityBoxProps>({
  default: DEFAULT_VISIBILITY_BOX,
  key: 'AssetAnalysisCurrentCuboidVisibilityBox',
});

export const isCuboidVisibilityBoxEnabled = atom<boolean>({
  default: false,
  key: 'AssetAnalysisIsCuboidVisibilityBoxEnabled',
});

export const cuboidLinkedPois = atom<AssemblyPoiWithCheckMark[] | undefined>({
  key: 'AssetAnalysisCuboidLinkedPois',
  default: undefined,
});
