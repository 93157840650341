import {
  allAssemblyTemplates,
  isAlarmLevelDialogOpen as isAlarmLevelDialogOpenState,
  selectedAssemblyId,
  selectedAssemblyTemplateId,
} from '@/components/Analysis/state';
import { lazy, Suspense, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Add as AddIcon, ModeEdit as EditIcon, NotificationImportant } from '@mui/icons-material';
import { Box, Button, ButtonBase, Divider, Stack, Typography } from '@mui/material';
import { useGetAssemblyTemplateFieldDataQuery } from '@/__generated__/graphql';
import { structurePois } from '@/components/Analysis/modules/pointOfInterest/state';
import { outlinedButtonStyles } from '@/components/shared/ReportsRevamped/styles';
import { AlarmLevelMapper, Recommendations } from '../common';
import { abyssColors } from '@/theme/colors';
import { joinByRecommendationKey } from '@/utils';
import { useFeatureFlag } from '@/hooks';

const RecommendationDialogLazy = lazy(() =>
  import('@/components/Analysis/Viewer/Panels/AssemblyTable/Asset/RecommendationDialog').then(
    ({ RecommendationDialog }) => ({
      default: RecommendationDialog,
    })
  )
);

export const AlarmLevel = () => {
  const [isAlarmLevelDialogOpen, setIsAlarmLevelDialogOpen] = useRecoilState(
    isAlarmLevelDialogOpenState
  );
  const assemblyId = useRecoilValue(selectedAssemblyId);
  const selectedTemplateId = useRecoilValue(selectedAssemblyTemplateId);
  const allTemplates = useRecoilValue(allAssemblyTemplates);
  const allPOIs = useRecoilValue(structurePois);
  const canAccessAitTools = useFeatureFlag(['ait-engineer', 'admin']);

  const {
    data: getAssemblyFieldTemplateData,
    loading,
    refetch,
  } = useGetAssemblyTemplateFieldDataQuery({
    variables: { assemblyId: assemblyId ?? '' },
    fetchPolicy: 'no-cache',
    skip: !assemblyId,
  });

  // refetch the templateFieldData if number of pois changes
  useEffect(() => {
    refetch();
  }, [allPOIs, refetch]);

  const templateFieldData = useMemo(() => {
    return getAssemblyFieldTemplateData?.assembly?.templateFieldData ?? {};
  }, [getAssemblyFieldTemplateData?.assembly?.templateFieldData]);

  const template = useMemo(() => {
    if (!selectedTemplateId || !allTemplates) return undefined;
    return allTemplates.find((t) => t.id === selectedTemplateId);
  }, [allTemplates, selectedTemplateId]);

  const recommendation = useMemo(() => {
    if (!template) return;
    const recommendationField = joinByRecommendationKey(template).find(
      (data) => data.displayInQuery
    );
    if (!recommendationField?.key) return;
    if (templateFieldData[recommendationField.key]) {
      const recommendationValue = templateFieldData[recommendationField.key] as Recommendations;
      return AlarmLevelMapper[recommendationValue];
    }
  }, [template, templateFieldData]);

  const openRecommendationDialog = useCallback(() => {
    setIsAlarmLevelDialogOpen(true);
  }, [setIsAlarmLevelDialogOpen]);

  const closeRecommendationDialog = useCallback(() => {
    setIsAlarmLevelDialogOpen(false);
  }, [setIsAlarmLevelDialogOpen]);

  return (
    <>
      {!loading && templateFieldData && template?.recommendation && assemblyId && (
        <>
          {recommendation ? (
            <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 2 }}>
              <Box>
                <Typography sx={{ width: 'fit-content', color: 'info.main', fontSize: '12px' }}>
                  {template?.recommendation?.header}
                </Typography>
              </Box>
              <ButtonBase disabled={!canAccessAitTools} onClick={openRecommendationDialog}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    border: '1px solid',
                    borderColor: abyssColors.primary[500],
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    cursor: 'pointer',
                  }}
                >
                  <NotificationImportant
                    fontSize="small"
                    sx={{ marginRight: 1, color: recommendation.color }}
                  />
                  <Typography fontSize="12px">{recommendation.alarmLevel}</Typography>
                  {canAccessAitTools && (
                    <>
                      <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ mx: 1, borderColor: abyssColors.primary[500] }}
                      />
                      <EditIcon sx={{ fontSize: 16 }} />
                    </>
                  )}
                </Stack>
              </ButtonBase>
            </Stack>
          ) : (
            <Button
              size="small"
              variant="outlined"
              sx={{ ...outlinedButtonStyles, my: 2, height: 28, px: 1, fontWeight: 500 }}
              startIcon={<AddIcon />}
              onClick={openRecommendationDialog}
            >
              {`Add ${template?.recommendation?.header}`}
            </Button>
          )}

          <Divider sx={{ mb: 2, mt: 1 }} />

          {isAlarmLevelDialogOpen && (
            <Suspense>
              <RecommendationDialogLazy
                isOpen={isAlarmLevelDialogOpen}
                assemblyId={assemblyId}
                template={template}
                templateFieldData={templateFieldData}
                onClose={closeRecommendationDialog}
              />
            </Suspense>
          )}
        </>
      )}
    </>
  );
};
