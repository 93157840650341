import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Fragment, useEffect, useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { DEFAULT_LABELLING_COLORS } from '@/constants';
import * as state from '@/state';
import {
  useGetStructureLocationsForLabellingInspectionDataLoaderQuery,
  useGetStructureRelationshipsForLabellingInspectionDataLoaderQuery,
  GetStructureRelationshipsForLabellingInspectionDataLoaderQuery,
} from '@/__generated__/graphql';
import {
  ByAnnotation3dReferenceEntity,
  BySupervoxelClassIdEntity,
  LabellingStructureRelationships,
} from '@/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LoadingSpinnerContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(80vh / var(--zoom));
`;

export const InspectionDataLoader = ({ ...props }) => {
  const { user } = useUser();

  const router = useRouter();
  const { inspection, zoneid } = router.query;

  const setInspectionMetadata = useSetRecoilState(state.inspectionMetadata);
  const setStructureLocations = useSetRecoilState(state.structureLocations);
  const inspectionString = inspection?.toString();
  const zoneIdString = zoneid?.toString();

  const structureId = useRecoilValue(state.selectedStructureId) || inspectionString || '';
  // cannot use selectedzone atom here, as it will cause re-renders.
  const zoneId = zoneIdString || '';
  // separate due to typing conflict that can arise while re-using
  // tagging structureRelationships
  const setLabellingStructureRelationships = useSetRecoilState(
    state.labellingStructureRelationships
  );

  /**
   * On slower devices you will see a blank screen after structureRelationshipsQueryResult
   * loads because the request is finished but the main thread is busy constructing
   * the internal state. This ready state will wait for that too.
   */
  const [ready, setReady] = useState<boolean>(false);

  const structureRelationshipsQueryResult = useGetStructureRelationshipsForLabellingInspectionDataLoaderQuery({
    variables: {
      structureId,
      supervoxelsForStructureInput: {
        structureId,
        zoneId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const structureLocationsQueryResult = useGetStructureLocationsForLabellingInspectionDataLoaderQuery({
    variables: {
      structureId,
    },
  });

  useEffect(() => {
    if (structureLocationsQueryResult.data?.allLocations) {
      setStructureLocations(structureLocationsQueryResult.data?.allLocations);
    }
  }, [setStructureLocations, structureLocationsQueryResult.data?.allLocations]);

  useEffect(() => {
    if (structureRelationshipsQueryResult.data?.structure) {
      setInspectionMetadata(structureRelationshipsQueryResult.data?.structure);
    }
  }, [setInspectionMetadata, structureRelationshipsQueryResult.data?.structure]);

  /*
  This handles the first time structure relationships is loaded
  by transforming the data to a format that will be easier for the 3d viewer to use
*/
  useEffect(() => {
    const relationships: LabellingStructureRelationships = {
      byAnnotation3dReference: {},
      bySupervoxelClassId: {},
    };

    if (structureRelationshipsQueryResult.data) {
      // Accumulating superVoxelsClasses for a structure in form of Object
      const supervoxelClassesById =
        structureRelationshipsQueryResult.data.supervoxelsClassesForStructure.reduce(
          (accumulator, current) => {
            accumulator[current.id] = current;
            return accumulator;
          },
          {} as {
            [key: string]: GetStructureRelationshipsForLabellingInspectionDataLoaderQuery['supervoxelsClassesForStructure'][0];
          }
        );

      // While grouping, if a supervoxelClass does not exist in a zone,
      // grouping will miss that supervoxelClass, so we are initializing all the supervoxelClasses with
      // default values so we have consistent data about supervoxelClasses.
      Object.keys(supervoxelClassesById).forEach((supervoxelClass) => {
        const supervoxelClassInit: BySupervoxelClassIdEntity = {
          id: supervoxelClass,
          zoneId: '',
          color: supervoxelClassesById[supervoxelClass].color,
          name: supervoxelClassesById[supervoxelClass].name,
          annotationIds: [],
          highlightColor: DEFAULT_LABELLING_COLORS.defaultColor,
          isSelected: false,
        };
        relationships.bySupervoxelClassId[supervoxelClass] = { ...supervoxelClassInit };
      });

      structureRelationshipsQueryResult.data.supervoxelsForStructure.forEach((supervoxel) => {
        if (supervoxel) {
          // zoneId is renamed to superVoxelZone because zoneId exists in global scope
          const {
            id,
            zoneId: supervoxelZone,
            supervoxel3DReference,
            supervoxelClassId,
          } = supervoxel;
          if (supervoxelClassId) {
            const supervoxelClass = supervoxelClassesById[supervoxelClassId];
            if (!supervoxelClass) {
              return;
            }
            const supervoxelClassData: BySupervoxelClassIdEntity = relationships
              .bySupervoxelClassId[supervoxelClassId] || {
              id,
              zoneId: supervoxelZone,
              color: supervoxelClass.color,
              name: supervoxelClass.name,
              annotationIds: [],
              highlightColor: DEFAULT_LABELLING_COLORS.defaultColor,
              isSelected: false,
            };

            // Grouping each supervoxel according to its class.
            supervoxelClassData.annotationIds.push(supervoxel3DReference);
            relationships.bySupervoxelClassId[supervoxelClassId] = supervoxelClassData;

            const annotationData: ByAnnotation3dReferenceEntity = relationships
              .byAnnotation3dReference[supervoxel3DReference] || {
              supervoxelId: id,
              color: supervoxelClass.color,
              supervoxelClassId,
              highlightColor: DEFAULT_LABELLING_COLORS.defaultColor,
              defaultColor: DEFAULT_LABELLING_COLORS.defaultColor,
              isSelected: false,
            };

            // Annotation 3d reference is what the 3d viewer knows about
            // we need this map to translate from 3d viewer annotation to
            // the database annotation id
            // e.g. when handling a click event on the 3d viewer point cloud
            relationships.byAnnotation3dReference[supervoxel3DReference] = annotationData;
          }
        }
      });
      setReady(true);
      setLabellingStructureRelationships(relationships);
    }
  }, [setLabellingStructureRelationships, structureRelationshipsQueryResult.data, user?.sub]);

  if (structureRelationshipsQueryResult.error) {
    return <div>{`Error: ${structureRelationshipsQueryResult.error?.message}`}</div>;
  }

  if (structureRelationshipsQueryResult.loading || !ready) {
    return (
      <Wrapper>
        <LoadingSpinnerContainer>
          <CircularProgress size="10rem" />
        </LoadingSpinnerContainer>
      </Wrapper>
    );
  }

  if (!structureRelationshipsQueryResult.data) {
    throw new Error('Annotation metrics has not been initialized');
  }

  return (
    <Wrapper>
      <Fragment {...props} />
    </Wrapper>
  );
};
