import { useDefectLegendsForEquipmentQuery } from '@/__generated__/graphql';
import { CORROSION_GROUPKEYS } from '@/constants';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useEffect, useState } from 'react';

type CorrosionLevelColors = Record<string, string>;

export const useCorrosionColors = () => {
  const [corrosionLevelColors, setCorrosionLevelColors] = useState<CorrosionLevelColors>({});
  const structureId = useGetStructureId();
  const { data, loading } = useDefectLegendsForEquipmentQuery({
    variables: { structureId },
    skip: !structureId,
  });

  useEffect(() => {
    const defectLegends = data?.structure?.defectLegends;
    if (defectLegends) {
      const colors = defectLegends.reduce<CorrosionLevelColors>((accumulator, current) => {
        if (!CORROSION_GROUPKEYS.includes(current.groupKey)) return accumulator;
        accumulator[current.name] = current.color;
        return accumulator;
      }, {});
      setCorrosionLevelColors(colors);
    }
  }, [data]);

  return { corrosionLevelColors, loading };
};
