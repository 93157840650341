import { FormLabel, Slider } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as globalState from '@/state';
import { useCallback, useEffect, useState } from 'react';
import { ToggleItemVisibility } from '@/components/Analysis/Viewer/Panels/Visibility/ToggleItemVisibility';
import { DYNAMIC_POINT_SIZE_SCALE } from '@/constants';
const valuetext = (value: number) => {
  return `${value}`;
};
export const DynamicPointSize = () => {
  const [enableDynamicPointSize, setEnableDynamicPointSize] = useRecoilState<boolean>(
    globalState.enableDynamicPointSize
  );
  const setDynamicPointSize = useSetRecoilState<number>(globalState.dynamicPointSize);

  const [unfinishedValue, setUnfinishedValue] = useState<number>(1);

  const toggleDynamicPointSize = useCallback(() => {
    setEnableDynamicPointSize((enableDynamicPoints) => {
      return !enableDynamicPoints;
    });
  }, [setEnableDynamicPointSize]);

  useEffect(() => {
    setDynamicPointSize(Math.pow(DYNAMIC_POINT_SIZE_SCALE, unfinishedValue));
  }, [setDynamicPointSize, unfinishedValue]);

  const handlePointSizeChanged = (event: Event, value: number | number[]) => {
    setUnfinishedValue(value as number);
  };

  return (
    <>
      <ToggleItemVisibility
        label="Dynamic Point Sizing"
        isVisible={enableDynamicPointSize}
        toggleVisibility={toggleDynamicPointSize}
      />
      {enableDynamicPointSize && (
        <>
          <FormLabel>Dynamic Point Size</FormLabel>
          <Slider
            min={0}
            max={10}
            step={1}
            aria-label="DynamicPointSize"
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            value={unfinishedValue}
            onChange={handlePointSizeChanged}
            size="small"
          />
        </>
      )}
    </>
  );
};
