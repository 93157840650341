import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/components/Analysis/state';
import { AnalysisStructureRelationships, Colors } from '@/types';
import {
  GetAllAnnotationInLocationQuery,
  useGetAllAnnotationInLocationLazyQuery,
} from '@/__generated__/graphql';
import { PICKABLE_COLOR, UNPICKABLE_COLOR, TRANSPARENT_COLOR } from '@/constants';

import { colorWithAlpha } from '@/utils';

import { structureRelationship } from '@/components/Analysis/state';
import { useGetStructureId } from '@/hooks/useGetStructureId';

const useInitialiseMode = (
  colorMap: ColorMap | undefined,
  annotationPickingMap: ColorMap | undefined,
  allColors: Colors,
  annotationsInLocation: GetAllAnnotationInLocationQuery | undefined,
  assemblyId: string | undefined | null
) => {
  const currentSpherical = useRecoilValue(state.selectedSpherical);
  const structureRelationships = useRecoilValue(structureRelationship);
  const showPointCloudInSpherical = useRecoilValue(state.showPointCloudInSpherical);
  const showPointsOutsideViewpoint = useRecoilValue(state.showPointsOutsideViewpoint);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const blendOpacity = useRecoilValue(state.blendOpacity);
  const selectedImageViewpoint = useRecoilValue(state.selectedSpherical);
  const setPickingMapsUpdateSequence = useSetRecoilState(state.pickingMapsUpdateSequence);

  const setAnnotationColor = useCallback(
    (
      assemblyIdToAnnotation3dArray: AnalysisStructureRelationships['assemblyIdToAnnotation3dArray'],
      id: string,
      color: number,
      pickableColor: number = PICKABLE_COLOR
    ) => {
      assemblyIdToAnnotation3dArray[id]?.forEach((annotation3dReference: number) => {
        colorMap?.setColor(annotation3dReference, color);
        annotationPickingMap?.setColor(annotation3dReference, pickableColor);
      });
    },
    [annotationPickingMap, colorMap]
  );

  useEffect(() => {
    if (!currentSpherical) return;
    if (!colorMap) return;
    if (!annotationPickingMap) return;
    if (!annotationsInLocation?.allAnnotations) return;
    if (!structureRelationships) return;
    const {
      annotationIdToAnnotation3d = {},
      assemblyIdToAnnotation3dArray = {},
      annotationIdToAssemblyId = {},
    } = structureRelationships;

    const shouldShowAll =
      showPointCloudInSpherical &&
      !selectedAssemblyId &&
      annotationsInLocation?.allAnnotations.length === 0;

    // TODO: TBC colour should be based on defects
    const finalColor = colorWithAlpha(allColors.foundAssemblyColour, blendOpacity);

    const initialColor = shouldShowAll ? finalColor : TRANSPARENT_COLOR;
    const initialPickingColor = shouldShowAll ? PICKABLE_COLOR : UNPICKABLE_COLOR;

    Object.keys(annotationIdToAnnotation3d).forEach((annotationId) => {
      const annotation3dReference = annotationIdToAnnotation3d[annotationId];
      colorMap.setColor(annotation3dReference, initialColor);
      annotationPickingMap.setColor(annotation3dReference, initialPickingColor);
    });

    if (shouldShowAll) {
      return;
    }

    if (!showPointCloudInSpherical) {
      return;
    }

    /*
      All annotations are assigned to the same transparent color
      So we need to assign color for each annotation related
    */
    if (showPointsOutsideViewpoint) {
      if (selectedAssemblyId) {
        setAnnotationColor(
          assemblyIdToAnnotation3dArray,
          selectedAssemblyId,
          finalColor,
          PICKABLE_COLOR
        );
      } else {
        Object.keys(assemblyIdToAnnotation3dArray).forEach((aId) => {
          setAnnotationColor(assemblyIdToAnnotation3dArray, aId, finalColor, PICKABLE_COLOR);
        });
      }
    }

    annotationsInLocation?.allAnnotations.forEach(({ id }) => {
      const assemblyIdForAnnotation = annotationIdToAssemblyId[id];
      const annotation3dReference = annotationIdToAnnotation3d[id];

      if (!selectedAssemblyId || assemblyIdForAnnotation === selectedAssemblyId) {
        colorMap.setColor(annotation3dReference, finalColor);
      } else {
        colorMap.setColor(annotation3dReference, TRANSPARENT_COLOR);
      }

      annotationPickingMap.setColor(annotation3dReference, PICKABLE_COLOR);
    });
    setPickingMapsUpdateSequence((current) => current + 1);
  }, [
    allColors.colors,
    allColors.noAssemblyColour,
    allColors.foundAssemblyColour,
    annotationsInLocation?.allAnnotations,
    blendOpacity,
    currentSpherical,
    assemblyId,
    colorMap,
    annotationPickingMap,
    selectedAssemblyId,
    selectedImageViewpoint?.id,
    showPointCloudInSpherical,
    structureRelationships,
    showPointsOutsideViewpoint,
    setAnnotationColor,
    setPickingMapsUpdateSequence,
  ]);
};

export const useImageViewpointEffects = (
  colorMap: ColorMap | undefined,
  annotationPickingMap: ColorMap | undefined,
  allColors: Colors,
  assemblyId: string | undefined | null
) => {
  const structureViewpoints = useRecoilValue(state.structureLocations);
  const structureId = useGetStructureId();
  const currentSpherical = useRecoilValue(state.selectedSpherical);

  const [getAllAnnotationInLocation, { data: annotationsInLocation }] =
    useGetAllAnnotationInLocationLazyQuery({
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!currentSpherical) return;

    const locationId = structureViewpoints?.find(
      (viewpoint) => viewpoint.name === currentSpherical?.name
    )?.id;
    if (locationId) {
      getAllAnnotationInLocation({
        variables: {
          structureId,
          locationId,
        },
      });
    }
  }, [currentSpherical, getAllAnnotationInLocation, structureId, structureViewpoints]);

  useInitialiseMode(colorMap, annotationPickingMap, allColors, annotationsInLocation, assemblyId);
};
