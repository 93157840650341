import { useMemo, useState, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SearchList } from '../../../../shared/SearchList';
import {
  assemblyTemplate,
  allAssemblyTemplates,
  selectedTemplateFiltersState,
} from '@/components/Analysis/state';
import { FiltersCount } from '../styles';
import { useGroupFilterValue } from '@/hooks';
import { TemplateFieldTypeEnum } from '@/__generated__/graphql';

type TabPanelProps = {
  children?: React.ReactNode;
  show: boolean;
};

const FilterTabpanel = (props: TabPanelProps) => {
  const { children, show } = props;

  return (
    <Stack role="tabpanel" hidden={!show}>
      {show && (
        <Stack>
          <Typography>{children}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

type CustomAccordianProps = {
  expanded: string[];
  name: string;
  id: string;
  serverSide: boolean;
  templateId: string;
  templateFieldId?: string;
  templateFieldKey: string;
  handleChange:
    | ((event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void)
    | undefined;
};
const CustomAccordian = ({
  expanded,
  name,
  id,
  serverSide,
  templateId,
  templateFieldId,
  handleChange,
  templateFieldKey,
}: CustomAccordianProps) => {
  const isFilterExpanded = expanded.includes(name);
  const selectedTemplateFilters = useRecoilValue(selectedTemplateFiltersState);
  const selected = useGroupFilterValue(selectedTemplateFilters);

  return (
    <Accordion
      sx={{
        margin: 0,
        boxShadow: 'none',
        backgroundColor: '#F7F8FC',
        '.Mui-expanded&:before': { opacity: 1 },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        '@media(max-width: 1728px)': {
          '& .MuiAccordionSummary-root': {
            minHeight: '32px',
          },
        },
      }}
      expanded={isFilterExpanded}
      onChange={handleChange}
      key={`${name}-${id}`}
      disableGutters
    >
      <AccordionSummary
        sx={{ padding: '0', backgroundColor: '#F7F8FC', m: 0 }}
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1a-content"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography sx={{ fontWeight: 500, paddingLeft: '1rem' }} variant="body2">
            {name}
          </Typography>
          {selected[id] && <FiltersCount>{selected[id].length}</FiltersCount>}
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          margin: 0,
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <SearchList
          isExpanded={isFilterExpanded}
          templateId={templateId}
          templateFieldId={templateFieldId}
          templateFieldKey={templateFieldKey}
          name={name}
          serverSide={serverSide}
          selected={selected}
        />
      </AccordionDetails>
    </Accordion>
  );
};

type AssemblyFiltersProps = {
  value: number;
};

export const AssemblyFilters = ({ value }: AssemblyFiltersProps) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const filters = useRecoilValue(assemblyTemplate);
  const allTemplates = useRecoilValue(allAssemblyTemplates);

  const handleChange = useCallback(
    (name: string) => () => {
      const updatedExpanded = expanded.includes(name)
        ? expanded.filter((element) => element !== name)
        : [...expanded, name];
      setExpanded(updatedExpanded);
    },
    [expanded]
  );

  const optionsList: { [key: string]: { id: string; serverSide: boolean } } = useMemo(
    () => ({}),
    []
  );

  filters?.fields.forEach((field) => {
    optionsList[field.name] = { id: field.id, serverSide: field.serverSide };
  });

  const AccordionMemo = useMemo(() => {
    if (allTemplates?.length === 1) {
      // Temporarily filter out INPUT type filters to cater for scenario for fields to show up in the equipment table but not filters
      return filters?.fields
        ?.filter((field) => field.type !== TemplateFieldTypeEnum.Input)
        .map(({ name, id, serverSide, key }) => (
          <CustomAccordian
            key={id}
            expanded={expanded}
            name={name}
            id={id}
            serverSide={serverSide}
            templateId={filters?.id}
            templateFieldId={id}
            templateFieldKey={key ?? ''}
            handleChange={handleChange(name)}
          />
        ));
    }

    return allTemplates?.map((template, index) => {
      return (
        <FilterTabpanel show={value === index} key={template?.id}>
          {filters?.fields?.map(({ name, id, serverSide, key }) => (
            <CustomAccordian
              key={id}
              expanded={expanded}
              name={name}
              id={id}
              serverSide={serverSide}
              templateId={template?.id}
              templateFieldId={id}
              templateFieldKey={key ?? ''}
              handleChange={handleChange(name)}
            />
          ))}
        </FilterTabpanel>
      );
    });
  }, [expanded, allTemplates, filters?.fields, filters?.id, handleChange, value]);

  return (
    <Stack data-testid="template-filters-container">
      {AccordionMemo}
      <Divider />
    </Stack>
  );
};
