import { useRecoilValue } from 'recoil';
import { useCallback, useMemo } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as state from '@/components/Analysis/state';
import { useMarkViewPointAsAnalysedFromCurrentAssemblyMutation } from '@/__generated__/graphql';
import { ResponsiveButton } from '../styles';
import {
  FindAssemblyForViewPointsDocument,
  useFindAssemblyForViewPointsQuery,
} from '@/__generated__/graphql';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';

export const AnalyseViewpointButton = () => {
  const selectedSpherical = useRecoilValue(state.selectedSpherical);

  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);

  const { showSnackBar } = useSnackBarMessage({});

  const [markLocationAnalysedMutation, { loading }] =
    useMarkViewPointAsAnalysedFromCurrentAssemblyMutation();

  // Callback functions to call mutation APIs
  const handleAnalyseViewpointButtonClick = useCallback(() => {
    if (!selectedSpherical?.id || !selectedAssemblyId) {
      showSnackBar('Something went wrong, please try again later', 'error');
      return;
    }

    markLocationAnalysedMutation({
      variables: {
        input: {
          assemblyId: selectedAssemblyId,
          viewpointId: selectedSpherical?.id,
          lastModifiedFor: 'Mark viewpoint as analysed',
        },
      },
      // Refetch the currently selected assembly's viewpoints, so that the newly
      // marked or unmarked viewpoint's color can be updated in the 3D viewer
      refetchQueries: [FindAssemblyForViewPointsDocument],
      onCompleted: (response) => {
        if (response?.markViewPointAsAnalysedFromCurrentAssembly)
          showSnackBar('Success', 'success');
      },
      onError: () => showSnackBar('Mark viewpoint analysed failed, please try again', 'error'),
    });
  }, [markLocationAnalysedMutation, selectedAssemblyId, selectedSpherical?.id, showSnackBar]);

  const { data: assemblyViewpointsData } = useFindAssemblyForViewPointsQuery({
    variables: { assemblyId: selectedAssemblyId },
    skip: !selectedAssemblyId,
    fetchPolicy: 'cache-first',
  });

  const isSelectedViewPointAnalysed = useMemo(() => {
    const assemblyViewpoints = assemblyViewpointsData?.assembly?.seenByViewpoints || undefined;

    if (!assemblyViewpoints) return false;

    if (assemblyViewpoints.length === 0) return false;

    // Find the currently selected spherical in the assembly viewpoints
    const selectedSphericalFoundInCurrentAssemblyViewpoints = assemblyViewpoints.find(
      (viewpoint) => viewpoint.location.id === selectedSpherical?.id
    );

    // 1. Check if the spherical was found
    // 2. Check if it was analysed
    return !!selectedSphericalFoundInCurrentAssemblyViewpoints?.analysed;
  }, [assemblyViewpointsData?.assembly?.seenByViewpoints, selectedSpherical?.id]);

  return (
    <ResponsiveButton
      variant="contained"
      onClick={handleAnalyseViewpointButtonClick}
      startIcon={!isSelectedViewPointAnalysed && <CheckCircleOutlineIcon />}
      disabled={loading}
    >
      {isSelectedViewPointAnalysed ? `Reanalyse viewpoint` : `Mark complete`}
    </ResponsiveButton>
  );
};
