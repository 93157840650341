import { FC, SyntheticEvent, useEffect, useCallback, useRef, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormControlLabel, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { VisibilityBoxMode, VisibilityBoxProps } from '@abyss/3d-viewer';
import { useZonesForStructureForTaggingQuery, useZoneAndHistoriesForQuery, History } from '@/__generated__/graphql';
import { Zone } from '@/types';
import * as state from '@/state';
import * as selectors from '@/state/selectors';
import { DockPanel } from '@/components/shared/DockPanel';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import { NO_TAG_ASSEMBLY_NAME } from '@/constants';
import { GetStructureLocationsForTaggingDataLoaderQuery } from '@/__generated__/graphql';
import { mapLocationToCurrentImage } from '@/components/Analysis/Viewer/hooks/useImageFromLocationId';

export const Navigation: FC = () => {
  const router = useRouter();

  // To check if the selectedZone update
  // was triggered by a user action like dropdown change
  const zoneUpdatedManually = useRef(false);

  const [selectedSpherical, setSelectedSpherical] = useRecoilState(state.selectedSpherical);
  const structureIdFromURL = router?.query?.inspection as string;
  const structureId = useRecoilValue(state.selectedStructureId) || structureIdFromURL || '';

  const setZones = useSetRecoilState(state.zones);
  const [selectedZone, setSelectedZone] = useRecoilState(state.selectedZone);
  const [searchedHighlightedTag, setSearchedHighlightedTag] = useRecoilState(
    state.searchedHighlightedTag
  );

  const structureRelationships = useRecoilValue(state.structureRelationships);
  const setSelectedPartIds = useSetRecoilState(state.selectedPartIds);
  const setShouldHighlightAllPartsWithSameTag = useSetRecoilState(
    state.shouldHighlightAllPartsWithSameTag
  );
  const setCurrentVisibilityBox = useSetRecoilState(state.currentVisibilityBox);
  const setNormalVisibilityRange = useSetRecoilState(state.normalVisibilityRange);
  const setZoneSegmentsHeight = useSetRecoilState(state.zoneSegmentsHeight);
  const setShouldShowDemarcationOfZones = useSetRecoilState(state.shouldShowDemarcationOfZones);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const [zonesHistories, setZoneHistories] = useRecoilState(state.zoneHistories);

  const [
    { previousMode: previousAbyssViewerMode, mode: currentAbyssViewerMode },
    setAbyssViewerState,
  ] = useRecoilState(selectors.setAbyssViewerState);

  const allAssembliesNonFiltered = useRecoilValue(state.allAssemblies);
  const structureLocations = useRecoilValue(state.structureLocations);
  const setSearchedSphericalId = useSetRecoilState(state.searchedSphericalId);
  const byPartIdReference = useRef(structureRelationships?.byPartId);
  const allAssemblies = useMemo(
    () => allAssembliesNonFiltered.filter((assembly) => assembly.tagName !== NO_TAG_ASSEMBLY_NAME),
    [allAssembliesNonFiltered]
  );

  const [areUnselectedEquipmentHidden, setAreUnselectedEquipmentHidden] = useRecoilState(
    state.areUnselectedEquipmentHidden
  );

  const { data: zonesData } = useZonesForStructureForTaggingQuery({
    variables: {
      structureId,
    },
  });

  const { data: historiesData } = useZoneAndHistoriesForQuery({
    variables: {
      structureId,
    },
    // Skip running this query if we already have the data
    // i.e fetched before from another component
    skip: zonesHistories.length > 0,
  });

  useEffect(() => {
    if (!historiesData?.zonesAndHistoriesFor) return;

    const result = historiesData.zonesAndHistoriesFor;

    setZoneHistories(() =>
      result.map((item) => ({
        id: item._id,
        histories: item.histories as History[],
      }))
    );
  }, [historiesData?.zonesAndHistoriesFor, setZoneHistories]);

  useEffect(() => {
    /*
    When entering or leaving isolate part mode, we need to
    maintain the current view.
    To prevent resizing of the visibility box and to avoid
    updating the camera target in isolate mode, we exit early
    */
    if (previousAbyssViewerMode === 'IsolatePart' || currentAbyssViewerMode === 'IsolatePart')
      return;

    const visibilityBox = selectedZone?.box;
    if (visibilityBox) {
      const vBoxFromZone: VisibilityBoxProps = {
        min: [visibilityBox.min.x, visibilityBox.min.y, visibilityBox.min.z],
        max: [visibilityBox.max.x, visibilityBox.max.y, visibilityBox.max.z],
        mode: VisibilityBoxMode.Enabled,
        step: [0.25, 0.25, 0.1],
      };
      setCurrentVisibilityBox(vBoxFromZone);

      const center = vBoxFromZone.step.reduce<number[]>((previous, __, index) => {
        return [...previous, (vBoxFromZone.min[index] + vBoxFromZone.max[index]) / 2];
      }, []);

      if (center.length === 3) {
        setCameraTarget({
          position: [center[0], center[1] + 15, center[2] + 5],
          lookAt: [center[0], center[1], center[2]],
        });
      }
      setShouldShowDemarcationOfZones(false);
      setNormalVisibilityRange('ZoneOnly');
    } else {
      setNormalVisibilityRange('All');
    }
  }, [
    currentAbyssViewerMode,
    previousAbyssViewerMode,
    selectedZone,
    setCameraTarget,
    setCurrentVisibilityBox,
    setNormalVisibilityRange,
    setShouldShowDemarcationOfZones,
  ]);

  useEffect(() => {
    const zoneIdFromParameters = router?.query?.zoneid;
    if (zoneIdFromParameters) {
      const zone = zonesData?.zonesForStructure?.find((z) => z.id === zoneIdFromParameters);
      if (zone) {
        setSelectedZone(zone);
      }
    }
  }, [zonesData?.zonesForStructure, router?.query?.zoneid, setSelectedZone]);

  useEffect(() => {
    if (zonesData?.zonesForStructure) {
      const firstZone = zonesData?.zonesForStructure[0] || [];
      if (firstZone?.box?.min?.z && firstZone?.box?.max?.z) {
        setZoneSegmentsHeight(firstZone.box.max.z || firstZone.box.min.z);
      }
      setZones(zonesData.zonesForStructure);
    }
  }, [zonesData?.zonesForStructure, setZoneSegmentsHeight, setZones]);

  useEffect(() => {
    // Use the value stored in the useRef
    const byPartId = byPartIdReference.current;

    if (byPartId && searchedHighlightedTag) {
      const selectedParts: string[] = [];

      byPartId.forEach((part, partId) => {
        if (part.assemblyId && part.assemblyId === searchedHighlightedTag.id) {
          selectedParts.push(partId);
        }
      });
      setSelectedPartIds(selectedParts);
      setShouldHighlightAllPartsWithSameTag(true);
    } else {
      setShouldHighlightAllPartsWithSameTag(false);
    }
  }, [searchedHighlightedTag, setSelectedPartIds, setShouldHighlightAllPartsWithSameTag]);

  useEffect(() => {
    byPartIdReference.current = structureRelationships?.byPartId;
  }, [structureRelationships]);

  const handleZoneChanged = useCallback(
    (_: SyntheticEvent<Element, Event> | undefined, zone: Zone | null) => {
      setSelectedZone(zone);
    },
    [setSelectedZone]
  );

  const handleAutoCompleteChange = useCallback(
    (_: SyntheticEvent<Element, Event> | undefined, newZone: Zone | null) => {
      setAbyssViewerState({ mode: 'Normal', previousMode: undefined });
      zoneUpdatedManually.current = true;
      handleZoneChanged(undefined, newZone);
    },
    [handleZoneChanged]
  );

  const handleSearchedSphericalChange = useCallback(
    (
      _: SyntheticEvent<Element, Event> | undefined,
      newSpherical: GetStructureLocationsForTaggingDataLoaderQuery['allLocations'][0] | null
    ) => {
      if (newSpherical) setSelectedSpherical(mapLocationToCurrentImage(newSpherical));
      setSearchedSphericalId(newSpherical?.id);
    },
    [setSearchedSphericalId]
  );

  const handleSearchedHighlightedTagChanged = (
    _: SyntheticEvent<Element, Event>,
    tag: { id: string; tagName: string } | null
  ) => {
    setSearchedHighlightedTag(tag);
    if (!tag) {
      setAreUnselectedEquipmentHidden(false);
      setSelectedPartIds([]);
    }
  };

  const toggleUnselectedEquipmentVisisbility = useCallback(() => {
    setAreUnselectedEquipmentHidden((current) => !current);
  }, [setAreUnselectedEquipmentHidden]);

  const hideEquipmentSwitch = useMemo(() => {
    return (
      <Switch
        checked={areUnselectedEquipmentHidden}
        onChange={toggleUnselectedEquipmentVisisbility}
      />
    );
  }, [areUnselectedEquipmentHidden, toggleUnselectedEquipmentVisisbility]);

  return (
    <DockPanel>
      <Autocomplete
        id="zone-selector"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={zonesData?.zonesForStructure || []}
        getOptionLabel={(option) => option.name}
        defaultValue={selectedZone}
        value={selectedZone}
        disableListWrap
        autoHighlight
        disabled={!!selectedSpherical}
        onChange={handleAutoCompleteChange}
        renderInput={(parameters) => (
          <DockPanelItem>
            <TextField {...parameters} label="Zone" variant="standard" />
          </DockPanelItem>
        )}
      />

      <Autocomplete
        id="highlight-tag-parts"
        value={searchedHighlightedTag}
        isOptionEqualToValue={(option, value) => value && option.id === value.id}
        options={allAssemblies || []}
        getOptionLabel={(option) => option.tagName}
        disableListWrap
        onChange={handleSearchedHighlightedTagChanged}
        renderInput={(parameters) => (
          <DockPanelItem>
            <TextField {...parameters} label="Equipment Tag" variant="standard" />
          </DockPanelItem>
        )}
      />

      <Autocomplete
        id="search-spherical"
        options={structureLocations || []}
        getOptionLabel={(option) => option.name}
        disableListWrap
        autoHighlight
        onChange={handleSearchedSphericalChange}
        renderInput={(parameters) => (
          <DockPanelItem>
            <TextField {...parameters} label="Search Spherical" variant="standard" />
          </DockPanelItem>
        )}
      />

      {selectedSpherical && (
        <>
          <DockPanelItem>
            <TextField
              label="Image viewpoint"
              variant="standard"
              fullWidth
              value={selectedSpherical.name}
              disabled
            />
          </DockPanelItem>
          <DockPanelItem>
            <Typography>ESC to exit image viewpoint</Typography>
          </DockPanelItem>
        </>
      )}

      <FormControlLabel
        control={hideEquipmentSwitch}
        label="Hide Unselected"
        labelPlacement="start"
        disabled={!searchedHighlightedTag}
      />
    </DockPanel>
  );
};
