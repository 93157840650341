import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { AssemblyPoiWithCheckMark } from './types';
type Props = {
  poiInfo: AssemblyPoiWithCheckMark;
  onCheck: (id: string) => void;
};
export const LinkedPoiRow = ({ poiInfo, onCheck }: Props) => {
  const fullLabel = `${poiInfo?.name} - ${poiInfo?.poiId || ''}`;
  return (
    <FormGroup
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={poiInfo?.checked} onChange={() => onCheck(poiInfo.id)} />}
        label={fullLabel}
      />
    </FormGroup>
  );
};
