import { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Slider, { SliderProps } from '@mui/material/Slider';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as state from '@/state';

type EdlStrengthSliderProps = {
  title: string;
} & SliderProps;

const EDL_STRENGTH_LEVELS = [0, 0.125, 0.25, 0.5, 1, 2, 8, 16, 24];

const calcStrengthFromValue = (value: number) => {
  return EDL_STRENGTH_LEVELS.at(value) ?? 0;
};

const calcValueFromStrength = (strength: number) => {
  return EDL_STRENGTH_LEVELS.indexOf(strength) ?? 0;
};

const valueText = (value: number) => {
  return `${value}`;
};

export const EdlStrengthSlider = ({ title, ...props }: EdlStrengthSliderProps) => {
  const [edlStrength, setEdlStrength] = useRecoilState<number>(state.edlStrength);
  const [unfinishedValue, setUnfinishedValue] = useState(calcValueFromStrength(edlStrength));
  const enableDynamicPointSize = useRecoilValue<boolean>(state.enableDynamicPointSize);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const strength = calcStrengthFromValue(unfinishedValue);
      setEdlStrength(strength);
    }, 250);

    return () => clearTimeout(debounce);
  }, [setEdlStrength, unfinishedValue]);

  const handleValueChanged = (event: Event, value: number | number[]) => {
    setUnfinishedValue(value as number);
  };

  useEffect(() => {
    setUnfinishedValue((currentEdl) => {
      if (currentEdl < 1 && enableDynamicPointSize) return 1;
      return currentEdl;
    });
  }, [enableDynamicPointSize, setUnfinishedValue]);

  return (
    <div>
      <FormLabel>{title}</FormLabel>
      <Slider
        {...props}
        min={0}
        step={1}
        max={EDL_STRENGTH_LEVELS.length - 1}
        aria-label="EdlStrength"
        getAriaValueText={valueText}
        valueLabelDisplay="auto"
        value={unfinishedValue}
        onChange={handleValueChanged}
        size="small"
      />
    </div>
  );
};
