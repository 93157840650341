import { useMemo } from 'react';

import {
  GetAbyssViewerColoursForAnalysisQuery,
  useGetAbyssViewerColoursForAnalysisQuery,
  SettingTypesEnum,
} from '@/__generated__/graphql';
import { DEFAULT_COLORS } from '@/constants';
import { Colors } from '@/types';
import { convertColorHexStringToNumber } from '@/utils';

const getColorSettings = (
  colorSettings: GetAbyssViewerColoursForAnalysisQuery['setting'],
  colorKeyName: string
): number | undefined => {
  const colourCheck = colorSettings?.meta.find(
    (set) => set.__typename === 'StringMeta' && set.key === colorKeyName
  );
  if (colourCheck && 'colour' in colourCheck) {
    const colourString = colourCheck.colour;
    return convertColorHexStringToNumber(colourString);
  }
  return undefined;
};

export const useColors = () => {
  const settingType = SettingTypesEnum.ColourMapForAbyssViewerParts;
  const { data } = useGetAbyssViewerColoursForAnalysisQuery({
    variables: {
      type: settingType,
    },
    fetchPolicy: 'cache-first',
  });

  const allColors = useMemo((): Colors => {
    const result = { ...DEFAULT_COLORS, colors: [...DEFAULT_COLORS.colors] };

    if (data) {
      const { setting } = data;
      const coloursCheck = setting?.meta.find(
        (set) => set.__typename === 'StringArrayMeta' && set.key === 'colourMap'
      );
      if (coloursCheck && coloursCheck.__typename === 'StringArrayMeta') {
        const colours = coloursCheck.value;
        result.colors = colours.map((colour) => {
          return convertColorHexStringToNumber(colour);
        });
      }

      result.selectedColour = getColorSettings(setting, 'selectedPart') ?? result.selectedColour;
      result.noAssemblyColour = getColorSettings(setting, 'noAssembly') ?? result.noAssemblyColour;
      result.outOfScopeColour = getColorSettings(setting, 'outOfScope') ?? result.outOfScopeColour;
      result.foundAssemblyColour =
        getColorSettings(setting, 'foundAssemblyColour') ?? result.foundAssemblyColour;
      result.hoveredAssemblyColour =
        getColorSettings(setting, 'hoveredAssemblyColour') ?? result.hoveredAssemblyColour;
    }

    return result;
  }, [data]);

  return {
    allColors,
  };
};
