import { useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { METERS_TO_MILLIMETERS_FACTOR, localiseMillimetreMeasurement } from '@/utils/unitSystem';
import { UnitSystemEnum } from '@/__generated__/graphql';

type Props = {
  isExpanded: boolean;
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
  governingValue: {
    height: number;
    estimatedWallLoss: number;
    estimatedWallLossPercentage: number;
    residualThickness: number;
    residualThicknessPercentage: number;
    residualCorrosionAllowance: number;
    residualCorrosionAllowancePercentage: number;
  };
  unitSystem: UnitSystemEnum;
};

/**
 * Governing value details presented in an expandable/collapsible accordion
 */
export const GoverningValueAccordion = ({
  isExpanded,
  onChange,
  governingValue,
  unitSystem,
}: Props) => {
  const governingValueDisplay = useMemo(() => {
    const {
      height,
      estimatedWallLoss,
      estimatedWallLossPercentage,
      residualThickness,
      residualThicknessPercentage,
      residualCorrosionAllowance,
      residualCorrosionAllowancePercentage,
    } = governingValue;
    const heightInMillimeters = height * METERS_TO_MILLIMETERS_FACTOR;
    return {
      height: localiseMillimetreMeasurement(unitSystem, heightInMillimeters),
      estimatedWallLoss: localiseMillimetreMeasurement(unitSystem, estimatedWallLoss),
      estimatedWallLossPercentage: `${estimatedWallLossPercentage.toFixed(0)}%`,
      residualThickness: localiseMillimetreMeasurement(unitSystem, residualThickness),
      residualThicknessPercentage: `${residualThicknessPercentage.toFixed(0)}%`,
      residualCorrosionAllowance: localiseMillimetreMeasurement(
        unitSystem,
        residualCorrosionAllowance
      ),
      residualCorrosionAllowancePercentage: `${residualCorrosionAllowancePercentage.toFixed(0)}%`,
    };
  }, [governingValue, unitSystem]);

  return (
    <Accordion expanded={isExpanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="governing-value-content"
        id="governing-value-header"
      >
        <Typography>
          <b>Governing value&nbsp;</b>
          (RCA&nbsp;
          {governingValueDisplay.residualCorrosionAllowancePercentage})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>Height - {governingValueDisplay.height}</Typography>
        <Typography>
          Wall loss - {governingValueDisplay.estimatedWallLoss} (
          {governingValueDisplay.estimatedWallLossPercentage})
        </Typography>
        <Typography>
          Residual thickness - {governingValueDisplay.residualThickness} (
          {governingValueDisplay.residualThicknessPercentage})
        </Typography>
        <Typography>
          RCA - {governingValueDisplay.residualCorrosionAllowance} (
          {governingValueDisplay.residualCorrosionAllowancePercentage})
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
