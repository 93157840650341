import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { CreatedByDetails, History, Maybe, ZoneStatus } from '@/__generated__/graphql';
import { ZoneAssigneeList } from '../ZoneAssigneeList';
import {
  containerStyles,
  DashedLine,
  EmptyAvatar,
  Line,
  listStyles,
  StyledBorderedAvatar,
  tooltipStyles,
} from './styles';
import { StyledTooltip } from '@/components/shared/StyledTooltip';

type Props = {
  status: ZoneStatus;
  histories: History[];
};

type AvatarProps = {
  name: string | undefined;
  profilePicture?: string | undefined;
  allAssignees: Array<Maybe<CreatedByDetails> | undefined>;
  status: ZoneStatus;
};

const BorderedAvatar = ({ name, profilePicture, allAssignees, status }: AvatarProps) => (
  <StyledTooltip
    styles={tooltipStyles}
    title={<ZoneAssigneeList allAssignees={allAssignees} status={status} />}
  >
    <StyledBorderedAvatar src={profilePicture}>
      {/* If the user has a profile picture use it */}
      {/* Otherwise use the first letter of their name */}
      {profilePicture ? <></> : name?.charAt(0)}
    </StyledBorderedAvatar>
  </StyledTooltip>
);

const ActiveAvatar = ({ name, profilePicture, allAssignees, status }: AvatarProps) => {
  return (
    <StyledTooltip
      styles={tooltipStyles}
      title={<ZoneAssigneeList allAssignees={allAssignees} status={status} />}
    >
      <Box position="relative">
        <Box sx={containerStyles}>
          <ArrowDropDownIcon />
        </Box>
        <BorderedAvatar
          name={name}
          profilePicture={profilePicture}
          allAssignees={allAssignees}
          status={status}
        />
      </Box>
    </StyledTooltip>
  );
};

const CompletedAvatar = () => (
  <StyledBorderedAvatar sx={{ bgcolor: 'hsla(123, 38%, 71%, 1)' }}>
    <CheckRoundedIcon sx={{ color: 'black' }} />
  </StyledBorderedAvatar>
);

const getZoneStatusComponents = (
  status: ZoneStatus,
  {
    assignee,
    reviewer,
  }: {
    assignee: Maybe<CreatedByDetails>;
    reviewer: Maybe<CreatedByDetails>;
  },
  allTaggers: Array<Maybe<CreatedByDetails> | undefined>,
  allReviewers: Array<Maybe<CreatedByDetails> | undefined>
) => {
  const zoneStatusComponents = {
    [ZoneStatus.NotStarted]: [
      { component: <EmptyAvatar />, key: 'empty-avatar-1' },
      { component: <DashedLine />, key: 'dashed-line-1' },
      { component: <EmptyAvatar />, key: 'empty-avatar-2' },
      { component: <DashedLine />, key: 'dashed-line-2' },
      { component: <EmptyAvatar />, key: 'empty-avatar-3' },
    ],
    [ZoneStatus.InProgress]: [
      {
        component: (
          <ActiveAvatar
            name={assignee?.name}
            profilePicture={assignee?.profilePicture}
            allAssignees={allTaggers}
            status={ZoneStatus.InProgress}
          />
        ),
        key: 'active-avatar-1',
      },
      { component: <DashedLine />, key: 'dashed-line-3' },
      { component: <EmptyAvatar />, key: 'empty-avatar-4' },
      { component: <DashedLine />, key: 'dashed-line-4' },
      { component: <EmptyAvatar />, key: 'empty-avatar-5' },
    ],
    [ZoneStatus.InReview]: [
      {
        component: (
          <BorderedAvatar
            name={assignee?.name}
            profilePicture={assignee?.profilePicture}
            allAssignees={allTaggers}
            status={ZoneStatus.InProgress}
          />
        ),
        key: 'bordered-avatar-1',
      },
      { component: <Line />, key: 'line-1' },
      {
        component: (
          <ActiveAvatar
            name={reviewer?.name}
            profilePicture={reviewer?.profilePicture}
            allAssignees={allReviewers}
            status={ZoneStatus.InReview}
          />
        ),
        key: 'active-avatar-2',
      },
      { component: <DashedLine />, key: 'dashed-line-5' },
      { component: <EmptyAvatar />, key: 'empty-avatar-6' },
    ],
    [ZoneStatus.Completed]: [
      {
        component: (
          <BorderedAvatar
            name={assignee?.name}
            profilePicture={assignee?.profilePicture}
            allAssignees={allTaggers}
            status={ZoneStatus.InProgress}
          />
        ),
        key: 'bordered-avatar-1',
      },
      { component: <Line />, key: 'line-2' },
      {
        component: (
          <BorderedAvatar
            name={reviewer?.name}
            profilePicture={reviewer?.profilePicture}
            allAssignees={allReviewers}
            status={ZoneStatus.InReview}
          />
        ),
        key: 'bordered-avatar-3',
      },
      { component: <Line />, key: 'line-3' },
      { component: <CompletedAvatar />, key: 'completed-avatar-1' },
    ],
    [ZoneStatus.ReviewReady]: [
      {
        component: (
          <ActiveAvatar
            name={assignee?.name}
            profilePicture={assignee?.profilePicture}
            allAssignees={allTaggers}
            status={ZoneStatus.ReviewReady}
          />
        ),
        key: 'active-avatar-1',
      },
      { component: <Line />, key: 'line-4' },
      { component: <EmptyAvatar />, key: 'empty-avatar-4' },
      { component: <DashedLine />, key: 'dashed-line-4' },
      { component: <EmptyAvatar />, key: 'empty-avatar-5' },
    ],
  };

  return zoneStatusComponents[status];
};

export const ZoneStatusList = ({ status, histories }: Props) => {
  if (!status || !histories) return <></>;

  // Reverse the array to sort by date descending
  const reversedHistory = [...histories].reverse();
  // Finding the last assignee and reviewer
  // Since a zone can go through progress and review multiple times

  const assignee = reversedHistory.find(
    (history) => history.document.currentZoneStatus === ZoneStatus.InProgress
  )?.createdByDetails as Maybe<CreatedByDetails>;

  const reviewer = reversedHistory.find(
    (history) => history.document.currentZoneStatus === ZoneStatus.InReview
  )?.createdByDetails as Maybe<CreatedByDetails>;

  const allTaggers = reversedHistory
    .filter((history) => history.document.currentZoneStatus === ZoneStatus.InProgress)
    .map((eachTagger) => eachTagger.createdByDetails);

  const allReviewers = reversedHistory
    .filter((history) => history.document.currentZoneStatus === ZoneStatus.InReview)
    .map((eachReviewer) => eachReviewer.createdByDetails);

  const zoneStatusComponents = getZoneStatusComponents(
    status,
    {
      assignee,
      reviewer,
    },
    allTaggers,
    allReviewers
  );

  return (
    <Box sx={listStyles}>
      {zoneStatusComponents.map(({ key, component }) => (
        <span key={key}>{component}</span>
      ))}
    </Box>
  );
};
