import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { GetAbyssViewerColoursForTaggingQuery, useGetAbyssViewerColoursForTaggingQuery } from '@/__generated__/graphql';
import { DEFAULT_COLORS, NO_TAG_ASSEMBLY_NAME } from '@/constants';
import { AssemblyId, AssemblyInfo, Colors } from '@/types';
import { convertColorHexStringToNumber, getColorIndex } from '@/utils';
import { StateMap } from '@/utils/stateMap';
import * as state from '@/components/Inspection/Tagging/state';

const getColorSettings = (
  colorSettings: GetAbyssViewerColoursForTaggingQuery['setting'],
  colorKeyName: string
): number | undefined => {
  const colourCheck = colorSettings?.meta.find(
    (set) => set.__typename === 'StringMeta' && set.key === colorKeyName
  );
  if (colourCheck && 'colour' in colourCheck) {
    const colourString = colourCheck.colour;
    return convertColorHexStringToNumber(colourString);
  }
  return undefined;
};

export const useColors = (byAssemblyId: StateMap<AssemblyId, AssemblyInfo> | undefined) => {
  const { data } = useGetAbyssViewerColoursForTaggingQuery();

  const setColorByAssemblyId = useSetRecoilState(state.colorByAssemblyId);

  const allColors = useMemo((): Colors => {
    const result = { ...DEFAULT_COLORS, colors: [...DEFAULT_COLORS.colors] };

    if (data) {
      const { setting } = data;
      const coloursCheck = setting?.meta.find(
        (set) => set.__typename === 'StringArrayMeta' && set.key === 'colourMap'
      );
      if (coloursCheck && coloursCheck.__typename === 'StringArrayMeta') {
        const colours = coloursCheck.value;
        result.colors = colours.map((colour) => {
          return convertColorHexStringToNumber(colour);
        });
      }

      result.selectedColour = getColorSettings(setting, 'selectedPart') ?? result.selectedColour;
      result.noAssemblyColour = getColorSettings(setting, 'noAssembly') ?? result.noAssemblyColour;
      result.outOfScopeColour = getColorSettings(setting, 'outOfScope') ?? result.outOfScopeColour;
    }

    return result;
  }, [data]);

  const { colors, noAssemblyColour } = allColors;

  const colorsForAssembly: { [assemblyId: string]: number } = useMemo(() => {
    const result: { [assemblyId: string]: number } = {};

    byAssemblyId?.forEach((assembly, assemblyId) => {
      let color: number = noAssemblyColour;
      if (assembly.tagName !== NO_TAG_ASSEMBLY_NAME) {
        const colorIndex = getColorIndex(assemblyId, colors.length);
        if (colorIndex !== undefined) {
          color = colors[colorIndex];
        }
      }
      result[assemblyId] = color;
    });
    return result;
    // TODO: Performance improvement. By adding byAssemblyId as dependency, every time a new assembly tag name is tagged, colorsForAssembly is recalculated
    // because a user adds a new assembly, so we have to set a color for newly added assembly
  }, [colors, noAssemblyColour, byAssemblyId]);

  useEffect(() => {
    setColorByAssemblyId(colorsForAssembly);
  }, [colorsForAssembly, setColorByAssemblyId]);

  return {
    allColors,
    colorsForAssembly,
  };
};
