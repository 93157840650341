import { useMemo } from 'react';
import { CustomTextField, DropDownField, RadioButtonField } from '@/components/shared/CustomField';
import { TemplateField, TemplateFieldTypeEnum } from '@/__generated__/graphql';
import {
  ProposedPaintArea,
  ProposedPaintDiameter,
  ProposedPaintLength,
} from '@/components/shared/CalculatedFields';
import { InputAdormantHandlerMap } from '@/types';

type Props = {
  templateField: TemplateField;
  isEditing: boolean;
  value?: string;
  onChange?: (value: string) => void;
  inputAdormantHandlerMap: InputAdormantHandlerMap;
  disabled?: boolean;
};

export const TemplateFieldRenderer = ({
  templateField,
  isEditing,
  value,
  onChange,
  inputAdormantHandlerMap,
  disabled,
}: Props) => {
  const props = useMemo(() => {
    if (isEditing) {
      return {
        mode: 'Edit' as const,
        id: templateField.id,
        title: templateField.name,
        options: templateField.options,
        value,
        onFieldValueChange: (data: string) => {
          if (onChange) onChange(data);
        },
        disabled,
      };
    }
    return {
      mode: 'ReadOnly' as const,
      id: templateField.id,
      title: templateField.name,
      value,
    };
  }, [
    disabled,
    isEditing,
    onChange,
    templateField.id,
    templateField.name,
    templateField.options,
    value,
  ]);

  switch (templateField.type) {
    case TemplateFieldTypeEnum.Textarea:
    case TemplateFieldTypeEnum.Input:
      return <CustomTextField sx={{ mt: 2 }} {...props} />;

    case TemplateFieldTypeEnum.Numeric:
      return <CustomTextField sx={{ mt: 2 }} {...props} numeric />;
    case TemplateFieldTypeEnum.SingleSelect:
      return <DropDownField sx={{ mt: 2 }} {...props} />;
    case TemplateFieldTypeEnum.RadioButton:
      return <RadioButtonField sx={{ mt: 2 }} {...props} />;
    case TemplateFieldTypeEnum.MeasurementPaintLength:
      return (
        <ProposedPaintLength
          {...props}
          displayConfig={templateField.displayConfig}
          inputAdormantHandlerMap={inputAdormantHandlerMap}
        />
      );
    case TemplateFieldTypeEnum.MeasurementPaintDiameter:
      return (
        <ProposedPaintDiameter
          {...props}
          displayConfig={templateField.displayConfig}
          onFieldValueChange={onChange}
        />
      );

    case TemplateFieldTypeEnum.MeasurementPaintArea:
      return (
        <ProposedPaintArea
          {...props}
          displayConfig={templateField.displayConfig}
          onFieldValueChange={onChange}
        />
      );
    default:
      return <></>;
  }
};
