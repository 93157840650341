/* eslint-disable no-bitwise */
import { useEffect } from 'react';
import { ColorMap } from '@abyss/3d-viewer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import { Colors } from '@/types';
import { TRANSPARENT_COLOR } from '@/constants';
import { colorWithAlpha, convertColorHexStringToNumber } from '@/utils';

const rgbContrastHoveredAssemblyColour = 0xead624ff;
const extraOpacity = 0x33; // 20%
const opacityValueWhereRgbIsProminent = 0x80; // 50%

export const useNormalEffects = (
  colorMap: ColorMap | undefined,
  annotationPickingMap: ColorMap | undefined,
  allColors: Colors,
  assemblyId: string | undefined | null
) => {
  const currentSpherical = useRecoilValue(state.selectedSpherical);
  const structureRelationships = useRecoilValue(state.structureRelationship);
  const filteredAssemblyIds = useRecoilValue(state.filteredAssemblyIds);
  const hoveredAssemblyId = useRecoilValue(state.hoveredAssemblyId);
  const selectedAssemblyInfo = useRecoilValue(state.selectedAssemblyInfo);
  const blendOpacity = useRecoilValue(state.blendOpacity);
  const partLegends = useRecoilValue(state.partLegends);
  const setPickingMapsUpdateSequence = useSetRecoilState(state.pickingMapsUpdateSequence);

  useEffect(() => {
    if (currentSpherical) return;
    if (!colorMap) return;
    if (!annotationPickingMap) return;
    if (!structureRelationships) return;

    const annotationIdToAnnotation3d = structureRelationships?.annotationIdToAnnotation3d;
    const assemblyIdToAnnotation3dArray = structureRelationships?.assemblyIdToAnnotation3dArray;

    // TODO: TBC determine colours for highlighted assets and selected asset
    const defaultAssemblyColour = allColors.noAssemblyColour & 0xffffff09;
    const { foundAssemblyColour, hoveredAssemblyColour, colors } = allColors; // TODO: TBC name
    const selectedAssemblyColour = foundAssemblyColour;
    const clickHighlightColours = [hoveredAssemblyColour, colors[5]];

    if (assemblyId) {
      // First reset everything to faded out by default
      colorMap.clear(TRANSPARENT_COLOR);
      annotationPickingMap.clear(TRANSPARENT_COLOR);

      const selectedAnnotation3dReferences = assemblyIdToAnnotation3dArray[assemblyId];
      selectedAnnotation3dReferences?.forEach((annotation3dReference: number) => {
        const finalColor = colorWithAlpha(selectedAssemblyColour, blendOpacity);
        colorMap.setColor(annotation3dReference, finalColor);
        annotationPickingMap.setColor(annotation3dReference, selectedAssemblyColour);
      });

      if (structureRelationships.partsByAnnotationId && !structureRelationships.parts) {
        Object.keys(structureRelationships.partsByAnnotationId).forEach((part, index) => {
          const color = colors[(index % colors.length) - 1];
          const finalColor = colorWithAlpha(color, blendOpacity);
          const annotationIds = structureRelationships.partsByAnnotationId[part];

          annotationIds.forEach((annotationId) => {
            if (selectedAnnotation3dReferences.includes(annotationId)) {
              colorMap.setColor(annotationId, finalColor);
              annotationPickingMap.setColor(annotationId, finalColor);
            }
          });
        });
      }

      if (structureRelationships.parts) {
        Object.keys(structureRelationships.parts).forEach((part, index) => {
          const color = partLegends[structureRelationships.parts[part].class.toLowerCase()];
          const finalColor = colorWithAlpha(
            partLegends && color
              ? convertColorHexStringToNumber(color)
              : colors[(index % colors.length) - 1],
            blendOpacity
          );
          const annotationIds = structureRelationships.parts[part].annotations;

          annotationIds.forEach((annotationId) => {
            if (
              selectedAnnotation3dReferences &&
              selectedAnnotation3dReferences.includes(annotationId)
            ) {
              colorMap.setColor(annotationId, finalColor);
              annotationPickingMap.setColor(annotationId, finalColor);
            }
          });
        });
      }
    } else {
      // If no selected asset, only go through assets that we want to highlight

      // First reset everything to faded out (or transparent if no linked assembly) by default
      Object.keys(annotationIdToAnnotation3d!).forEach((annotationId) => {
        const annotation3dReference =
          annotationIdToAnnotation3d && annotationIdToAnnotation3d[annotationId];
        colorMap.setColor(annotation3dReference!, TRANSPARENT_COLOR);
        annotationPickingMap.setColor(annotation3dReference, defaultAssemblyColour);
      });

      filteredAssemblyIds.forEach((filteredAssemblyId) => {
        if (selectedAssemblyInfo) {
          const annotation3dReferences =
            assemblyIdToAnnotation3dArray && assemblyIdToAnnotation3dArray[filteredAssemblyId];
          if (filteredAssemblyId === selectedAssemblyInfo) {
            annotation3dReferences?.forEach((annotation3dReference: number) => {
              const annotationColor = clickHighlightColours[0];
              colorMap.setColor(annotation3dReference, annotationColor);
              annotationPickingMap.setColor(annotation3dReference, annotationColor);
            });
          } else {
            annotation3dReferences?.forEach((annotation3dReference) => {
              colorMap.setColor(annotation3dReference, foundAssemblyColour);
              annotationPickingMap.setColor(annotation3dReference, foundAssemblyColour);
            });
          }
        } else {
          const annotation3dReferences =
            assemblyIdToAnnotation3dArray && assemblyIdToAnnotation3dArray[filteredAssemblyId];
          annotation3dReferences?.forEach((annotation3dReference) => {
            if (filteredAssemblyId === hoveredAssemblyId) {
              if (blendOpacity <= opacityValueWhereRgbIsProminent) {
                // highlight the hovered assembly with better contrast against RGB colours
                colorMap.setColor(annotation3dReference!, rgbContrastHoveredAssemblyColour);
              } else {
                // regular highlight colour for when the structure is coloured foundAssemblyColour
                colorMap.setColor(annotation3dReference!, hoveredAssemblyColour);
              }
            } else if (hoveredAssemblyId) {
              // an asset is hovered and highlighted, colour every other asset with an increaced fade colour
              const finalColor = colorWithAlpha(
                foundAssemblyColour,
                Math.max(1, blendOpacity - extraOpacity)
              );
              colorMap.setColor(annotation3dReference!, finalColor);
            } else {
              // no asset is highlighted, colour everything according to the the opacity set inside <BlendingMode />
              const finalColor = colorWithAlpha(foundAssemblyColour, blendOpacity);
              colorMap.setColor(annotation3dReference!, finalColor);
            }
            annotationPickingMap.setColor(annotation3dReference, foundAssemblyColour);
          });
        }
      });
    }
    setPickingMapsUpdateSequence((current) => current + 1);
  }, [
    allColors,
    allColors.colors,
    allColors.noAssemblyColour,
    assemblyId,
    blendOpacity,
    colorMap,
    annotationPickingMap,
    currentSpherical,
    hoveredAssemblyId,
    filteredAssemblyIds,
    structureRelationships,
    selectedAssemblyInfo,
    partLegends,
  ]);
};
