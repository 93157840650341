import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import { assemblyTemplate as assemblyTemplateState } from '@/components/Analysis/state';
import {
  EquipmentDetailField,
  EquipmentDetailTypeEnum,
  OptionFieldTypeEnum,
  EquipmentDetailSubTypeEnum,
  Maybe,
  TemplateFieldTypeEnum,
  EqiupmentCardFieldTypeEnum,
} from '@/__generated__/graphql';
import { EquipmentCondition } from '../EquipmentCondition';
import { EquipmentDetailsTypography, HorizontalBar } from '../styles';
import { getTemplateFieldData, toFixedTwoDecimalPoints } from '@/utils';
import { SUBSTRATE_CONDITION } from '@/constants';
import { SelectUpdater } from './OptionFieldDisplays';
import * as state from '@/components/Analysis/state';
import { useUser } from '@auth0/nextjs-auth0';

const titleStyles = { fontWeight: 600 };

type Props = {
  data: {
    [key: string]: string;
  };
  area: number;
};

type TemplateListProps = {
  fieldMeta: Partial<EquipmentDetailField>;
  fieldData: Array<{
    name: string | undefined;
    value: Maybe<string> | undefined;
    type: string | undefined;
    templateKey: Maybe<string> | undefined;
  }>;
  area: number;
};

const corrosionPriorities = [
  SUBSTRATE_CONDITION.HEAVY,
  SUBSTRATE_CONDITION.MODERATE,
  SUBSTRATE_CONDITION.LIGHT,
];

const calculateSubstrateCondition = (
  corrosionData: {
    name: string | undefined;
    value: Maybe<string> | undefined;
    type:
      | OptionFieldTypeEnum
      | TemplateFieldTypeEnum
      | EqiupmentCardFieldTypeEnum
      | EquipmentDetailTypeEnum
      | undefined;
  }[]
) => {
  if (corrosionData.every((item) => Number(item?.value) === 0)) return SUBSTRATE_CONDITION.CLEAN;
  const corrosion = corrosionData?.filter((item) => Number(item.value) > 0);
  const filteredCorrosion = corrosionPriorities.find((priority) =>
    corrosion.some((item) => item.name === priority)
  );
  return filteredCorrosion || 'N/A';
};

const calculateDegreeOfRusting = (
  data: {
    name: string | undefined;
    value: Maybe<string> | undefined;
    type:
      | OptionFieldTypeEnum
      | TemplateFieldTypeEnum
      | EqiupmentCardFieldTypeEnum
      | EquipmentDetailTypeEnum
      | undefined;
  }[]
) => {
  const title =
    data?.find((item) => item.type === EquipmentDetailTypeEnum.DefectConsolidatedDegree)?.value ||
    'N/A';
  const ratio =
    data?.find((item) => item.type === EquipmentDetailTypeEnum.DefectCorrosionRatio)?.value ||
    'N/A';
  const area =
    data?.find((item) => item.type === EquipmentDetailTypeEnum.DefectCorrosionArea)?.value || 'N/A';
  return {
    title,
    ratio,
    area,
  };
};

const TemplateListType = ({ fieldData, fieldMeta, area }: TemplateListProps) => {
  const { user } = useUser();

  const userRoles = user?.['http://abyss.com/user/roles'] as string[] | undefined;

  const allAssemblyTemplates = useRecoilValue(state.allAssemblyTemplates);
  const selectedAssemblyTemplateId = useRecoilValue(state.selectedAssemblyTemplateId);

  const template = allAssemblyTemplates.find((t) => t.id === selectedAssemblyTemplateId);

  return (
    <Box>
      <EquipmentDetailsTypography sx={titleStyles}>{fieldMeta.name}</EquipmentDetailsTypography>
      {fieldData.map((data) => {
        const field = template?.fields.find((f) => f.key === data.templateKey);
        // Roles stored on the field, if the user has one of these roles, they can edit the field
        // theres also a check on the backend to ensure the user has the correct role
        const editRoles = field?.editRoles;
        const userHasOneOfEditRoles = userRoles?.some((role) => editRoles?.includes(role));
        const shouldShowEditableField = field?.isEditable && userHasOneOfEditRoles;

        switch (data.type) {
          case OptionFieldTypeEnum.SimpleText:
            return (
              <>
                {shouldShowEditableField && <SelectUpdater {...data} />}
                {!shouldShowEditableField && data.value && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      my: 1,
                    }}
                  >
                    <EquipmentDetailsTypography>{data.name}</EquipmentDetailsTypography>
                    <EquipmentDetailsTypography fontWeight={600}>
                      {data.value}
                    </EquipmentDetailsTypography>
                  </Box>
                )}
              </>
            );
          case OptionFieldTypeEnum.CircularProgressBar:
            return (
              <EquipmentCondition
                title={data.name ?? ''}
                valueInt={toFixedTwoDecimalPoints(data.value) ?? 0}
                area={area}
                subType={fieldMeta.subType ?? ''}
              />
            );
          default:
            return <></>;
        }
      })}
      {fieldMeta.subType === null && <HorizontalBar />}
    </Box>
  );
};

type DefectMaxSeverityProps = {
  fieldMeta: Partial<EquipmentDetailField>;
  corrosionSubstrateCondition: string;
  consolidatedDegreeOfRusting: {
    title: string;
    ratio: string;
    area: string | number;
  };
};

const DefectMaxSeverity = ({
  fieldMeta,
  corrosionSubstrateCondition,
  consolidatedDegreeOfRusting,
}: DefectMaxSeverityProps) => {
  const computedStyles =
    fieldMeta?.subType === EquipmentDetailSubTypeEnum.DefectConsolidatedDegreeOfRusting
      ? { mt: 3 }
      : { display: 'flex', justifyContent: 'space-between', mt: 3, my: 1 };

  return (
    <Box sx={computedStyles}>
      <EquipmentDetailsTypography
        sx={
          fieldMeta?.subType === EquipmentDetailSubTypeEnum.DefectConsolidatedDegreeOfRusting
            ? titleStyles
            : {}
        }
      >
        {fieldMeta?.name}
      </EquipmentDetailsTypography>
      {fieldMeta.subType === EquipmentDetailSubTypeEnum.DefectSubstrateCondition && (
        <EquipmentDetailsTypography fontWeight={600}>
          {corrosionSubstrateCondition.toUpperCase()}
        </EquipmentDetailsTypography>
      )}
      {fieldMeta.subType === EquipmentDetailSubTypeEnum.DefectConsolidatedDegreeOfRusting && (
        <EquipmentCondition
          title={consolidatedDegreeOfRusting.title}
          valueInt={consolidatedDegreeOfRusting.ratio}
          area={consolidatedDegreeOfRusting.area}
        />
      )}
    </Box>
  );
};

export const EquipmentDetailRenderer = ({ data, area }: Props) => {
  const assemblyTemplate = useRecoilValue(assemblyTemplateState);
  const [showHorizontalBar, setShowHorizontalBar] = useState(false);

  const corrosionData = useMemo(() => {
    const corrosionDataList = assemblyTemplate.equipmentDetails
      .filter((field) => field.subType === EquipmentDetailSubTypeEnum.DefectCorrosion)
      .flatMap((list) => list.options ?? []);
    return getTemplateFieldData(corrosionDataList, data);
  }, [data, assemblyTemplate]);

  const substrateCondition = useMemo(() => {
    return calculateSubstrateCondition(corrosionData);
  }, [corrosionData]);

  const degreeOfRusting = useMemo(() => {
    const consolidateData = assemblyTemplate.equipmentDetails
      .filter(
        (field) => field.subType === EquipmentDetailSubTypeEnum.DefectConsolidatedDegreeOfRusting
      )
      .flatMap((list) => list.options ?? []);
    const consolidateDataList = getTemplateFieldData(consolidateData, data);
    return calculateDegreeOfRusting(consolidateDataList);
  }, [assemblyTemplate, data]);

  const equipmentDetailRender = useMemo(() => {
    return assemblyTemplate.equipmentDetails.map((detailsObject) => {
      const options = detailsObject?.options || [];
      const fieldData = getTemplateFieldData(options, data);

      setShowHorizontalBar(true);
      switch (detailsObject.type) {
        case EquipmentDetailTypeEnum.TemplateList: {
          return (
            <TemplateListType
              key={detailsObject.id}
              fieldData={fieldData}
              fieldMeta={detailsObject}
              area={area}
            />
          );
        }
        case EquipmentDetailTypeEnum.DefectMaxSeverity:
        case EquipmentDetailTypeEnum.DefectConsolidatedDegree:
          return (
            <DefectMaxSeverity
              key={detailsObject.id}
              fieldMeta={detailsObject}
              corrosionSubstrateCondition={substrateCondition}
              consolidatedDegreeOfRusting={degreeOfRusting}
            />
          );
        default:
          return <></>;
      }
    });
  }, [assemblyTemplate, data, area, substrateCondition, degreeOfRusting]);

  return (
    <>
      {equipmentDetailRender}
      {showHorizontalBar && <HorizontalBar />}
    </>
  );
};
