import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Maybe, useInlineUpdateSingleAssemblyTemplateDataMutation } from '@/__generated__/graphql';
import { EquipmentDetailsTypography } from '../styles';
import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import { useMemo, useState } from 'react';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { GraphQLErrors } from '@apollo/client/errors';

type Props = {
  name: string | undefined;
  value: Maybe<string> | undefined;
  type: string | undefined;
  templateKey: Maybe<string> | undefined;
};

export const SelectUpdater = ({ name, value, templateKey }: Props) => {
  const { showSnackBar } = useSnackBarMessage({
    variant: 'filled',
  });



  const [mutate, { loading }] = useInlineUpdateSingleAssemblyTemplateDataMutation({});

  const allAssemblyTemplates = useRecoilValue(state.allAssemblyTemplates);
  const selectedAssemblyTemplateId = useRecoilValue(state.selectedAssemblyTemplateId);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);

  const template = allAssemblyTemplates.find((t) => t.id === selectedAssemblyTemplateId);
  const field = template?.fields.find((f) => f.key === templateKey);

  const initialValue = useMemo(() => {
    if (!value) return undefined;
    if (!field?.options?.includes(value)) return undefined;
    return value;
  }, [field?.options, value]);

  const [selectValue, setSelectValue] = useState<string | undefined>(initialValue);

  if (!field) return 'N/A';

  const onChange = async (event: SelectChangeEvent<string>) => {
    if (!selectedAssemblyId) {
      showSnackBar('Could not update. Missing selectedAssemblyId', 'error');
      return;
    }

    if (!templateKey) {
      showSnackBar('Could not update. Missing templateKey', 'error');
      return;
    }

    if (!selectedAssemblyTemplateId) {
      showSnackBar('Could not update. Missing selectedAssemblyTemplateId', 'error');
      return;
    }

    try {
      const response = await mutate({
        variables: {
          input: {
            assemblyId: selectedAssemblyId,
            key: templateKey,
            newValue: event.target.value,
            templateId: selectedAssemblyTemplateId,
            lastModifiedFor: `${name} update`,
          },
        },
      });
      const updatedValue =
        response?.data?.updateSingleTemplateData?.templateFieldData?.[templateKey];
      if (updatedValue) {
        setSelectValue(updatedValue);
        showSnackBar('Updated successfully', 'success');
      } else {
        showSnackBar('Could not update. Invalid response from server', 'error');
      }
    } catch (error: unknown) {
      const gqlErrors = error as GraphQLErrors;
      const first = gqlErrors?.[0];
      showSnackBar(first?.message || 'Something went wrong. Please contact support', 'error');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        my: 1,
      }}
    >
      <EquipmentDetailsTypography>{name}</EquipmentDetailsTypography>
      <Select
        disabled={loading}
        placeholder="Select"
        value={selectValue}
        onChange={onChange}
        sx={{
          minWidth: '100px',
          textOverflow: 'ellipsis',
          width: '50%',
          fontSize: '1.2rem',
          height: '2.5rem',
        }}
      >
        {field.options?.map((option) => <MenuItem value={option}>{option}</MenuItem>)}
      </Select>
    </Box>
  );
};
