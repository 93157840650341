import { useGetAssemblyPipeSpecLazyQuery } from '@/__generated__/graphql';
import { selectedAssemblyId, unitSystem as unitSystemState } from '@/components/Analysis/state';
import { localiseMillimetreMeasurement } from '@/utils/unitSystem';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Detail, DetailsAccordion } from './StyledComponents';
import { Skeleton, Typography } from '@mui/material';

const SkeletonLoading = () =>
  Array.from({ length: 4 }, (_, index) => <Skeleton key={index} width="100%" height={32} />);

export const PipeSpec = () => {
  const assemblyId = useRecoilValue(selectedAssemblyId);
  const [getPipeSpec, { data, loading, error }] = useGetAssemblyPipeSpecLazyQuery();

  useEffect(() => {
    if (assemblyId) getPipeSpec({ variables: { assemblyId } });
  }, [assemblyId, getPipeSpec]);

  const unitSystem = useRecoilValue(unitSystemState);

  const localiseValue = useCallback(
    (value?: number | null) => localiseMillimetreMeasurement(unitSystem, value),
    [unitSystem]
  );

  const { title, ...pipeSpecs } = useMemo(() => {
    const pipeSpec = data?.assembly?.pipeSpec;
    if (!pipeSpec) return { title: 'Pipe Spec' };

    const { spec, size, schedule, material, nominalThickness, nominalCorrosionAllowance } =
      pipeSpec;
    return {
      title: `Pipe spec${spec ? ` - ${spec}` : ''}`,
      'Pipe size': `${size}, ${schedule}`,
      Material: material,
      'Nominal thickness': localiseValue(nominalThickness),
      'Nominal corrosion allowance': localiseValue(nominalCorrosionAllowance),
    };
  }, [data?.assembly?.pipeSpec, localiseValue]);

  return (
    <DetailsAccordion title={title}>
      {error ? (
        <Typography fontSize="14px" fontWeight="500" textAlign="center">
          Failed to load pipe spec
        </Typography>
      ) : loading ? (
        <SkeletonLoading />
      ) : (
        <>
          {Object.entries(pipeSpecs).map(([key, value]) => (
            <Detail key={key} title={key} value={value || 'N/A'} />
          ))}
        </>
      )}
    </DetailsAccordion>
  );
};
