import { useAssemblyInfoFromAssemblyId } from '@/state';
import { AllHistoriesQuery } from '@/__generated__/graphql';
import { formatDate, formatName } from '@/utils';

type PartsAddedToAssemblyEntryProps = {
  history: AllHistoriesQuery['allHistories'][0];
};

export const PartsAddedToAssemblyEntry = ({ history }: PartsAddedToAssemblyEntryProps) => {
  const assembly = useAssemblyInfoFromAssemblyId(history.document.assemblyId);
  return (
    <p>
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      <b>Tagged {assembly?.tagName}</b>
      <br />
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      {formatName(history.createdByName)} - {formatDate(new Date(Number(history.createdAt)))}
    </p>
  );
};
