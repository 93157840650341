import React, { useCallback, useMemo, useState } from 'react';
import { PointOfInterestDocument } from '@/types';
import {
  useGetOneAssemblyForBlistersQuery,
  useGetRecommendationsForAssemblyQuery,
} from '@/__generated__/graphql';
import { NominateDialog } from '@/components/Analysis/modules/pointOfInterest/aitInsights/Blisters/NominateDialog';
import { DetailsAccordion } from '../StyledComponents';
import { BlistersTable } from './BlistersTable';

type Props = { assemblyId: string | undefined };

export const Blisters = ({ assemblyId }: Props) => {
  const [blisterIdToNominate, setBlisterIdToNominate] = useState<string | undefined>();
  const [isNominateBlisterOpen, setIsNominateBlisterOpen] = useState(false);

  const { data } = useGetRecommendationsForAssemblyQuery({
    variables: {
      assemblyId: assemblyId!,
    },
    fetchPolicy: 'network-only',
    skip: !assemblyId,
  });

  const { data: assemblyQueryResponse } = useGetOneAssemblyForBlistersQuery({
    variables: {
      assemblyId: assemblyId!,
    },
    skip: !assemblyId,
  });

  const governingBlisterId = useMemo(
    () => assemblyQueryResponse?.assembly?.recommendation?.governingValue?.pointOfInterest?.id,
    [assemblyQueryResponse?.assembly?.recommendation?.governingValue?.pointOfInterest?.id]
  );

  const handleOnNominateBlisterClicked = useCallback(
    (blister: PointOfInterestDocument) => {
      if (!assemblyId) {
        return;
      }

      setBlisterIdToNominate(blister.id);
      setIsNominateBlisterOpen(true);
    },
    [assemblyId]
  );

  const closeNominateBlister = useCallback(() => {
    setIsNominateBlisterOpen(false);
  }, []);

  return (
    <>
      <DetailsAccordion title="Associated Blisters">
        <BlistersTable
          assemblyId={assemblyId}
          onNominateBlisterClicked={handleOnNominateBlisterClicked}
          governingBlisterId={governingBlisterId}
        />
      </DetailsAccordion>
      {isNominateBlisterOpen && assemblyId && data?.assembly?.tagName && blisterIdToNominate && (
        <NominateDialog
          isOpen={isNominateBlisterOpen}
          onClose={closeNominateBlister}
          assemblyId={assemblyId}
          assemblyTagName={data?.assembly.tagName}
          blisterId={blisterIdToNominate || ''}
          pipeSpec={data?.assembly?.pipeSpec}
        />
      )}
    </>
  );
};
