import { Box, Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import * as poiState from '../../../../state';
import { useGeneralFields, useTriggerPoiEditing } from '../../hooks';
import { GeneralFieldsForm } from '../GeneralFieldsForm';
import { PoiAccessibilityEnum, useUpdatePoiGeneralFieldsMutation } from '@/__generated__/graphql';
import { StyledForm } from './styles';
import { SaveCancelFormButtons } from '../SaveCancelFormButtons';
import { convertTimeStampToDate, upperSnakeCase } from '@/utils';
import { saveCancelButtonCommonStyles } from '../../../styles';
import { PointOfInterestDocument } from '@/__generated__/graphql';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import * as cuboidsState from '../../../../../cuboids/state';
import { useAnalysisCuboidActions } from '../../../../../cuboids';
import { AnalysisCuboid } from '@/types';
import { AllPointOfInterestsForDataLoaderDocument } from '@/__generated__/graphql';
import { VOI_POI_TYPES } from '@/constants';
import { LinkedPoiEditor } from '../LinkedPoiEditor';
import { useGetAllTeamsForCreateOrEditPoiQuery } from '@/__generated__/graphql';
import { PoiFormSubHeading } from '../styles';

export const UpdateGeneralFields = () => {
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const setPoiFilteredByCheckbox = useSetRecoilState(poiState.poiFilteredByCheckbox);
  const currentCuboid = useRecoilValue(cuboidsState.currentCuboid);
  const isCuboidEditorEnabled = useRecoilValue(cuboidsState.isCuboidEditorEnabled);

  const poi = editPointOfInterest?.pointOfInterest;
  const [loading, setLoading] = useState(false);
  const navbarHeight = useNavbarHeight();

  const formattedDueDate = convertTimeStampToDate(poi?.dueDate) || new Date();

  const modifyCuboidDataInPoi = useCallback(
    (updatedData: AnalysisCuboid, targetId: string) => {
      setPoiFilteredByCheckbox((current) => {
        const copy = [...current];
        const updatedPoiIndex = copy.findIndex(({ id }) => id === targetId);
        if (updatedPoiIndex === -1) return current;

        const updatedPoi = {
          ...copy[updatedPoiIndex],
          cuboids: [updatedData],
        };

        copy[updatedPoiIndex] = updatedPoi;

        return copy;
      });
    },
    [setPoiFilteredByCheckbox]
  );

  const generalFieldsData = useGeneralFields({
    assignee: poi?.assignee || undefined,
    dueDate: formattedDueDate,
    name: poi?.name || undefined,
    poiId: poi?.poiId || undefined,
    status: poi?.status || undefined,
    visibility: poi?.visibility || undefined,
    teamIds: poi?.teams?.map(({ id }) => id) || [],
    workpackNumber: poi?.workpackNumber || undefined,
    comment: poi?.comment || undefined,
    accessibility: poi?.accessibility || undefined,
  });
  const { data: teamsData, loading: teamsLoading } = useGetAllTeamsForCreateOrEditPoiQuery();

  const teamOptions = useMemo(() => {
    return teamsData?.allTeams?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [teamsData?.allTeams]);

  const [mutate] = useUpdatePoiGeneralFieldsMutation({});

  const { showSnackBar } = useSnackBarMessage({});

  const { cancelEditing } = useTriggerPoiEditing();

  const { stopEditingPoiCuboids, doneEditingPoiCuboids } = useAnalysisCuboidActions();

  const isPoiTypeOfVoi = VOI_POI_TYPES.includes(poi?.type || '');

  const handleSubmit = useCallback(() => {
    setLoading(true);
    const stringifiedDueDate = String(generalFieldsData.state.dueDate);

    const accessibilityEnum = Object.values(PoiAccessibilityEnum).find(
      (accessibilityValue) =>
        accessibilityValue === upperSnakeCase(generalFieldsData.state.accessibility || '')
    );
    // Do not pass poiId for update mutation since this id is not user defined
    const { poiId, ...generalFieldsState } = generalFieldsData.state;
    mutate({
      variables: {
        input: {
          pointOfInterestId: poi?.id || '',
          ...generalFieldsState,
          dueDate: stringifiedDueDate || undefined,
          ...(accessibilityEnum ? { accessibility: accessibilityEnum } : {}),
          cuboids:
            isPoiTypeOfVoi && currentCuboid
              ? [
                  {
                    position: currentCuboid.position,
                    scale: currentCuboid.scale,
                    rotation: currentCuboid.rotation,
                  },
                ]
              : undefined,
        },
      },
      refetchQueries: [PointOfInterestDocument, AllPointOfInterestsForDataLoaderDocument],
    })
      .then((response) => {
        const newCuboids = response?.data?.updatePointOfInterest?.cuboids;
        if (newCuboids) {
          modifyCuboidDataInPoi(newCuboids[0] as AnalysisCuboid, poi?.id || '');
        }
        showSnackBar('Point of interest updated', 'success');
        cancelEditing();
        doneEditingPoiCuboids();
      })
      .catch(() => {
        showSnackBar('Could not update point of interest', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    cancelEditing,
    currentCuboid,
    generalFieldsData.state,
    isPoiTypeOfVoi,
    mutate,
    poi?.id,
    showSnackBar,
    modifyCuboidDataInPoi,
    doneEditingPoiCuboids,
  ]);

  return (
    <Box height="100%">
      <StyledForm
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Stack
          sx={{
            height: `calc(100vh / var(--zoom) - ${navbarHeight}px)`,
            overflowY: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Box p={2} flex={1} mb="85px">
            <PoiFormSubHeading variant="h5">Edit General</PoiFormSubHeading>
            {isPoiTypeOfVoi && <LinkedPoiEditor />}
            <GeneralFieldsForm
              teamsLoading={teamsLoading}
              teamOptions={teamOptions}
              dataHook={generalFieldsData}
            />
          </Box>
          <SaveCancelFormButtons
            cancelButtonLabel="Cancel"
            buttonLabel="Save"
            loadingLabel="Updating..."
            onCancel={() => {
              stopEditingPoiCuboids(editPointOfInterest?.pointOfInterest);
              cancelEditing();
            }}
            loading={loading}
            sx={saveCancelButtonCommonStyles.sx}
            saveButtonStyles={saveCancelButtonCommonStyles.saveButtonStyles}
            cancelButtonStyles={saveCancelButtonCommonStyles.cancelButtonStyles}
            saveButtonDisabled={isCuboidEditorEnabled}
          />
        </Stack>
      </StyledForm>
    </Box>
  );
};
