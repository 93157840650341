import * as React from 'react';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Divider, InputAdornment, Skeleton, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  applyFilters as applyFiltersState,
  selectedTemplateFiltersState,
} from '@/components/Analysis/state';
import { useFilteredOptionsLazyQuery } from '@/__generated__/graphql';

type Props = {
  templateId: string;
  name: string;
  templateFieldId?: string;
  serverSide: boolean;
  isExpanded: boolean;
  templateFieldKey: string;
  selected: { [key: string]: string[] };
};

type FilterOptionVariable = {
  templateId: string;
  fieldKey: string;
  fieldId?: string;
  count?: number;
};

export const SearchList = ({
  templateId,
  name,
  templateFieldKey,
  serverSide,
  isExpanded,
  selected,
}: Props) => {
  const [searchTerms, setSearchTerms] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [unfinishedSearchTerm, setUnfinishedSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTemplateFilters, setSelectedTemplateFilters] = useRecoilState(
    selectedTemplateFiltersState
  );
  const setApplyFilters = useSetRecoilState(applyFiltersState);
  const list = React.useRef<string[]>([]);

  const [fetchFilteredOptions] = useFilteredOptionsLazyQuery({ fetchPolicy: 'no-cache' });
  const fetchInitialOptions = useCallback(async () => {
    const filterOptionsVariables: FilterOptionVariable = {
      templateId,
      fieldKey: templateFieldKey,
    };
    if (serverSide) {
      filterOptionsVariables.count = 10;
    }
    setLoading(true);
    await fetchFilteredOptions({
      variables: filterOptionsVariables,
    })
      .then(({ data }) => {
        if (data?.filteredOptions[0]?.options) {
          list.current = data.filteredOptions[0].options;
          setFilteredOptions(data.filteredOptions[0].options);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [fetchFilteredOptions, serverSide, templateFieldKey, templateId]);

  useEffect(() => {
    if (isExpanded && list.current.length === 0) {
      fetchInitialOptions();
    }
  }, [fetchInitialOptions, isExpanded]);

  const filterOptions = useCallback(async () => {
    if (list.current.length === 0) return;
    if (searchTerms === '') {
      setFilteredOptions(list.current);
      return;
    }
    if (serverSide) {
      const { data } = await fetchFilteredOptions({
        variables: {
          templateId,
          fieldKey: templateFieldKey,
          q: searchTerms,
          count: 10,
        },
      });
      if (data?.filteredOptions[0]?.options) {
        setFilteredOptions(data.filteredOptions[0].options);
      }
    } else {
      const options = list.current.filter((item: string) =>
        item.toLowerCase().includes(searchTerms.toLowerCase() ?? '')
      );
      setFilteredOptions(options);
    }
  }, [fetchFilteredOptions, searchTerms, serverSide, templateFieldKey, templateId]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearchTerms(unfinishedSearchTerm);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [setSearchTerms, unfinishedSearchTerm]);

  useEffect(() => {
    filterOptions();
  }, [filterOptions, searchTerms]);

  const checked = useMemo(() => {
    return selected[templateFieldKey] ?? [];
  }, [selected, templateFieldKey]);

  const handleToggle = useCallback(
    (key: string, value: string) => () => {
      setApplyFilters(false);
      if (selectedTemplateFilters.length > 0) {
        if (key) {
          const filterExist = selectedTemplateFilters.findIndex((filter) => filter.id === key);
          if (filterExist === -1) {
            setSelectedTemplateFilters([...selectedTemplateFilters, { id: key, value: [value] }]);
          } else {
            const filterValue = selectedTemplateFilters[filterExist].value;
            let filters = selectedTemplateFilters[filterExist];
            const newSelectedFilters = selectedTemplateFilters.filter(
              (filterObject) => filterObject !== filters
            );
            const newFilterValue = filterValue.filter((v) => v !== value);

            if (newFilterValue.length === 0) {
              setSelectedTemplateFilters([...newSelectedFilters]);
            } else {
              filters = filterValue.includes(value)
                ? { id: key, value: newFilterValue }
                : { id: key, value: [...filterValue, value] };

              setSelectedTemplateFilters([...newSelectedFilters, filters]);
            }
          }
        }
      } else if (selectedTemplateFilters.length === 0 && key) {
        setSelectedTemplateFilters([{ id: key, value: [value] }]);
      }
    },
    [selectedTemplateFilters, setSelectedTemplateFilters, setApplyFilters]
  );
  const handleSearchTermChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnfinishedSearchTerm(event.target.value);
    },
    [setUnfinishedSearchTerm]
  );

  const renderOptions = useMemo(() => {
    if (loading) {
      return (
        <>
          <Skeleton
            variant="rectangular"
            width="calc(100% - 20px)"
            height={20}
            sx={{ marginTop: '60px', marginLeft: '20px' }}
          />
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="calc(100% - 20px)"
              height={20}
              sx={{ marginTop: '22px', marginLeft: '20px' }}
            />
          ))}
        </>
      );
    }

    if (filteredOptions.length > 0) {
      return filteredOptions.map((value: string) => {
        const labelId = `checkbox-list-label-${name}-${templateFieldKey}`;
        const isChecked = checked.includes(value);
        return (
          <ListItem key={value} disablePadding data-testid="template-field-option" disableGutters>
            <ListItemButton
              role={undefined}
              dense
              onClick={handleToggle(templateFieldKey, value)}
              sx={{ padding: '0px 8px', gap: '8px' }}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Checkbox
                  edge="start"
                  disableRipple
                  checked={isChecked}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          </ListItem>
        );
      });
    }
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 2 }}>
        <Typography>No options found</Typography>
      </Stack>
    );
  }, [checked, filteredOptions, handleToggle, loading, name, templateFieldKey]);

  return (
    <>
      {loading || (
        <Stack sx={{ marginX: 1 }}>
          {(filteredOptions.length > 4 || unfinishedSearchTerm) && (
            <TextField
              data-testid="template-field-search-list"
              label={`Search ${name}`}
              type="search"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={unfinishedSearchTerm}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchTermChanged}
            />
          )}
        </Stack>
      )}
      <List
        sx={{
          width: '100%',
          bgcolor: '#F7F8FC',
          pt: 0,
          overflowY: 'auto',
          maxHeight: '260px',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {loading || <Divider />}
        {renderOptions}
      </List>
    </>
  );
};
