import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Chip,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GenericAccordionProps } from '@/types';
import { accordianRootStyles, containerStyles, indicatorStyles } from './styles';
import { abyssColors } from '@/theme/colors';

export const GenericAccordion = ({
  summary,
  children,
  numericIndicator,
  openByDefault,
  summaryStyles,
}: GenericAccordionProps) => {
  const StyledAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  return (
    <StyledAccordion
      elevation={0}
      sx={{ px: 3, ...accordianRootStyles }}
      defaultExpanded={openByDefault}
    >
      <AccordionSummary
        sx={{ px: 0, py: 1, ...accordianRootStyles }}
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: abyssColors.primary[500], transform: 'rotate(-90deg)', mr: 0.5 }}
          />
        }
      >
        <Stack sx={containerStyles}>
          <Typography sx={{ color: abyssColors.primary[500], ...summaryStyles }}>
            {summary}
          </Typography>
          {numericIndicator && <Chip sx={indicatorStyles} label={numericIndicator} />}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{mb:2}}>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
