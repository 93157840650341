import React, { useMemo, useState } from 'react';
import { GenericModal } from '../../shared/GenericModal';
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { dialogOverlay } from './styles';
import {
  AllStructuresForPlatformsDocument,
  StructureDeploymentLevel,
  StructureStatus,
  StructureTypes,
  useAllUserGroupsForPlatformsQuery,
  useCreateStructureMutation,
} from '@/__generated__/graphql';
import { inviteScrollDesign } from '@/components/UserManagement/styles';

type Props = { open: boolean; onClose: () => void };
type UserGroup = { name: string; value: string };

const defaultStructureInput = {
  name: '',
  userGroupIds: [],
  features: { riskAssessment: [] },
  status: StructureStatus.Analysis,
  type: StructureTypes.Platform,
  deploymentLevel: StructureDeploymentLevel.EsDeployment,
};

export const CreatePlatformDialog = ({ open, onClose }: Props) => {
  const [structureName, setStructureName] = useState<string>('');
  const [selectedGroupsIds, setSelectedGroupsIds] = useState<string[]>([]);

  const { data: userGroupsData, loading: userGroupsLoading } = useAllUserGroupsForPlatformsQuery();
  const [createStructure, { loading }] = useCreateStructureMutation();

  const userGroups = useMemo(
    () => userGroupsData?.allUserGroups?.map(({ name, id }) => ({ name, value: id })) || [],
    [userGroupsData]
  );

  const handleGroupsChange = (newGroups: UserGroup[]) => {
    setSelectedGroupsIds(newGroups.map((group) => group.value));
  };

  const handleStructureName = (updatedName: string) => setStructureName(updatedName);

  const handleClose = () => onClose();

  const handleCreateStructure = async () => {
    await createStructure({
      variables: {
        input: {
          ...defaultStructureInput,
          name: structureName.trim(),
          userGroupIds: selectedGroupsIds,
        },
      },
      refetchQueries: [AllStructuresForPlatformsDocument],
    });
    onClose();
  };

  const disableSubmit =
    loading || userGroupsLoading || !structureName.trim() || selectedGroupsIds.length === 0;

  return (
    <GenericModal
      open={open}
      handleClose={handleClose}
      title="Create Platform"
      crossIconStyle={{ backgroundColor: 'transparent', color: 'primary' }}
      sx={{ ...dialogOverlay, height: 'fit-content' }}
    >
      <DialogContent sx={{ py: 0 }}>
        <TextField
          fullWidth
          variant="standard"
          label="Platform Name"
          value={structureName}
          sx={{ mb: 2 }}
          onChange={(event) => handleStructureName(event.target.value)}
        />
        <Autocomplete
          multiple
          sx={{ mb: 3 }}
          options={userGroups}
          getOptionLabel={(group) => group.name}
          onChange={(_, value) => handleGroupsChange(value)}
          renderInput={(parameters) => (
            <TextField variant="standard" {...parameters} label="Assign User Group" />
          )}
          disableCloseOnSelect
          ListboxProps={{ sx: { maxHeight: 180, overflow: 'auto', ...inviteScrollDesign } }}
          ChipProps={{ variant: 'outlined', sx: { p: 0 } }}
          renderOption={(props, option, { selected }) => (
            <li style={{ padding: 0, minHeight: 15 }} {...props} id={`user-group-${option.value}`}>
              <Checkbox checked={selected} />
              {option.name}
            </li>
          )}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleClose} variant="text" sx={{ mr: 1, height: '36px' }}>
          Cancel
        </Button>
        <Button
          sx={{ height: '36px', width: '150px' }}
          variant="contained"
          onClick={handleCreateStructure}
          disabled={disableSubmit}
          disableElevation
        >
          {loading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </GenericModal>
  );
};
