export const humanizeString = (sentenceOrWord: string | undefined, split = ' '): string => {
  if (!sentenceOrWord) return '';

  // Split sentence into words
  const words = sentenceOrWord.split(split).map((word) => word.toLowerCase());

  if (words.length === 0) return '';

  // Join words by ' ', keeping multiple spaces as they are
  const humanizedString = sentenceOrWord
    .split(split)
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(split);

  return humanizedString;
};

export const enumToString = (enumValue: string): string => {
  return (
    enumValue
      // Lower case everything
      .toLowerCase()
      // Replace underscores with spaces
      .replace(/_/g, ' ')
      // Capitalize first letter of each word
      .replace(/\b[a-z]/g, (letter) => letter.toUpperCase())
  );
};

export const generateUUIDv4 = () => {

  const crypto = window.crypto;
  const buffer = new Uint8Array(16);
  crypto.getRandomValues(buffer);

  // Per RFC 4122, UUID version 4 has specific bits set
  buffer[6] = (buffer[6] & 0x0f) | 0x40; // Version 4
  buffer[8] = (buffer[8] & 0x3f) | 0x80; // Variant 10

  // Convert buffer to UUID string format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
  return [...buffer]
    .map((byte, index) => {
      // Insert dashes at appropriate positions
      const segmentDivider = [4, 6, 8, 10];
      const byteString = byte.toString(16).padStart(2, '0');
      return segmentDivider.includes(index) ? `-${byteString}` : byteString;
    })
    .join('');
};
