import { useRecoilValue, useSetRecoilState } from 'recoil';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useMemo, useState } from 'react';
import * as state from '@/state';
import { useSetReviewedFlagForPartsMutation } from '@/__generated__/graphql';
import { StructureRelationships } from '@/types';

export const PartReviewedCheckbox = () => {
  const selectedParts = useRecoilValue(state.selectedPartSummaries);
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const setStructureRelationships = useSetRecoilState(state.structureRelationships);
  const [isLoading, setIsLoading] = useState(false);
  const selectedZone = useRecoilValue(state.selectedZone);
  const structureId = useRecoilValue(state.selectedStructureId);

  useEffect(() => setIsLoading(false), [selectedParts]);

  const [setReviewedFlagForPartsMutation, { error, loading }] =
    useSetReviewedFlagForPartsMutation();

  const handleSetReviewedFlagForPart = () => {
    setIsLoading(true);
    let parts = {
      ids: selectedParts.map((selectedPart) => selectedPart.id),
      isReviewed: selectedParts.some((selectedPart) => !selectedPart.isReviewed),
      structureId: structureId ?? '',
    };

    if (selectedZone?.id) {
      const zone = { zoneId: selectedZone?.id };
      parts = { ...parts, ...zone };
    }
    setReviewedFlagForPartsMutation({
      errorPolicy: 'all',
      variables: {
        parts,
      },
      onCompleted(mutationReturn) {
        const partsUpdated = mutationReturn.setReviewedForParts;

        const isReviewed = selectedParts.every((selectedPart) => selectedPart.isReviewed === false);
        const partDisplayName = partsUpdated.length > 1 ? 'multiple parts' : 'part';
        const message = isReviewed
          ? `Reviewed ${partDisplayName}`
          : `Removed review for ${partDisplayName}`;

        setSnackbarMessage({
          shouldShow: true,
          content: <Alert severity="success">{message}</Alert>,
        });

        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }

          const byPartId = currentRelationships.byPartId.asMutableStateMap();

          partsUpdated.forEach((updatedPart) => {
            const part = byPartId.get(updatedPart);
            if (part) {
              byPartId.set(updatedPart, {
                ...part,
                isReviewed: isReviewed,
              });
            }
          });

          const updatedRelationships: StructureRelationships = {
            ...currentRelationships,
            byPartId: byPartId.asStateMap(),
          };

          return updatedRelationships;
        });
      },
    });
  };

  useEffect(() => {
    if (loading) {
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <div>
            <Alert severity="info">Updating reviewed</Alert>
          </div>
        ),
      });
    } else if (error) {
      setIsLoading(false);
      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="error">Failed to update the part. Please try again later</Alert>,
      });
    }
  }, [error, loading, setSnackbarMessage]);

  const isChecked = useMemo(() => {
    // multiple selected one is reviewed and another is not check box is unticked
    // all are reviewed check box is ticked
    // none are reviewed check box is unticked
    return !selectedParts.some((selectedPart) => !selectedPart.isReviewed);
  }, [selectedParts]);

  const CheckBoxComponent = (
    <Checkbox
      checked={isChecked}
      size="medium"
      color="primary"
      onChange={handleSetReviewedFlagForPart}
      disabled={!selectedParts || isLoading}
    />
  );

  return <FormControlLabel label="Reviewed" control={CheckBoxComponent} />;
};
