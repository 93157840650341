import { IdmsErrorTable } from '@/components/Analysis/Idms/IdmsErrorTable';
import { useRecoilState } from 'recoil';
import * as state from './state';
import { csvStringToJson } from '@/components/Analysis/Idms/utils';
import { TemplateValidationError } from '@/components/Analysis/Idms/types';
import { Modal, Paper, Typography } from '@mui/material';
import { ValidationsErrorDialogStyles } from '@/components/Analysis/modules/pointOfInterest/BulkUpload/components/styles';

export const LineFixingValidationErrorTable = () => {
  const [lineFixingState, setLineFixingState] = useRecoilState(state.lineFixingState);

  const isOpen = lineFixingState.state === 'validation-error';

  const { header, rows } = csvStringToJson(lineFixingState.inputCsv || '');

  const onClose = () => {
    setLineFixingState({
      ...lineFixingState,
      state: 'closed',
      error: undefined,
      inputCsv: undefined,
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper sx={ValidationsErrorDialogStyles}>
        <Typography fontWeight={'bold'} fontSize={20} my={2}>
          The file contains errors
        </Typography>
        <Typography>
          Note: This file cannot be uploaded due to exceptions highlighted in the table below. Hover
          over each highlighted value to view the specific exception.
        </Typography>
        <IdmsErrorTable
          header={header}
          rows={rows}
          errors={lineFixingState.error?.extensions?.error as Array<TemplateValidationError>}
        />
      </Paper>
    </Modal>
  );
};
