import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Box, Card, SxProps, Theme, Typography } from '@mui/material';
import * as state from '@/components/Analysis/state';
import {
  assemblyNameStyles,
  cardContentStyles,
  cardStyles,
  highlightStyles,
  cardSectionsStyles,
} from './styles';
import { useHavePermission, useRollupOnData } from '@/hooks';
import { EquipmentCardType, Permissions } from '@/types';
import { getTemplateFieldData } from '@/utils';
import { GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery } from '@/__generated__/graphql';
import { EquipmentCardTemplateRenderer } from './EquipmentCardTemplateRenderer';
import { EquipmentCardPositionEnum } from '@/__generated__/graphql';

type AssemblyProps =
  GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery['getAssembliesByTemplateFieldsFilters'][0];

type EquipmentCardProps = {
  assembly: AssemblyProps;
  handleClick: (assemblyId: string) => void;
  sx?: SxProps<Theme>;
  equipmentCardTemplate?: EquipmentCardType;
};

export const EquipmentCard = ({ sx, assembly, handleClick }: EquipmentCardProps) => {
  const setHoveredAssemblyId = useSetRecoilState(state.hoveredAssemblyId);
  const selectedAssemblyInfo = useRecoilValue(state.selectedAssemblyInfo);
  const selectedTemplate = useRecoilValue(state.assemblyTemplate);

  const canReadDefects = useHavePermission(Permissions.READ_DEFECTS);

  const isSelectedAssembly = useMemo(
    () => selectedAssemblyInfo === assembly.id,
    [selectedAssemblyInfo, assembly.id]
  );

  const handleRowClicked = () => {
    if (assembly.isUncontextualized) return;
    handleClick(assembly.id);
  };

  const handleRowEnter = useCallback(() => {
    setHoveredAssemblyId(assembly.id);
  }, [assembly.id, setHoveredAssemblyId]);

  const handleRowLeave = useCallback(() => {
    setHoveredAssemblyId(undefined);
  }, [setHoveredAssemblyId]);
  const fieldsData = getTemplateFieldData(
    selectedTemplate?.equipmentCard || [],
    assembly.templateFieldData
  );
  const dataForPosition = useRollupOnData(fieldsData, 'position');

  const subtitle = (
    <EquipmentCardTemplateRenderer data={dataForPosition[EquipmentCardPositionEnum.Subtitle]} />
  );

  const topMiddle = (
    <EquipmentCardTemplateRenderer data={dataForPosition[EquipmentCardPositionEnum.TopMiddle]} />
  );
  const bottomMiddle = (
    <EquipmentCardTemplateRenderer data={dataForPosition[EquipmentCardPositionEnum.BottomMiddle]} />
  );
  const topRight = (
    <EquipmentCardTemplateRenderer data={dataForPosition[EquipmentCardPositionEnum.TopRight]} />
  );
  const bottomRight = (
    <EquipmentCardTemplateRenderer data={dataForPosition[EquipmentCardPositionEnum.BottomRight]} />
  );

  return (
    <Card
      key={assembly.id}
      onClick={handleRowClicked}
      onMouseEnter={handleRowEnter}
      onMouseLeave={handleRowLeave}
      sx={{
        ...(isSelectedAssembly ? highlightStyles : cardStyles),
        ...sx,
      }}
    >
      <Box sx={cardContentStyles}>
        <Box sx={{ ...cardSectionsStyles, alignItems: 'flex-start', flex: '3' }}>
          <Typography sx={assemblyNameStyles}>{assembly?.tagName}</Typography>
          {subtitle}{' '}
          {assembly.isUncontextualized ? <Typography>(Uncontextualized)</Typography> : ''}
        </Box>
        {canReadDefects && (
          <Box sx={{ ...cardSectionsStyles, alignItems: 'center', flex: '1' }}>
            {topMiddle}
            {bottomMiddle}
          </Box>
        )}
        {canReadDefects && (
          <Box sx={{ ...cardSectionsStyles, flex: '2', alignItems: 'flex-end' }}>
            {topRight}
            {bottomRight}
          </Box>
        )}
      </Box>
    </Card>
  );
};
