/* eslint-disable unicorn/no-null */
import { Button, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { NavbarLinks, menuListStyles } from './styles';
import { useRouter } from 'next/router';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FeatureBase } from '@/types';

type Props = {
  label?: string;
  handleItemClick: (route: string) => void;
  items: FeatureBase[];
};

export const NavbarMenu = ({ label, handleItemClick, items = [] }: Props) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const router = useRouter();
  const open = Boolean(anchorElement);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div>
      <Button
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={NavbarLinks}
        style={{
          borderColor: items.some((item) => router.route.includes(item?.url ?? ''))
            ? '#fff'
            : 'transparent',
        }}
      >
        {label}
        <KeyboardArrowDown sx={{ fontSize: '1.8rem', marginBottom: '0.4rem' }} />
      </Button>

      <Menu anchorEl={anchorElement} open={open} onClose={handleClose} sx={menuListStyles}>
        {items
          .filter((item) => item.enabled)
          .map((item) => (
            <MenuItem
              key={item?.label}
              disabled={item?.disabled}
              onClick={() => {
                handleItemClick(item?.url ?? '');
              }}
            >
              {item?.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
