/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable unicorn/no-null */
import { Button, Menu, MenuItem, SxProps, Typography } from '@mui/material';
import { useRef } from 'react';
type Props = {
  sx?: SxProps;
};
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { theme } from '@/theme/theme';
import {
  useBulkLineFixingMutation,
  useRevertBulkLineFixingMutation,
} from '../PartsLabelReportButton';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { snackbarMessage } from '@/state';
import { RevertConfirmDialog } from './RevertConfirmDialog';
import { FixedAssembliesTable } from './FixedAssembliesTable';
import { GraphQLError } from 'graphql';
import { CSV_VALIDATION_ERROR_CODE, SUPPORT_ERROR_MESSAGE } from '@/constants';
import { LineFixingErrorDialog } from './LineFixingErrorDialog';
import { useRecoilState } from 'recoil';
import * as state from './state';
import { LineFixingValidationErrorTable } from './LineFixingValidationErrorTable';

export const LineFixingMenu = ({ sx }: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [lineFixingState, setLineFixingState] = useRecoilState(state.lineFixingState);

  const isMenuOpen = Boolean(lineFixingState.menuAnchorElement);
  const [mutate, { loading: lineFixingLoading }] = useBulkLineFixingMutation();
  const [mutateRevert, { loading: revertLoading }] = useRevertBulkLineFixingMutation();
  const structureId = useGetStructureId();
  const { showSnackBar } = useSnackBarMessage({ atom: snackbarMessage });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLineFixingState({
      ...lineFixingState,
      menuAnchorElement: event.currentTarget,
      state: 'closed',
    });
  };

  const handleCloseMenu = () => {
    setLineFixingState({
      ...lineFixingState,
      menuAnchorElement: undefined,
      error: undefined,
    });
  };

  const handleRevert = () => {
    mutateRevert({
      variables: {
        input: { structureId },
      },
    })
      .then((data) => {
        const count = data.data?.revertBulkLineFixing?.updatedCount;

        if (!count || count === 0) {
          showSnackBar('No lines were reverted.', 'info');
          return;
        }

        const message = `${count} line(s) have been reverted. Please refresh the page to see the changes.`;
        if (message) {
          showSnackBar(message, 'success');
        }
      })
      .catch((error_) => {
        showSnackBar(error_.message, 'error');
      })
      .finally(() => {
        setLineFixingState({
          ...lineFixingState,
          state: 'closed',
        });
      });
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const fileData = reader.result;
      mutate({
        variables: {
          input: {
            csv: fileData as string,
            structureId,
          },
        },
      })
        .then((data) => {
          const updateCount = data.data?.bulkLineFixing?.updatedCount;

          if (!updateCount || updateCount === 0) {
            showSnackBar('No lines were fixed. Please check the file and try again.', 'error');
            return;
          }

          showSnackBar(
            `${updateCount} line(s) have been fixed. Please refresh the page to see the changes.`,
            'success'
          );
        })
        .catch((error_) => {
          const gqlError: GraphQLError | undefined = error_?.graphQLErrors?.[0];

          if (!gqlError) {
            showSnackBar(SUPPORT_ERROR_MESSAGE, 'error');
            handleCloseMenu();
            return;
          }

          if (gqlError.extensions?.code === CSV_VALIDATION_ERROR_CODE) {
            setLineFixingState({
              ...lineFixingState,
              inputCsv: fileData as string,
              error: gqlError,
              state:
                gqlError.extensions?.type === 'validation'
                  ? 'validation-error'
                  : 'generic-csv-error',
            });
            return;
          }

          showSnackBar(error_.message || SUPPORT_ERROR_MESSAGE, 'error');
          handleCloseMenu();
        })
        .finally(() => {
          // clear file input
          fileRef.current!.value = '';
        });
    });
    reader.readAsText(file);
  };

  const MENU_ITEMS = [
    {
      title: 'Upload new file',
      description: 'Upload a new file for line fixing.',
      onClick: () => {
        fileRef.current?.click();
        handleCloseMenu();
      },
    },
    {
      title: 'View uploaded file',
      description: 'Click to view the uploaded file in Abyss Fabric.',
      onClick: () => {
        setLineFixingState({
          ...lineFixingState,
          state: 'table-modal-open',
        });
      },
    },
    {
      title: 'Revert changes',
      description: 'Click to revert any recent changes.',
      onClick: () => {
        setLineFixingState({
          ...lineFixingState,
          state: 'confirm-revert',
        });
      },
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleClick}
        sx={sx}
        endIcon={<ArrowDropDownIcon />}
        disabled={lineFixingLoading}
      >
        {lineFixingLoading ? 'Uploading' : 'Line Fixing File'}
      </Button>
      <Menu
        id="line-fixing-menu"
        anchorEl={lineFixingState.menuAnchorElement}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        sx={{
          mt: 2,
        }}
      >
        {MENU_ITEMS.map((item, index) => (
          <MenuItem
            key={index}
            onClick={item.onClick}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              my: 1,
            }}
          >
            <Typography color={theme.palette.primary.main}>{item.title}</Typography>
            <Typography color={theme.palette.secondary.dark}>{item.description}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <input
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        onChange={onFileChange}
        accept=".csv"
      />
      <RevertConfirmDialog onConfirm={handleRevert} disabled={revertLoading} />
      <FixedAssembliesTable />
      <LineFixingErrorDialog />
      <LineFixingValidationErrorTable />
    </>
  );
};
