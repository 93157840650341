import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Accordion,
  Card,
  styled as muiStyled,
} from '@mui/material';
import styled from 'styled-components';

export const MainContainer = styled(Box)({
  width: '100%',
  padding: '45px 5%',
  height: 'calc(100vh - 100px)',
  overflowY: 'auto',
});

export const StyledDialogActions = styled(DialogActions)({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  right: 0,
  padding: '16px !important',
  paddingRight: '25px !important',
  borderTop: '1px solid #0000001f',
});

export const StyledDialogContent = styled(DialogContent)({
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const SuccessActionButton = muiStyled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  color: 'white',
  height: '36px',
}));

export const dialogOverlay = {
  top: '15% !important',
  transform: 'none',
};

export const StyledBox = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: 1,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '15px',
  padding: '20px 20px',
  height: 350,
  '&:hover': {
    backgroundColor: '#f0f0f0',
    transition: 'background-color 0.3s',
  },
}));

export const StyledBoxOverlay = muiStyled(Box)({
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
});

export const AccordionContainer = muiStyled(Accordion)({
  marginTop: '18px',
  boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
});

export const CardContainer = muiStyled(Card)({
  marginBottom: '1rem',
  boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
});
