import { SxProps, Typography, styled } from '@mui/material';

export const LinkTypography = styled(Typography)({
  display: 'inline',
  color: 'blue',
  cursor: 'pointer',
  textDecoration: 'underline',
});

export const ValidationsErrorDialogStyles: SxProps = {
  minHeight: '15%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: 3,
};
