import { SxProps } from '@mui/material';

export const accordianRootStyles: SxProps = {
  margin: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
};
export const containerStyles: SxProps = { display: 'flex', alignItems: 'center', paddingLeft: 0 };
export const indicatorStyles: SxProps = {
  marginLeft: '5%',
  backgroundColor: '#DDDDDD',
  color: 'white',
};
