import { defineAbility } from '@casl/ability';
import { useEffect, useState } from 'react';
import { unpackRules } from '@casl/ability/extra';
import { useUser } from '@auth0/nextjs-auth0';
import { useGetUserAbilitiesQuery } from '@/__generated__/graphql';
import { Abilities } from '@/types';

const useAbility = () => {
  const [ability, setAbility] = useState(defineAbility(() => {}));
  const [loading, setLoading] = useState(true);
  const { data: userAbilities } = useGetUserAbilitiesQuery({fetchPolicy: 'cache-first'});
  const { user } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      const data = userAbilities?.getUserAbilities;
      if (data) {
        const unpackedRules: Abilities = unpackRules(JSON.parse(data));
        if (user) {
          const permissions = user['http://abyssfabric.co/user/permissions'] as Array<string>;
          permissions?.forEach((permission) => {
            const [action, subject] = permission.split(':');
            unpackedRules.push({ action: [action], subject: [subject] });
          });
        }

        setAbility(defineAbility(() => {}).update(unpackedRules));
        setLoading(false);
      }
    };

    fetchData();
  }, [userAbilities?.getUserAbilities, user]);

  return { ability, loading };
};

export default useAbility;
