import { Stack, Typography } from '@mui/material';
import { abyssColors } from '@/theme/colors';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { NotificationImportant } from '@mui/icons-material';
import { getDamageClassColor } from '../../../RiskAssessment/shared/styles';
import { Assembly } from '@/__generated__/graphql';

export const RiskEquipmentCard = ({
  handleClick,
  assembly,
}: {
  handleClick: () => void;
  assembly: Partial<Assembly>;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      onClick={handleClick}
      sx={{
        padding: '1.2rem 1.6rem',
        backgroundColor: '#EEF3F8',
        cursor: 'pointer',
      }}
    >
      <Stack gap="1.2rem">
        <Typography fontSize="1.4rem" lineHeight="normal" color={abyssColors.primary[500]}>
          {assembly.tagName}
        </Typography>
        <Typography fontSize="1.2rem" lineHeight="normal" color={abyssColors.primary[300]}>
          {assembly.templateFieldData?.risk_component_type ?? NOT_APPLICABLE_LABEL}
        </Typography>
      </Stack>
      <Stack alignItems="flex-end" gap="1.2rem" justifyContent="space-between">
        <Stack direction="row" gap="0.6rem" alignItems="center" width="fit-content">
          <NotificationImportant
            sx={{
              fontSize: '1.8rem',
              color: getDamageClassColor(assembly.templateFieldData?.risk_damage_class),
            }}
          />
          <Typography
            fontSize="1.4rem"
            lineHeight="normal"
            fontWeight={500}
            color={abyssColors.primary[500]}
          >
            {assembly.templateFieldData?.risk_damage_class ?? NOT_APPLICABLE_LABEL}
          </Typography>
        </Stack>
        <Typography
          fontSize="1.2rem"
          lineHeight="normal"
          textAlign="right"
          noWrap
          color={abyssColors.primary[300]}
        >
          Damage Class
        </Typography>
      </Stack>
    </Stack>
  );
};
