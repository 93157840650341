import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useMemo, useState, lazy, Suspense } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SelectedPoiMenuButton } from './SelectedPoiMenuButton';
import { PoiSuspenseFallback } from './PoiSuspenseFallback';
import { PointOfInterest } from '@/__generated__/graphql';
import { usePointOfInterestQuery } from '@/__generated__/graphql';
import { generatePoiDisplayName } from './utils';
import { abyssColors } from '@/theme/colors';

interface TabPanelProps {
  children?: React.ReactNode;
  id: number;
  selectedTabId: number;
}

const GeneralTabLazy = lazy(() =>
  import('./Tabs').then(({ GeneralTab }) => ({
    default: GeneralTab,
  }))
);
const DetailsTabLazy = lazy(() =>
  import('./Tabs/DetailsTab').then(({ DetailsTab }) => ({
    default: DetailsTab,
  }))
);

const CommentsTabLazy = lazy(() =>
  import('@/components/shared/Comments').then(({ Comments }) => ({
    default: Comments,
  }))
);

function TabContent(props: TabPanelProps) {
  const { children, selectedTabId, id, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTabId !== id}
      id={`simple-tabpanel-${id}`}
      style={{ flex: '1 1 auto' }}
      aria-labelledby={`simple-tab-${id}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  selectedPoiId: string;
  closeHandler: () => void;
  disableEdit?: boolean;
  pointOfInterestDetails?: PointOfInterest;
};

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  p: 1,
  py: 2,
  cursor: 'pointer',
  justifyContent: 'space-between',
};

export const SelectedPoiContent = ({ selectedPoiId, closeHandler, disableEdit }: Props) => {
  const { data: pointOfInterest } = usePointOfInterestQuery({
    variables: {
      pointOfInterestId: selectedPoiId as string,
    },
    skip: !selectedPoiId,
  });

  const selectedPoiInfo = useMemo(() => {
    return (pointOfInterest?.pointOfInterest as PointOfInterest) ?? undefined;
  }, [pointOfInterest?.pointOfInterest]);

  const tabsData = useMemo(
    () => [
      {
        id: 0,
        label: 'Overview',
        component: (
          <Suspense fallback={<PoiSuspenseFallback />}>
            {selectedPoiInfo && <GeneralTabLazy selectedPoiInfo={selectedPoiInfo} />}
          </Suspense>
        ),
      },
      {
        id: 1,
        label: 'Details',
        component: (
          <Suspense fallback={<PoiSuspenseFallback />}>
            {selectedPoiInfo && (
              <DetailsTabLazy disableEdit={disableEdit} selectedPoiInfo={selectedPoiInfo} />
            )}
          </Suspense>
        ),
      },
      {
        id: 2,
        label: 'Comments',
        component: (
          <Suspense fallback={<PoiSuspenseFallback />}>
            <CommentsTabLazy referenceId={selectedPoiId} collectionName="pointOfInterests" />
          </Suspense>
        ),
      },
    ],
    [disableEdit, selectedPoiInfo, selectedPoiId]
  );

  const [selectedTabId, setSelectedTabId] = useState(tabsData[0].id);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabId(newValue);
  };

  if (!selectedPoiInfo) {
    return <PoiSuspenseFallback />;
  }

  const poiName = generatePoiDisplayName({
    name: selectedPoiInfo.name,
    poiId: selectedPoiInfo.poiId,
    poiType: selectedPoiInfo.type,
  });

  return (
    <Stack
      direction="column"
      sx={{ width: '100%', height: '100%', backgroundColor: abyssColors.primary[50] }}
    >
      <Box sx={headerStyles}>
        <Stack direction="row" alignItems="center" onClick={closeHandler}>
          <ArrowBack fontSize="medium" />
          <Typography data-testid="poi-name" fontSize="1.8rem" sx={{ ml: 1 }}>
            {poiName}
          </Typography>
          {selectedPoiInfo.isVerified && <CheckCircleIcon sx={{ color: 'success.main', ml: 1 }} />}
        </Stack>

        <SelectedPoiMenuButton poiId={selectedPoiInfo.id} />
      </Box>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& button': { textTransform: 'none', fontWeight: 400 },
          '& .Mui-selected': { fontWeight: 500 },
        }}
      >
        <Tabs
          value={selectedTabId}
          onChange={handleChange}
          variant="fullWidth"
          sx={{
            '@media (max-width: 1728px)': {
              minHeight: '36px',
              '& button': {
                padding: 0,
                minHeight: '36px',
                minWidth: 0,
              },
            },
          }}
        >
          {tabsData.map((tab) => (
            <Tab label={tab.label} {...a11yProps(tab.id)} />
          ))}
        </Tabs>
      </Box>
      {tabsData.map((tab) => (
        <TabContent key={tab.id} selectedTabId={selectedTabId} id={tab.id}>
          {tab.component}
        </TabContent>
      ))}
    </Stack>
  );
};
