import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Vector3 } from 'three';
import { MeasurementLineStyle } from '@abyss/3d-viewer';
import { MeasurementToolState } from '@/types';
import * as moduleState from '../state';
import { InputAdormentIconTypeEnum } from '@/__generated__/graphql';

const measurementLineStyle: MeasurementLineStyle = {
  color: 0xffffff,
  opacity: 1,
  lineWidth: 12,
};

export const useMeasurementTool = () => {
  const [measurements, setMeasurements] = useRecoilState(moduleState.measurementsToAdd);
  const [measurementDistanceWithNoBoundry, setMeasurementLineDistance] = useRecoilState(
    moduleState.measurementLineDistance
  );
  const [measurementToolState, setMeasurementToolState] = useRecoilState(
    moduleState.measurementToolState
  );

  const isMeasurementMenuVisible = useMemo(() => {
    if (measurementToolState === MeasurementToolState.WAITING_FOR_POINT) {
      return true;
    }
    return false;
  }, [measurementToolState]);

  const measurementDistance = useMemo(() => {
    return measurementDistanceWithNoBoundry.toFixed(2);
  }, [measurementDistanceWithNoBoundry]);

  useEffect(() => {
    if (measurementToolState === MeasurementToolState.IN_ACTIVE) {
      setMeasurements(undefined);
      setMeasurementLineDistance(0);
    }
  }, [measurementToolState, setMeasurementLineDistance, setMeasurements]);

  const measurementToolHandlerForAdormants = useCallback(() => {
    // enable measurement tool
    setMeasurementToolState(MeasurementToolState.WAITING_FOR_POINT);
  }, [setMeasurementToolState]);

  const defaultReplayHandler = useCallback(() => {}, []);

  const inputAdormantHandlerMap = useMemo(() => {
    return {
      [InputAdormentIconTypeEnum.MeasurementTool]: measurementToolHandlerForAdormants,
      [InputAdormentIconTypeEnum.Reload]: defaultReplayHandler,
      '': () => {},
    };
  }, [measurementToolHandlerForAdormants, defaultReplayHandler]);

  const setLineDistance = useCallback(
    (distance: number) => {
      setMeasurementLineDistance(distance);
    },
    [setMeasurementLineDistance]
  );

  const handleMeasurementsOnPointSelect = useCallback(
    (position: number | number[] | undefined) => {
      if (
        position === undefined ||
        !Array.isArray(position) ||
        measurementToolState !== MeasurementToolState.WAITING_FOR_POINT
      ) {
        return;
      }

      // if measurement tool is enabled, calculate position and pass it to abyss3dViewer
      setMeasurements((oldMeasurements) => {
        return {
          styles: oldMeasurements?.styles || measurementLineStyle,
          points: [
            ...(oldMeasurements?.points || []),
            new Vector3(position[0], position[1], position[2]),
          ],
          setLineDistance: oldMeasurements?.setLineDistance || setLineDistance,
        };
      });
    },
    [measurementToolState, setLineDistance, setMeasurements]
  );

  const cancelMeasuring = useCallback(() => {
    // marks measurements as canceled.
    // user wants to restart the measurement.
    setMeasurementToolState(MeasurementToolState.IN_ACTIVE);
  }, [setMeasurementToolState]);

  const doneMeasuring = useCallback(() => {
    // mark measurements set as done.
    // can be used to send measurements to database.
    setMeasurementToolState(MeasurementToolState.DONE);
    setMeasurements(undefined);
  }, [setMeasurementToolState, setMeasurements]);

  return {
    isMeasurementMenuVisible,
    measurementToolState,
    measurementDistance,
    measurements,
    cancelMeasuring,
    doneMeasuring,
    handleMeasurementsOnPointSelect,
    measurementToolHandlerForAdormants,
    inputAdormantHandlerMap,
  };
};
