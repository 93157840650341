/* eslint-disable react/jsx-one-expression-per-line */

import { AllHistoriesQuery } from '@/__generated__/graphql';
import { formatDate, formatName } from '@/utils';

type PartSplitEntryProps = {
  history: AllHistoriesQuery['allHistories'][0];
};

export const PartReviewedEntry = ({ history }: PartSplitEntryProps) => {
  return (
    <p>
      <b>{`Part ${history.document.isReviewed ? 'reviewed by' : 'cancelled review state by'}`}</b>
      <br />
      {formatName(history.createdByName)} - {formatDate(new Date(Number(history.createdAt)))}
    </p>
  );
};
