import dynamic from 'next/dynamic';
import { ScatterData, Layout, ColorScale, PlotMouseEvent } from 'plotly.js';
import { memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Container } from './styles';
import {
  INCHES_TO_METERS_FACTOR,
  METERS_TO_INCHES_FACTOR,
  METERS_TO_MILLIMETERS_FACTOR,
  MILLIMETERS_TO_METERS_FACTOR,
} from '@/utils/unitSystem';
import { UnitSystemEnum } from '@/__generated__/graphql';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';

// https://github.com/plotly/react-plotly.js/issues/273
const Plot = dynamic(() => import('react-plotly.js'), { ssr: false });

// Height must be in meters
export type Point = { x: number; y: number; z: number; height: number };

type Props = {
  points: Point[];
  onSelectPoint?: (point: { x: number; y: number; z: number; height: number }) => void; // Height returned is in meters
};

const Z_ASPECT = 0.5;
const CONFIG = { responsive: true, displaylogo: false };

const COLOR_SCALE_VIRIDIS: ColorScale = [
  [0, 'rgb(68,1,84)'],
  [0.1, 'rgb(71,39,117)'],
  [0.2, 'rgb(62,72,135)'],
  [0.3, 'rgb(49,102,141)'],
  [0.4, 'rgb(38,130,141)'],
  [0.5, 'rgb(36,157,136)'],
  [0.6, 'rgb(55,181,120)'],
  [0.7, 'rgb(109,204,88)'],
  [0.8, 'rgb(176,221,49)'],
  [1, 'rgb(253,231,37)'],
];

const remapColorScale = (
  colorScale: Array<[number, string]>,
  intensityMin: number,
  intensityMax: number,
  fixedRangeMin: number,
  fixedRangeMax: number
) => {
  /*
  Remap input colourScale into 3 zones.
  * variable scale - minimum value in data to fixedRangeMin
  * fixed scale - fixedRangeMin to fixedRangeMax (colours consistently match to input values)
  * variable scale - fixedRangeMax to maximum value in the data
  Colour intensity scale is broken into 3 regions
      |  variable scale  |        fixed scale      |  variable scale |
      |                  |        input            |                 |
      ----------------------------------------------------------------
  intensityMin        fixedRangeMin             fixedRangeMax          intensityMax
                       -2mm                       2mm
  */
  // Ensure the full scale starts at lower of measuremntMin intensityMin
  // i.e. protect against intensityMin being larger than measurementMin
  const fullScaleValueMin = Math.min(intensityMin, fixedRangeMin);
  const fullScaleValueMax = Math.max(intensityMax, fixedRangeMax);

  // full range of values on the color scale
  // used to convert to ratios as used by the chart
  const colourFullRange = fullScaleValueMax - fullScaleValueMin;
  const fixedRangeColorScale = (fixedRangeMax - fixedRangeMin) / colourFullRange;
  const measurementMinRatio = (fixedRangeMin - fullScaleValueMin) / colourFullRange;
  const measurementMaxRatio = (fixedRangeMax - fullScaleValueMin) / colourFullRange;

  const veryLow = 'rgb(0,0,0)';
  const veryHigh = 'rgb(255,0,0)';

  // only allocate variable scales if the input values go beyond the fixed limits
  // 0.0001 is used to make a sharp color change - the color scale needs some increment to work
  const variableScaleLow: Array<[number, string]> =
    measurementMinRatio - 0.0001 > 0
      ? [
          [0, veryLow],
          [measurementMinRatio - 0.0001, veryHigh],
        ]
      : [];
  const variableScaleHigh: Array<[number, string]> =
    measurementMaxRatio + 0.0001 < 1
      ? [
          [measurementMaxRatio + 0.0001, veryHigh],
          [1, veryLow],
        ]
      : [[1, veryLow]];

  const fixedScale: Array<[number, string]> = colorScale.map((mark) => [
    measurementMinRatio + mark[0] * fixedRangeColorScale,
    mark[1],
  ]);
  const colorScaleView: Array<[number, string]> = [
    ...variableScaleLow,
    ...fixedScale,
    ...variableScaleHigh,
  ];

  return {
    colorScaleView,
    fullScaleValueMin,
    fullScaleValueMax,
  };
};

export const extractPointData = (rawData: number[], scale: number): [number[], number, number] => {
  const rawMin = Math.min(...rawData);
  const data = rawData.map((value) => (value - rawMin) * scale);
  const max = Math.max(...data);
  const min = Math.min(...data);

  return [data, max, min];
};

const GraphBase = ({ points, onSelectPoint }: Props) => {
  const unitSystem = useRecoilValue(unitSystemState);
  const unitScaleFromRawToDisplay = useMemo(() => {
    return unitSystem === UnitSystemEnum.Imperial
      ? METERS_TO_INCHES_FACTOR
      : METERS_TO_MILLIMETERS_FACTOR;
  }, [unitSystem]);

  const unitScaleFromDisplayToRaw = useMemo(() => {
    return unitSystem === UnitSystemEnum.Imperial
      ? INCHES_TO_METERS_FACTOR
      : MILLIMETERS_TO_METERS_FACTOR;
  }, [unitSystem]);

  const unitString = useMemo(() => {
    return unitSystem === UnitSystemEnum.Imperial ? '"' : 'mm';
  }, [unitSystem]);

  const handleClick = (event: Readonly<PlotMouseEvent>) => {
    if (onSelectPoint && event.points.length > 0) {
      const pointData = event.points[0];
      if (
        typeof pointData.x === 'number' &&
        typeof pointData.y === 'number' &&
        typeof pointData.z === 'number' &&
        typeof pointData.customdata === 'number'
      ) {
        onSelectPoint({
          x: pointData.x,
          y: pointData.y,
          z: pointData.z,
          height: pointData.customdata * unitScaleFromDisplayToRaw,
        });
      }
    }
  };

  const { layout, data }: { layout: Partial<Layout>; data: Partial<ScatterData> } = useMemo(() => {
    const [xData, xMax] = extractPointData(
      points.map((point) => point.x),
      unitScaleFromRawToDisplay
    );
    const [yData, yMax] = extractPointData(
      points.map((point) => point.y),
      unitScaleFromRawToDisplay
    );
    const [zData, zMax] = extractPointData(
      points.map((point) => point.z),
      unitScaleFromRawToDisplay
    );

    const max = Math.max(xMax, yMax, zMax);
    const heightsData = points.map((point) => point.height * unitScaleFromRawToDisplay);
    const heightMax = Math.max(...heightsData);
    const heightMin = Math.min(...heightsData);

    const fixedRangeMin = -1 * (2 / 1000) * unitScaleFromRawToDisplay;
    const fixedRangeMax = (2 / 1000) * unitScaleFromRawToDisplay;

    const { colorScaleView, fullScaleValueMin, fullScaleValueMax } = remapColorScale(
      COLOR_SCALE_VIRIDIS,
      heightMin,
      heightMax,
      fixedRangeMin,
      fixedRangeMax
    );

    const d: Partial<ScatterData> = {
      x: xData,
      y: yData,
      z: zData,
      customdata: heightsData,
      hovertemplate: `%{customdata:.3f}${unitString}<extra></extra>`,
      mode: 'markers',
      marker: {
        size: 12,
        color: heightsData, // what values the color's based on
        colorbar: {
          lenmode: 'fraction',
          ticksuffix: unitString,
          len: 0.8,
          thicknessmode: 'fraction',
          thickness: 0.08,
          tickfont: { size: 10, family: 'Roboto', color: 'gray' },
        },
        colorscale: colorScaleView,
        cmin: fullScaleValueMin,
        cmax: fullScaleValueMax,
        opacity: 1,
      },
      type: 'scatter3d',
    };

    const l: Partial<Layout> = {
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
      },
      autosize: true,
      scene: {
        aspectmode: 'manual',
        aspectratio: {
          x: 1,
          y: 1,
          z: Z_ASPECT,
        },
        xaxis: {
          nticks: 9,
          range: [0, max],
        },
        yaxis: {
          nticks: 9,
          range: [0, max],
        },
        zaxis: {
          nticks: 10,
          range: [0, max * Z_ASPECT],
        },
      },
    };

    return {
      layout: l,
      data: d,
    };
  }, [points, unitScaleFromRawToDisplay, unitString]);

  return (
    <Container>
      <Plot
        data={[data]}
        layout={layout}
        config={CONFIG}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler
        onClick={handleClick}
      />
    </Container>
  );
};

const arePropsEqual = (previousProps: Props, nextProps: Props): boolean => {
  return (
    previousProps.points.length === nextProps.points.length &&
    previousProps.onSelectPoint === nextProps.onSelectPoint
  );
};

export const Graph = memo(GraphBase, arePropsEqual);
