import { useMemo } from 'react';
import { InputAdormantHandlerMap, PointOfInterestTemplateDocument, PoiFormState } from '@/types';
import { UseTemplateFieldsHookType } from '../hooks/useTemplateFields';
import { TemplateFieldRenderer } from '../shared/TemplateFieldRenderer';
import { TemplateFieldTypeEnum } from '@/__generated__/graphql';
import { localiseBlisterHeight } from '@/utils/unitSystem';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';
import { UNCERTAIN_PROFILE_LABEL } from '@/constants';
import { useRecoilValue } from 'recoil';

type Props = {
  template: PointOfInterestTemplateDocument | undefined;
  dataHook: UseTemplateFieldsHookType;
  formState: PoiFormState | undefined;
  inputAdormantHandlerMap: InputAdormantHandlerMap;
  allDisabled?: boolean;
};

export const TemplateFieldsForm = ({
  template,
  dataHook,
  formState,
  inputAdormantHandlerMap,
  allDisabled,
}: Props) => {
  const { state, handleChange, blister } = dataHook;
  const unitSystem = useRecoilValue(unitSystemState);
  const fields = useMemo(
    () => (template?.fields || []).filter((field) => field.name !== 'Comments'),
    [template?.fields]
  );

  return (
    <>
      {fields.map((field) => (
        <TemplateFieldRenderer
          inputAdormantHandlerMap={inputAdormantHandlerMap}
          key={field.id}
          isEditing
          templateField={field}
          onChange={(value) => handleChange(field.id, value)}
          value={state[field.id]}
          disabled={allDisabled}
        />
      ))}
      {formState === 'Verify' && blister && (
        <>
          <TemplateFieldRenderer
            inputAdormantHandlerMap={inputAdormantHandlerMap}
            key={'verified-max-height'}
            isEditing
            templateField={{
              name: 'Max Height',
              id: 'verified-max-height',
              isReadOnly: false,
              isRequired: false,
              options: [],
              type: TemplateFieldTypeEnum.Input,
              serverSide: false,
            }}
            onChange={(value) => handleChange('verified-max-height', value)}
            value={localiseBlisterHeight(unitSystem, blister?.maxHeight) ?? UNCERTAIN_PROFILE_LABEL}
            disabled={allDisabled}
          />
          <TemplateFieldRenderer
            inputAdormantHandlerMap={inputAdormantHandlerMap}
            key={'verified-thickness'}
            isEditing
            templateField={{
              name: 'Residual Thickness',
              id: 'verified-thickness',
              isReadOnly: false,
              isRequired: false,
              options: [],
              type: TemplateFieldTypeEnum.Input,
              serverSide: false,
            }}
            onChange={(value) => handleChange('verified-thickness', value)}
            value={
              localiseBlisterHeight(unitSystem, blister?.residualThickness?.value) ??
              UNCERTAIN_PROFILE_LABEL
            }
            disabled={allDisabled}
          />
        </>
      )}
    </>
  );
};
