import { TableCell, Tooltip } from '@mui/material';
import { TypedErrorMap } from '../types';
import { getCleanCellValue } from '../utils';

type Props = {
  mapKey: string;
  value: number | string;

  errorMap: TypedErrorMap | undefined;
};

export const ErrorCell = ({ mapKey: key, value, errorMap }: Props) => {
  const cleanValue = getCleanCellValue(value);

  if (errorMap?.[key]) {
    const errorString = errorMap[key].map((error) => error.message).join(', ');
    return (
      <Tooltip key={key} title={errorString} placement="top">
        <TableCell
          sx={{
            color: 'red',
          }}
        >
          {cleanValue}
        </TableCell>
      </Tooltip>
    );
  }

  return <TableCell key={key}>{cleanValue}</TableCell>;
};
