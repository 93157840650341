import Box from '@mui/material/Box';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Skeleton } from '@mui/material';
import { editPointOfInterest } from '@/components/Analysis/modules/pointOfInterest/state';
import { TemplateFieldTypeEnum, useGetAssemblyServiceAndPipeSpecQuery } from '@/__generated__/graphql';
import { AutoPopulatedBoxStyles, AutoPopulatedTypography } from './styles';

export type Props = {
  title?: string;
  type?: TemplateFieldTypeEnum;
  onTextAreaChange?: (value: string) => void;
};

export const AutoPopulatedFields = ({ title, type, onTextAreaChange }: Props) => {
  const { pointOfInterest } = useRecoilValue(editPointOfInterest);
  const assemblyId = pointOfInterest?.assembly.id;
  const { data, loading } = useGetAssemblyServiceAndPipeSpecQuery({
    variables: {
      assemblyId,
    },
  });

  const assemblyAutoPopulatedData = useMemo(() => {
    if (type === TemplateFieldTypeEnum.AutoAssemblyPressureService) {
      return data?.assembly?.pipeSpec?.spec;
    }

    if (type === TemplateFieldTypeEnum.AutoAssemblyService) {
      return data?.assembly?.service?.code;
    }

    return '';
  }, [data?.assembly?.pipeSpec?.spec, data?.assembly?.service?.code, type]);

  useEffect(() => {
    if (assemblyAutoPopulatedData && onTextAreaChange) {
      onTextAreaChange(assemblyAutoPopulatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assemblyAutoPopulatedData]);

  if (loading) {
    return (
      <>
        <Skeleton data-testid="loading-skeleton" variant="text" sx={{ fontSize: '1rem' }} />
      </>
    );
  }

  return (
    <>
      {assemblyAutoPopulatedData && (
        <>
          <Box sx={AutoPopulatedBoxStyles} component="form" noValidate autoComplete="off">
            <AutoPopulatedTypography data-testid="title" fontWeight={600}>
              {title}
            </AutoPopulatedTypography>
            <AutoPopulatedTypography data-testid="spec">
              {assemblyAutoPopulatedData}
            </AutoPopulatedTypography>
          </Box>
        </>
      )}
    </>
  );
};
