import NotificationImportant from '@mui/icons-material/NotificationImportant';
import { Box, CircularProgress, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  EquipmentDetailsTypography,
  HorizontalBar,
  BoxStyles,
  EquipmentRowStyles,
  LineBreak,
  EquipmentDetail,
  DetailLabel,
  DetailValue,
} from './styles';
import { formatEnumForDisplay } from '@/utils';
import { GetAssemblyDetailsQuery, useScCategoryMappingQuery } from '@/__generated__/graphql';
import { EquipmentDetailRenderer } from './EquipmentDetailRenderer';
import { useFeatureFlag, useHavePermission } from '@/hooks';
import { Permissions } from '@/types';
import * as state from '@/components/Analysis/state';
import { localiseAreaMeasurement } from '@/utils/unitSystem';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { usePointOfInterestsCountLazyQuery } from '@/__generated__/graphql';
import { abyssColors } from '@/theme/colors';
import { getDamageClassColor } from '../../../RiskAssessment/shared/styles';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { AlarmLevel } from './AlarmLevel';
import { PipeSpec } from './PipeSpec';
import { Viewpoints } from './AssociatedViewpoints/Viewpoints';
import { Blisters } from './AssociatedBlisters/Blisters';
import { ScCategory } from './ScCategory';

type Props = {
  assembly?: GetAssemblyDetailsQuery['assembly'];
  isLoadingAssemblyDetails?: boolean;
  isUncontextualized?: boolean;
};

const existingKeys = new Set([
  'risk_level_1_component',
  'risk_level_2_component',
  'risk_component_type',
  'risk_material_type',
  'risk_member_id',
  'risk_structure',
  'risk_equipment_tag',
]);

export const EquipmentDetails = ({
  assembly,
  isLoadingAssemblyDetails,
  isUncontextualized,
}: Props) => {
  const assemblyId = useRecoilValue(state.selectedAssemblyId);
  const isEsSheet = useRecoilValue(state.hasRiskAssessment);
  const structureId = useGetStructureId();
  const canReadDefects = useHavePermission(Permissions.READ_DEFECTS);
  const canAccessAitTools = useFeatureFlag(['ait-engineer', 'admin']);

  const { data } = useScCategoryMappingQuery({
    variables: { structureId },
    skip: !structureId,
  });

  const scCategoryMappingExists = useMemo(() => {
    return Object.keys(data?.structure?.scCategoryMapping ?? {}).length > 0;
  }, [data?.structure?.scCategoryMapping]);

  const templateFieldData: {
    [key: string]: string;
  } = assembly?.templateFieldData;
  const area = assembly?.area;

  const damageClass = useMemo(() => {
    return assembly?.templateFieldData?.risk_damage_class ?? '';
  }, [assembly?.templateFieldData?.risk_damage_class]);

  const unitSystem = useRecoilValue(state.unitSystem);

  const areaValue = useMemo(() => {
    return `${localiseAreaMeasurement(unitSystem, area)}`;
  }, [area, unitSystem]);

  const [totalPoiCount, setTotalPoiCount] = useState(0);
  const [totalDefectPoisCount, setTotalDefectPoisCount] = useState(0);

  const [getPOICount, { loading: poiCountLoading }] = usePointOfInterestsCountLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (assemblyId && !isUncontextualized) {
      const fetchPoiCounts = async () => {
        const { data: totalPoiCountData } = await getPOICount({
          variables: {
            input: {
              structureId,
              assemblyIds: [assemblyId],
            },
          },
        });

        const { data: defectTypePoiCountData } = await getPOICount({
          variables: {
            input: {
              structureId,
              assemblyIds: [assemblyId],
              type: 'DEFECT_CLASS',
            },
          },
        });

        setTotalPoiCount(totalPoiCountData?.pointOfInterestsCount?.count ?? 0);
        setTotalDefectPoisCount(defectTypePoiCountData?.pointOfInterestsCount?.count ?? 0);
      };

      fetchPoiCounts();
    }
  }, [assemblyId, getPOICount, isUncontextualized, structureId]);

  const unContextualizedGeneralDetails = useMemo(() => {
    const fieldData = assembly?.templateFieldData || {};
    const fields = [
      { label: 'Level 1 Component', key: 'risk_level_1_component' },
      { label: 'Level 2 Component', key: 'risk_level_2_component' },
      { label: 'Component type', key: 'risk_component_type' },
      { label: 'Structure', key: 'risk_structure' },
      { label: 'Member Id', key: 'risk_member_id' },
      { label: 'Material type', key: 'risk_material_type' },
    ];

    return fields.map(({ label, key }) => ({
      label,
      value: fieldData[key] ?? NOT_APPLICABLE_LABEL,
    }));
  }, [assembly?.templateFieldData]);

  return (
    <Box sx={{ p: 3, pt: 1 }}>
      {isLoadingAssemblyDetails && (
        <Stack spacing={2} sx={{ py: 1 }} data-testid="pois-loader">
          {Array.from({ length: 6 }, (_, index) => (
            <Skeleton key={index} variant="rectangular" height={26} />
          ))}
        </Stack>
      )}

      {!isLoadingAssemblyDetails && !isUncontextualized && (
        <>
          <Box>
            <Typography fontSize="1.8rem">{assembly?.tagName}</Typography>
            {canReadDefects && <>{scCategoryMappingExists ? <ScCategory /> : <AlarmLevel />}</>}
          </Box>
          <Box sx={EquipmentRowStyles}>
            <Typography fontSize="small" fontWeight={600}>
              Area
            </Typography>
            <Typography fontSize="small">{areaValue}</Typography>
          </Box>

          {templateFieldData?.size && (
            <Box sx={EquipmentRowStyles}>
              <Typography fontSize="small" fontWeight={600}>
                Size
              </Typography>
              <Typography fontSize="small">{templateFieldData.size}</Typography>
            </Box>
          )}

          {templateFieldData?.service && (
            <Box sx={EquipmentRowStyles}>
              <Typography fontSize="small" fontWeight={600}>
                Service Class
              </Typography>
              <Typography fontSize="small">{templateFieldData.service}</Typography>
            </Box>
          )}

          <HorizontalBar />

          <Box>
            <Box sx={BoxStyles}>
              {templateFieldData && canReadDefects ? (
                <EquipmentDetailRenderer data={templateFieldData} area={area ?? 0} />
              ) : (
                'No additional information'
              )}
            </Box>
          </Box>

          <Box sx={EquipmentRowStyles}>
            <EquipmentDetailsTypography fontSize="14px" fontWeight={600}>
              POIs of Component
            </EquipmentDetailsTypography>
            {poiCountLoading ? (
              <CircularProgress
                sx={{
                  color: abyssColors.primary[500],
                }}
                size="1.5rem"
              />
            ) : (
              <EquipmentDetailsTypography>{totalPoiCount}</EquipmentDetailsTypography>
            )}
          </Box>
          {!!totalDefectPoisCount && (
            <Box sx={EquipmentRowStyles}>
              <EquipmentDetailsTypography fontWeight={600}>
                Governing defect POIs
              </EquipmentDetailsTypography>
              <EquipmentDetailsTypography>{totalDefectPoisCount}</EquipmentDetailsTypography>
            </Box>
          )}
          {canAccessAitTools && (
            <>
              <PipeSpec />
              <Blisters assemblyId={assemblyId} />
            </>
          )}
          <Viewpoints assemblyId={assemblyId} />
        </>
      )}

      {!isLoadingAssemblyDetails && isUncontextualized && (
        <>
          <Box>
            <Typography fontSize="1.8rem" color={abyssColors.primary[700]} fontWeight={500}>
              {assembly?.tagName}
            </Typography>
            {isEsSheet ? (
              <Stack sx={{ mt: 2, mb: 3 }} alignItems="center" gap="0.8rem" direction="row">
                <NotificationImportant sx={{ color: getDamageClassColor(damageClass) }} />
                <EquipmentDetailsTypography>
                  {damageClass ? damageClass.replace('DC', 'Damage Class ') : NOT_APPLICABLE_LABEL}
                </EquipmentDetailsTypography>
              </Stack>
            ) : (
              canReadDefects && <AlarmLevel />
            )}

            <LineBreak />
            {isEsSheet && (
              <Stack my="3.2rem" gap="1.6rem">
                {unContextualizedGeneralDetails.map((detail, index) => (
                  <EquipmentDetail key={detail.label + index}>
                    <Tooltip title={detail.label}>
                      <DetailLabel>{detail.label}</DetailLabel>
                    </Tooltip>
                    <Tooltip title={detail.value}>
                      <DetailValue>{detail.value}</DetailValue>
                    </Tooltip>
                  </EquipmentDetail>
                ))}
              </Stack>
            )}
            <LineBreak />
            <Stack mt="3.2rem" gap="1.6rem">
              {Object.keys(assembly?.templateFieldData)
                .filter((key) => !existingKeys.has(key))
                .map((key, index) => (
                  <EquipmentDetail key={assembly?.templateFieldData[key] + index}>
                    <Tooltip title={formatEnumForDisplay(key).replace('Risk', '')}>
                      <DetailLabel>{formatEnumForDisplay(key).replace('Risk', '')}</DetailLabel>
                    </Tooltip>
                    <Tooltip title={assembly?.templateFieldData[key] || NOT_APPLICABLE_LABEL}>
                      <DetailValue>
                        {assembly?.templateFieldData[key] || NOT_APPLICABLE_LABEL}
                      </DetailValue>
                    </Tooltip>
                  </EquipmentDetail>
                ))}
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};
