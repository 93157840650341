import React, { useState } from 'react';
import { DetailsAccordion } from '../StyledComponents';
import { ViewpointsTable } from './ViewpointsTable';

type Props = { assemblyId: string | undefined };

export const Viewpoints = ({ assemblyId }: Props) => {
  const [viewPointsCount, setViewPointsCount] = useState<number>(0);
  return (
    <DetailsAccordion title="Associated Viewpoints" subTitle={viewPointsCount || ''}>
      <ViewpointsTable assemblyId={assemblyId} setViewPointsCount={setViewPointsCount} />
    </DetailsAccordion>
  );
};
