import { Alert, Button, ButtonProps, Chip, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ZoneStatusList } from '../ZoneStatusTimeline/ZoneStatusList';
import { ZoneStatus, useUpdateZoneStatusMutation, History } from '@/__generated__/graphql';
import { Container } from './styles';
import { shouldRedirectToTasks, ZoneStatusMapper } from '../common';
import { ZoneStatusChangerOptions } from './ZoneStatusChangerOptions';
import * as state from '@/state';
import { Zone } from '@/types';

const infoButtonProps: ButtonProps = {
  variant: 'contained',
  color: 'info',
};

const noWrapStyles = {
  whiteSpace: 'nowrap',
};

type Props = {
  status?: ZoneStatus;
  histories?: History[];
};

const getZoneStatusUpdateInput = (status: ZoneStatus, zoneId: string) => {
  return {
    variables: {
      input: {
        zoneId,
        status,
      },
    },
  };
};

const ErrorAlert = (
  <div>
    <Alert severity="error">Error updating zone status</Alert>
  </div>
);

const SuccessAlert = (
  <div>
    <Alert severity="success">Zone status updated</Alert>
  </div>
);

export const ZoneStatusChanger = ({ histories }: Props) => {
  const router = useRouter();
  const zoneIdFromUrl = router?.query?.zoneid;

  const [selectedZone, setSelectedZone] = useRecoilState(state.selectedZone);
  const zoneId = zoneIdFromUrl ? zoneIdFromUrl.toString() : selectedZone?.id;

  const inspection = router?.query?.inspection as string;

  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const zoneHistories = useRecoilValue(state.zoneHistories);
  const currentZoneHistory = zoneHistories.find((item) => item.id === zoneId);

  const [mutate] = useUpdateZoneStatusMutation({
    onCompleted: (data) => {
      if (shouldRedirectToTasks(selectedZone?.status, data.updateZoneStatus.currentStatus)) {
        router.push({
          pathname: '/inspection/[inspection]/tasks',
          query: { inspection },
        });
        return;
      }
      setSelectedZone(
        (previous) =>
          ({
            ...previous,
            status: data.updateZoneStatus.currentStatus,
          } as Zone)
      );
    },
    refetchQueries: ['zonesAndHistoriesFor'],
  });

  const updateZoneStatus = useCallback(
    (input: ZoneStatus) => {
      if (!zoneId) {
        setSnackbarMessage({
          shouldShow: true,
          content: ErrorAlert,
        });
        return;
      }

      mutate(getZoneStatusUpdateInput(input, zoneId))
        .then(() => {
          setSnackbarMessage({
            shouldShow: true,
            content: SuccessAlert,
          });
        })
        .catch(() => {
          setSnackbarMessage({
            shouldShow: true,
            content: ErrorAlert,
          });
        });
    },
    [mutate, setSnackbarMessage, zoneId]
  );

  const StartButton = useCallback(() => {
    const handleClick = () => {
      updateZoneStatus(ZoneStatus.InProgress);
    };

    return (
      <Button {...infoButtonProps} onClick={handleClick}>
        Start
      </Button>
    );
  }, [updateZoneStatus]);

  const SendToReviewButton = useCallback(() => {
    const handleClick = () => {
      updateZoneStatus(ZoneStatus.ReviewReady);
    };

    return (
      <Button {...infoButtonProps} sx={noWrapStyles} onClick={handleClick}>
        Send To Review
      </Button>
    );
  }, [updateZoneStatus]);

  const ApproveButton = useCallback(() => {
    const handleClick = () => {
      updateZoneStatus(ZoneStatus.Completed);
    };

    return (
      <Button variant="contained" color="success" onClick={handleClick}>
        Approve
      </Button>
    );
  }, [updateZoneStatus]);

  const RejectButton = useCallback(() => {
    const handleClick = () => {
      updateZoneStatus(ZoneStatus.NotStarted);
    };

    return (
      <Button variant="contained" color="error" onClick={handleClick}>
        Reject
      </Button>
    );
  }, [updateZoneStatus]);

  const StartReviewButton = useCallback(() => {
    const handleClick = () => {
      updateZoneStatus(ZoneStatus.InReview);
    };

    return (
      <Button
        variant="contained"
        color="info"
        onClick={handleClick}
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        Start Review
      </Button>
    );
  }, [updateZoneStatus]);

  const selectButton = (currentStatus: ZoneStatus) => {
    switch (currentStatus) {
      case ZoneStatus.NotStarted:
        return <StartButton />;
      case ZoneStatus.InProgress:
        return <SendToReviewButton />;
      case ZoneStatus.InReview:
        return (
          <Stack direction="row" spacing={1}>
            <ApproveButton />
            <RejectButton />
          </Stack>
        );
      case ZoneStatus.ReviewReady:
        return <StartReviewButton />;
      default:
        return undefined;
    }
  };

  if (!selectedZone || !selectedZone?.status) return <></>;

  const { status } = selectedZone;

  return (
    <Container>
      <Chip label={ZoneStatusMapper[status]} />

      <ZoneStatusList
        status={status}
        histories={histories || currentZoneHistory?.histories || []}
      />
      <div>{selectButton(status)}</div>
      <ZoneStatusChangerOptions />
    </Container>
  );
};
