import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ColorMap } from '@abyss/3d-viewer';
import * as state from '@/state';
import { Colors } from '@/types';
import {
  GetAllAnnotationInLocationQuery,
  useGetAllAnnotationInLocationLazyQuery,
} from '@/__generated__/graphql';
import { usePrevious } from '@/hooks';
import { useSetAnnotationColours } from '../helpers';
import { TRANSPARENT_COLOR } from '@/constants';

/*
  This handles switching to Spherical / ImageViewpoint mode
  and switching between different ImageViewpoint visibility options
  by updating colours based on visibility options
*/
const useInitialiseMode = (
  colorMap: ColorMap | undefined,
  allColors: Colors,
  colorsForAssembly: {
    [assemblyId: string]: number;
  },
  annotationsInLocation: GetAllAnnotationInLocationQuery | undefined
) => {
  const inSphericalView = useRecoilValue(state.inSphericalView);
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const [sphericalPointCloudMode, setSphericalPointCloudState] = useRecoilState(
    state.sphericalPointCloudMode
  );
  const singleAssemblyIdForSelectedParts = useRecoilValue(state.singleAssemblyIdForSelectedParts);
  const { mode: currentAbyssViewerMode } = useRecoilValue(state.setAbyssViewerState);

  const { setAnnotationColourByHiddenOrAssembly, setAnnotationColoursForSelectedPart } =
    useSetAnnotationColours(allColors, colorsForAssembly);

  useEffect(() => {
    /*
      When exiting Spherical / ImageViewpoint mode, the point cloud visibility
      filters are reset. To avoid recoloring all parts in IsolatePart mode, we exit early.
      We don't exit immediately after resetting the point cloud visibility filters
      because the recoloring needs to happen before the hook completes.
    */
    if (currentAbyssViewerMode === 'IsolatePart') return;
    if (!inSphericalView) {
      setSphericalPointCloudState('All');
    }

    if (!colorMap) return;
    const byAnnotationId = structureRelationships?.byAnnotationId;
    if (!byAnnotationId) return;
    if (!annotationsInLocation) return;

    byAnnotationId.forEach((annotation, annotationId) => {
      const { annotation3dReference } = annotation;

      switch (sphericalPointCloudMode) {
        /*
          Not checking each annotation is related or not because
          too much annotation need to be checked which is slow.

          steps here:
          -> assign all annotations to the same transparent color
          -> after looping through all annotations, assign color for each annotation related
          (loop through related annotation lists)

          in such case, not all annotations need to be checked related or not
          but related points would be assigned to a colour twice
          (1st: transparent, 2nd: actual colour)
        */
        case 'Related':
        case 'None':
          colorMap.setColor(annotation3dReference, TRANSPARENT_COLOR);
          break;

        default:
          setAnnotationColourByHiddenOrAssembly(
            annotationId,
            colorMap,
            singleAssemblyIdForSelectedParts
          );
          break;
      }
    });

    /*
      All annotations are assigned to the same transparent color
      So we need to assign color for each annotation related
    */
    if (sphericalPointCloudMode === 'Related') {
      annotationsInLocation?.allAnnotations.forEach(({ id }) => {
        setAnnotationColourByHiddenOrAssembly(id, colorMap, singleAssemblyIdForSelectedParts);
      });
    }
    /*
      All annotations are assigned to a new colour
      Rerender selected part annotations
    */
    setAnnotationColoursForSelectedPart(colorMap);
  }, [
    inSphericalView,
    annotationsInLocation,
    colorMap,
    setAnnotationColourByHiddenOrAssembly,
    setAnnotationColoursForSelectedPart,
    singleAssemblyIdForSelectedParts,
    sphericalPointCloudMode,
    structureRelationships?.byAnnotationId,
    setSphericalPointCloudState,
    currentAbyssViewerMode,
  ]);
};

/*
  This handles selection/deselection of part
  by updating colours for annotations related to selected part
  and reverting colours for annotations related to deselected part
  based on current visibility option
*/
const useAnnotationSelection = (
  colorMap: ColorMap | undefined,
  allColors: Colors,
  colorsForAssembly: {
    [assemblyId: string]: number;
  },
  annotationsInLocation: GetAllAnnotationInLocationQuery | undefined
) => {
  const inSphericalView = useRecoilValue(state.inSphericalView);
  const structureRelationships = useRecoilValue(state.structureRelationships);
  const sphericalPointCloudMode = useRecoilValue(state.sphericalPointCloudMode);
  const annotationIdsForSelectedPartId = useRecoilValue(state.annotationIdsForSelectedPart);
  const previousAnnotationIdsForSelectedPartId = usePrevious(annotationIdsForSelectedPartId);
  const singleAssemblyIdForSelectedParts = useRecoilValue(state.singleAssemblyIdForSelectedParts);

  const { setAnnotationColourByHiddenOrAssembly, setAnnotationColoursForSelectedPart } =
    useSetAnnotationColours(allColors, colorsForAssembly);

  useEffect(() => {
    if (!inSphericalView) return;
    if (!annotationsInLocation) return;
    if (!colorMap) return;
    const byAnnotationId = structureRelationships?.byAnnotationId;
    const byPartId = structureRelationships?.byPartId;
    if (!byAnnotationId) return;

    previousAnnotationIdsForSelectedPartId?.forEach((annotationId) => {
      const annotation = byAnnotationId.get(annotationId);
      if (!annotation) return;
      const { annotation3dReference, partId } = annotation;
      const part = byPartId?.get(partId);
      if (!part) return;
      const { assemblyId } = part;

      /* Logic for spherical point cloud mode === related | none
          when deselecting the part, we will need to check if the part is related to the location or not
          then set its color to normal color (colorsForAssembly/noAssemblyColour) (meaning it is related to the location)
          or TRANSPARENT_COLOR (0xffffff00) (meaning it is not related to the location, and is unable to be selected once deselect)

          Selecting part is @part level, meaning it contains all the annotations of the part
          some annotations in the part may be not related to the location
          when deselecting the part, we will need to check each annotation in the part is related or not
      */
      switch (sphericalPointCloudMode) {
        case 'Related':
          if (
            // TODO: avoid array scan
            annotationsInLocation.allAnnotations.some(
              ({ id: annotationIdInLocation }) => annotationIdInLocation === annotationId
            )
          ) {
            colorMap.setColor(
              annotation3dReference,
              assemblyId ? colorsForAssembly[assemblyId] : allColors.noAssemblyColour
            );
          } else {
            colorMap.setColor(annotation3dReference, TRANSPARENT_COLOR);
          }
          break;

        case 'None':
          colorMap.setColor(annotation3dReference, TRANSPARENT_COLOR);
          break;

        default:
          setAnnotationColourByHiddenOrAssembly(
            annotationId,
            colorMap,
            singleAssemblyIdForSelectedParts
          );
          break;
      }
    });

    setAnnotationColoursForSelectedPart(colorMap);
  }, [
    allColors.noAssemblyColour,
    annotationsInLocation,
    colorMap,
    colorsForAssembly,
    inSphericalView,
    previousAnnotationIdsForSelectedPartId,
    setAnnotationColourByHiddenOrAssembly,
    setAnnotationColoursForSelectedPart,
    singleAssemblyIdForSelectedParts,
    sphericalPointCloudMode,
    structureRelationships?.byAnnotationId,
    structureRelationships?.byPartId,
  ]);
};

export const useImageViewpointEffects = (
  colorMap: ColorMap | undefined,
  allColors: Colors,
  colorsForAssembly: {
    [assemblyId: string]: number;
  }
) => {
  const inSphericalView = useRecoilValue(state.inSphericalView);
  const structureViewpoints = useRecoilValue(state.structureLocations);
  const structureId = useRecoilValue(state.selectedStructureId) || '';
  const selectedImageViewpoint = useRecoilValue(state.selectedSpherical);
  const setSphericalPointCloudState = useSetRecoilState(state.sphericalPointCloudState);

  const [getAllAnnotationInLocation, { loading, error, data: annotationsInLocation }] =
    useGetAllAnnotationInLocationLazyQuery({
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (loading) {
      setSphericalPointCloudState('Loading');
    } else if (error) {
      setSphericalPointCloudState('Error');
    } else {
      setSphericalPointCloudState('Loaded');
    }
  }, [loading, error, annotationsInLocation, setSphericalPointCloudState]);

  useEffect(() => {
    const locationId = structureViewpoints?.find(
      (viewpoint) => viewpoint.name === selectedImageViewpoint?.name
    )?.id;
    if (inSphericalView && locationId) {
      getAllAnnotationInLocation({
        variables: {
          structureId,
          locationId,
        },
      });
    }
  }, [
    getAllAnnotationInLocation,
    inSphericalView,
    selectedImageViewpoint?.name,
    structureId,
    structureViewpoints,
  ]);

  useInitialiseMode(colorMap, allColors, colorsForAssembly, annotationsInLocation);

  useAnnotationSelection(colorMap, allColors, colorsForAssembly, annotationsInLocation);
};
