import { Box, Stack, Typography } from '@mui/material';
import { LinkedPoiRow } from './LinkedPoiRow';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as cuboidState from '../../../../../cuboids/state';
import * as poiState from '../../../../state';
import { AssemblyPoiWithCheckMark } from './types';
import { useEffect } from 'react';
import { findPointsInsideCuboid } from '@abyss/3d-viewer';
import { VOI_POI_TYPES } from '@/constants';

export const LinkedPoiEditor = () => {
  const [cuboidLinkedPois, setCuboidLinkedPois] = useRecoilState(cuboidState.cuboidLinkedPois);
  const currentCuboid = useRecoilValue(cuboidState.currentCuboid);
  const poiFilteredByCheckbox = useRecoilValue(poiState.poiFilteredByCheckbox);

  // Makes sure whenever current cuboid changes, we recalculate the points inside the cuboid
  useEffect(() => {
    if (!currentCuboid) return undefined;
    const poisExcludingVois = poiFilteredByCheckbox?.filter(
      (poi) => !VOI_POI_TYPES.includes(poi.type)
    );
    const pointsInsideCuboidResults = findPointsInsideCuboid(
      {
        position: currentCuboid?.position as [number, number, number],
        rotation: currentCuboid?.rotation as [number, number, number],
        scale: currentCuboid?.scale as [number, number, number],
      },
      poisExcludingVois
        .filter((poi) => poi.centerPoint3d)
        .map((poi) => [
          // incase POI was uploaded via CSV, the it'll have no 3D coordinates
          // hence location will be undefined
          // typescript still doesn't allow passing this array of coordinates
          // even though we've already filtered out the ones that have no centerPoint3d
          // just throwing in these ?. , ?? and 0s to make typescript happy
          poi?.centerPoint3d?.x ?? 0,
          poi?.centerPoint3d?.y ?? 0,
          poi?.centerPoint3d?.z ?? 0,
        ] as [number, number, number])
    );

    const poiWithinCuboidWithCheckbox = pointsInsideCuboidResults.map((poiIndex) => ({
      ...poisExcludingVois[poiIndex],
      checked: true,
    }));
    setCuboidLinkedPois(poiWithinCuboidWithCheckbox as AssemblyPoiWithCheckMark[]);
  }, [currentCuboid, poiFilteredByCheckbox, setCuboidLinkedPois]);

  const onCheck = (id: string) => {
    setCuboidLinkedPois((current) =>
      current?.map((poi) => {
        if (poi.id === id) {
          return {
            ...poi,
            checked: !poi.checked,
          };
        }
        return poi;
      })
    );
  };

  return (
    <Box>
      <Typography fontSize={'1.5rem'}>Linked POIs</Typography>
      {cuboidLinkedPois && cuboidLinkedPois.length > 0 ? (
        <Stack>
          {cuboidLinkedPois.map((poi) => (
            <LinkedPoiRow key={poi.id} poiInfo={poi} onCheck={onCheck} />
          ))}
        </Stack>
      ) : (
        <Typography>No linked POIs</Typography>
      )}
    </Box>
  );
};
