import { primary } from '@/theme/colors';
import { Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { typographyStyles } from './styles';

type Props = { title: string; value: string | number };

export const Detail = ({ title, value }: Props) => {
  return (
    <Stack spacing={1} my={1.5} direction="row" alignItems="center" justifyContent="space-between">
      <Tooltip placement="top" title={title.length > 22 ? title : ''} arrow>
        <Typography
          fontSize="12px"
          color="text.secondary"
          noWrap
          sx={{ ...typographyStyles, textAlign: 'left' }}
        >
          {title}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title={value.toString().length > 22 ? value : ''} arrow>
        <Typography
          fontSize="12px"
          noWrap
          sx={{ ...typographyStyles, color: primary.darkBlue, textAlign: 'right' }}
        >
          {value}
        </Typography>
      </Tooltip>
    </Stack>
  );
};
