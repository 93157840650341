import { DAMAGE_CLASS_COLOR_MAP, abyssColors } from '@/theme/colors';
import {
  Accordion,
  Box,
  Button,
  Card,
  Dialog,
  Stack,
  alpha,
  styled as muiStyled,
} from '@mui/material';
import TextField from '@mui/material/TextField';

export const RiskCommentDialog = muiStyled(Dialog)(() => ({
  background: alpha('#000', 0.85),
  '& .MuiPaper-root': {
    maxWidth: '536px',
    height: '416px',
    borderRadius: 0,
    boxShadow: 'none',
  },
  '& .MuiDialogTitle-root': {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '2rem',
    fontWeight: 600,
    color: abyssColors.primary[500],
  },
  '& .MuiIconButton-root': {
    padding: 0,
  },
  '& .MuiDialogContent-root': {
    padding: '4px 32px 60px 32px',
  },
  '& .MuiInputBase-root': {
    padding: '20px',
    fontSize: '1.6rem',
    color: abyssColors.primary[500],
    fontWeight: 400,
  },
  '& fieldset': {
    borderColor: '#ddd',
  },
  '& .MuiDialogActions-root': {
    height: '62px',
    padding: '16px 32px',
    gap: '24px',
  },
}));

type ButtonProps = {
  contained?: boolean;
  compact?: boolean;
};

export const ActionButton = muiStyled(Button)(({ contained, compact }: ButtonProps) => ({
  padding: compact ? '0.8rem 1.2rem' : '0.8rem 1.8rem',
  height: '3.4rem',
  borderRadius: '0.4rem',
  fontSize: '1.4rem',
  background: contained ? abyssColors.primary[500] : abyssColors.neutral[50],
  color: contained ? abyssColors.neutral[50] : abyssColors.primary[500],
  boxShadow: 'none',
  textTransform: 'none',
  width: 'fit-content',
  lineHeight: 'normal',
  marginLeft: '0px !important',
  gap: '0.6rem',
  '&:disabled': {
    background: contained ? abyssColors.neutral[300] : abyssColors.neutral[50],
    color: contained ? abyssColors.neutral[50] : abyssColors.primary[300],
  },
  '&:hover': {
    background: contained ? abyssColors.primary[400] : abyssColors.neutral[50],
  },
}));

export const DialogActionButton = muiStyled(ActionButton)(() => ({
  fontSize: '1.2rem',
  height: '3rem',
  padding: '0px 30px',
}));

export const SummaryCard = muiStyled(Card)(() => ({
  padding: '16px',
  border: `1px solid ${abyssColors.primary[100]}`,
  borderRadius: '8px',
}));

export const MenuStyles = {
  marginTop: '12px',
  '& .MuiMenu-list': {
    padding: '8px',
    width: '100%',
    minWidth: '256px',
    borderRadius: '4px',
  },
  '& .MuiPopover-paper ': {
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.30)',
  },
};

export const getDamageClassColor = (key: string) => {
  switch (key) {
    case 'DC1':
      return DAMAGE_CLASS_COLOR_MAP.DC1;
    case 'DC2':
      return DAMAGE_CLASS_COLOR_MAP.DC2;
    case 'DC3':
      return DAMAGE_CLASS_COLOR_MAP.DC3;
    case 'DC4':
      return DAMAGE_CLASS_COLOR_MAP.DC4;
    case 'DC5':
      return DAMAGE_CLASS_COLOR_MAP.DC5;
    case 'RC1':
      return DAMAGE_CLASS_COLOR_MAP.RC1;
    case 'RC2':
      return DAMAGE_CLASS_COLOR_MAP.RC2;
    case 'RC3':
      return DAMAGE_CLASS_COLOR_MAP.RC3;
    case 'RC4':
      return DAMAGE_CLASS_COLOR_MAP.RC4;
    default:
      return abyssColors.neutral[100];
  }
};

type BarProps = {
  background: string;
  offset: number;
  width: string;
};

export const Bar = muiStyled(Box)(({ background, offset, width }: BarProps) => ({
  backgroundColor: background,
  height: '12px',
  width: width,
  display: 'inline-block',
  border: '1px solid rgba(0, 0, 0, 0.20)',
  borderRadius: '2px',
  position: 'absolute',
  left: `${offset * 100}%`,
}));

export const ChartAccordian = muiStyled(Accordion)(() => ({
  '&::before': {
    opacity: 0,
  },
  '& .MuiAccordionSummary-root': {
    margin: 0,
    minHeight: 'min-content',
  },
}));

export const SummaryContainer = muiStyled(Stack)(() => ({
  width: '100%',
  height: '100vh',
  overflowY: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

export const RiskTextField = muiStyled(TextField)(() => ({
  '& .MuiInputBase-input': {
    color: abyssColors.primary[500],
  },
  '& .MuiFormLabel-root': {
    color: abyssColors.primary[300],
  },
  '& .MuiInputBase-formControl::before': {
    borderBottom: `1px solid ${abyssColors.primary[100]} !important`,
  },
  '& .MuiInputBase-formControl::after': {
    borderBottom: `1px solid ${abyssColors.primary[100]}`,
  },
}));
