import {
  PointOfInterestTemplateField,
  TemplateDataInput,
} from '@/__generated__/graphql';
import { TemplateDataHookKeys } from './hooks/useTemplateFields';
import { Maybe, PointOfInterest, UnitSystemEnum, ValueAndPercentage } from '@/__generated__/graphql';
import { localiseBlisterHeight } from '@/utils/unitSystem';
import { UNCERTAIN_PROFILE_LABEL } from '@/constants';

export const convertTemplateStateToTemplateData = (
  data: TemplateDataHookKeys
): TemplateDataInput[] => {
  const result: TemplateDataInput[] = Object.entries(data).map(([key, value]) => ({
    id: key,
    value: value || '',
  }));

  return result;
};

const formatLabel = (data: Maybe<ValueAndPercentage> | undefined, unitSystem: UnitSystemEnum) => {
  if (!data) return 'N/A';
  const { value, percentage } = data;
  if (!value && !percentage) return 'N/A';
  const convertedValue = localiseBlisterHeight(unitSystem, value);
  if (!percentage) return `${convertedValue}`;
  return `${convertedValue} (${percentage.toFixed(1)}%)`;
};

export const convertTemplateDataIntoFieldsArray = ({
  selectedPoi,
  unitSystem,
  templateFields,
}: {
  selectedPoi: PointOfInterest | undefined;
  unitSystem: UnitSystemEnum;
  templateFields: PointOfInterestTemplateField[];
}): Array<{
  label: string;
  value: string;
}> => {
  const values = templateFields?.map((field) => {
    const value = selectedPoi?.templateData?.find((td) => td.id === field.id)?.value || 'N/A';

    return {
      value,
      label: field?.name || 'N/A',
    };
  });

  if (selectedPoi?.blister) {
    const { maxHeight, wallLoss, residualCorrosionAllowance, residualThickness } =
      selectedPoi.blister;
    return [
      {
        value: localiseBlisterHeight(unitSystem, maxHeight) || UNCERTAIN_PROFILE_LABEL,
        label: 'Maximum Blister Height',
      },
      { value: formatLabel(wallLoss, unitSystem), label: 'Estimated Wall Loss' },
      {
        value: formatLabel(residualCorrosionAllowance, unitSystem),
        label: 'Residual Corrosion Allowance',
      },
      { value: formatLabel(residualThickness, unitSystem), label: 'Residual Thickness' },
      ...values,
    ];
  }

  return values;
};

export const convertToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      // Ensure the result is a string before resolving
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Result of file reading was not a string.'));
      }
    });
    reader.addEventListener('error', () => reject(reader.error));
  });

export const generatePoiDisplayName = ({
  name,
  poiId,
  poiType,
}: {
  name: Maybe<string> | undefined;
  poiId: Maybe<string> | undefined;
  poiType: string | undefined;
}) => {
  if (!poiId) return name || poiType || 'N/A';

  if (!name && !poiType) return `POI - ${poiId}`;

  return `${name || poiType} - ${poiId}`;
};
