/* eslint-disable unicorn/no-null */
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSetRecoilState } from 'recoil';
import { useDeletePointOfInterestMutation } from '@/__generated__/graphql';
import { ConfirmationDialog } from '@/components/shared/ConfirmationDialog';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import * as state from '@/components/Analysis/state';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';

type Props = {
  poiId: string;
};

export const SelectedPoiMenuButton = ({ poiId }: Props) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const setSelectedPointOfInterestId = useSetRecoilState(poiState.selectedPointOfInterestId);
  const setApplyFilters = useSetRecoilState(state.applyFilters);
  const { showSnackBar } = useSnackBarMessage({});
  const setAllPOI = useSetRecoilState(poiState.structurePois);

  const open = useMemo(() => Boolean(anchorElement), [anchorElement]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateDialogClose = useCallback(() => {
    setIsUpdateDialogOpen((previous) => !previous);
  }, []);

  const [deletePoi] = useDeletePointOfInterestMutation({
    variables: { input: { pointOfInterestId: poiId } },
  });

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const handleDeleteClick = useCallback(() => {
    setIsLoading(true);
    deletePoi()
      .then(() => {
        setAllPOI((previous) => previous.filter((poi) => poi.id !== poiId));
        setApplyFilters(true);
        showSnackBar('POI deleted successfully', 'success');
        setSelectedPointOfInterestId(undefined);
        handleClose();
      })
      .catch(() => {
        showSnackBar('Failed to delete POI. Please contact support', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [deletePoi, handleClose, poiId, setAllPOI, setSelectedPointOfInterestId, showSnackBar, setApplyFilters]);

  return (
    <div>
      <ConfirmationDialog
        loading={{
          state: isLoading,
          text: 'Deleting...',
        }}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        description="Are you sure you want to delete this POI?"
        title="Delete POI"
        handleClose={handleUpdateDialogClose}
        isOpen={isUpdateDialogOpen}
        onConfirm={handleDeleteClick}
        onCancel={() => setIsUpdateDialogOpen(false)}
      />
      <IconButton
        id="selected-poi-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="basic-menu" anchorEl={anchorElement} open={open} onClose={handleClose}>
        <MenuItem disabled>
          <ShareIcon sx={{ mr: 1 }} />
          Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsUpdateDialogOpen(true);
          }}
        >
          <DeleteIcon sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
