import { editPointOfInterest } from '@/components/Analysis/modules/pointOfInterest/state';
import { allPointOfInterestTemplates } from '@/components/Analysis/state';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { PointOfInterestDocument, PointOfInterestTemplateDocument } from '@/types';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AllPointOfInterestTemplate } from '@/types';
import { convertTemplateNameToPoiType } from '@/utils';

export const usePoiFlow = () => {
  const setEditPointOfInterest = useSetRecoilState(editPointOfInterest);
  const structureId = useGetStructureId();
  const [currentTemplate, setCurrentTemplate] = useState<string>('OTHER');
  const [selectedAssemblyId, setSelectedAssemblyId] = useState<string>('');

  const allTemplates = useRecoilValue<AllPointOfInterestTemplate>(allPointOfInterestTemplates);

  const defaultPoi = useMemo(() => {
    return {
      type: 'OTHER',
      name: '',
      assembly: { id: selectedAssemblyId, structure: { id: structureId } },
    };
  }, [selectedAssemblyId, structureId]);

  const changePoiTemplate = useCallback(
    (type: string) => {
      const template = allTemplates.find(
        ({ name }) => name === type
      ) as PointOfInterestTemplateDocument;
      const poiType = convertTemplateNameToPoiType(template?.name);
      setCurrentTemplate(type);
      setEditPointOfInterest((current) => ({
        ...current,
        template,
        pointOfInterest: {
          ...defaultPoi,
          type: poiType,
        } as PointOfInterestDocument,
      }));
    },
    [allTemplates, defaultPoi, setEditPointOfInterest]
  );

  const handleCreatePoi = useCallback(() => {
    setEditPointOfInterest((current) => ({
      ...current,
      state: 'WaitingForDetail',
      formState: 'Create',
      pointOfInterest: defaultPoi as PointOfInterestDocument,
      template: allTemplates.find(
        (template) => template.name === 'Other'
      ) as PointOfInterestTemplateDocument,
      isManuallyCreated: true,
    }));
  }, [allTemplates, defaultPoi, setEditPointOfInterest]);

  const setAssemblyId = (assemblyId: string) => {
    setSelectedAssemblyId(assemblyId);
    setEditPointOfInterest((current) => {
      const pointOfInterest = current?.pointOfInterest as PointOfInterestDocument;
      const assembly = { ...pointOfInterest?.assembly } as PointOfInterestDocument['assembly'];
      assembly.id = assemblyId;
      return {
        ...current,
        pointOfInterest: { ...pointOfInterest, assembly },
      };
    });
  };

  return { currentTemplate, selectedAssemblyId, handleCreatePoi, changePoiTemplate, setAssemblyId };
};
