import { Difference, Warning } from '@mui/icons-material';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { TemplateValidationError } from './types';

export const csvStringToJson = (
  csvString: string
): {
  header: string[];
  rows: Array<Record<string, unknown>>;
} => {
  // remove trailing newline at the end of the file
  if (csvString.endsWith('\n')) {
    csvString = csvString.slice(0, -1);
  }

  const lines = csvString.split('\n');
  const header = lines[0].trim().split(',');
  const rows = lines.slice(1).map((line) => {
    const row = line.split(',');
    return header.reduce(
      (accumulator, key, index) => {
        accumulator[key] = row[index];
        return accumulator;
      },
      {} as Record<string, unknown>
    );
  });
  return { header, rows };
};

export const getCleanCellValue = (value: number | string): string => {
  const stringifiedValue = String(value);
  if (!stringifiedValue || stringifiedValue.trim() === '') return NOT_APPLICABLE_LABEL;
  return stringifiedValue;
};

export const pickErrorIconForCell = ({
  rowError,
}: {
  rowError: TemplateValidationError | undefined;
}) => {
  if (rowError?.errorMap?.CUSTOM_DUP) {
    return <Difference color="warning" sx={{ fontSize: '1.6rem' }} />;
  }

  return <Warning color="error" sx={{ fontSize: '1.6rem' }} />;
};
