import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { Dialog } from '@/components/shared/Dialog';
import { AlarmLevelContainer } from './styles';
import { PipeSpec, useGetNominationCalculationsQuery } from '@/__generated__/graphql';
import * as state from '@/components/Analysis/state';
import { PipeSpecAccordion } from '@/components/shared/PipeSpecAccordion';
import { GoverningValueAccordion } from '@/components/Analysis/Viewer/Panels/GoverningValueAccordion';
import { AcceptNominationButton } from './AcceptNominationButton';
import { useHavePermission } from '@/hooks';
import { Permissions } from '@/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  assemblyId: string;
  assemblyTagName: string;
  blisterId: string;
  pipeSpec?: PipeSpec | null;
};

const dialogAcceptanceButtonStyle = {
  mt: '30px',
  float: 'right',
};

/**
 * Add component description here
 */
export const NominateDialog = ({
  isOpen,
  onClose,
  assemblyId,
  assemblyTagName,
  blisterId,
  pipeSpec,
}: Props) => {
  const { data: calculations, loading: isCalculationsLoading } = useGetNominationCalculationsQuery({
    fetchPolicy: 'network-only', // Using this fetch policy to force apollo to call onCompleted callback on any subsequent calls with the same input per https://github.com/apollographql/apollo-client/issues/9338
    variables: { input: { assemblyId, blisterId } },
  });

  const userCanUpdateAsset = useHavePermission(Permissions.UPDATE_ASSET);

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const [isPipeSpecExpanded, setIsPipeSpecExpanded] = useState(false);
  const [isGoverningValueExpanded, setIsGoverningValueExpanded] = useState(false);

  const handlePipeSpecToggle = () => {
    setIsPipeSpecExpanded((current) => {
      return !current;
    });
  };

  const handleGoverningValueToggle = () => {
    setIsGoverningValueExpanded((current) => {
      return !current;
    });
  };

  const unitSystem = useRecoilValue(state.unitSystem);

  const alarmLevelDisplay = useMemo<string | undefined>(() => {
    if (!calculations?.getNominationCalculations?.alarmLevel) {
      return undefined;
    }

    return calculations.getNominationCalculations.alarmLevel;
  }, [calculations]);

  return (
    <Dialog
      title={`Nominate as governing value for ${assemblyTagName}?`}
      open={isOpen}
      onClose={handleCloseDialog}
      isFixedHeight
    >
      {isCalculationsLoading ? (
        <AlarmLevelContainer>
          <CircularProgress size={24} />
        </AlarmLevelContainer>
      ) : (
        <>
          {alarmLevelDisplay ? (
            <>
              <AlarmLevelContainer>
                <Typography sx={{ fontSize: '3.2rem' }}>{alarmLevelDisplay}</Typography>
                <Typography variant="caption">Auto-generated</Typography>
              </AlarmLevelContainer>
              {calculations &&
              calculations.getNominationCalculations &&
              calculations.getNominationCalculations.governingValue ? (
                <GoverningValueAccordion
                  isExpanded={isGoverningValueExpanded}
                  onChange={handleGoverningValueToggle}
                  governingValue={calculations.getNominationCalculations.governingValue}
                  unitSystem={unitSystem}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <AlarmLevelContainer>
              <div>
                {pipeSpec?.material === 'not specified' && (
                  <div>
                    Material for this blister is <strong>unspecified</strong>
                  </div>
                )}
                Unable to calculate alarm level, please contact support
              </div>
            </AlarmLevelContainer>
          )}
        </>
      )}
      <PipeSpecAccordion
        isExpanded={isPipeSpecExpanded}
        onChange={handlePipeSpecToggle}
        pipeSpec={pipeSpec}
        unitSystem={unitSystem}
      />
      {userCanUpdateAsset && (
        <Box>
          <AcceptNominationButton
            shouldOpenUpdateAlarmDialog={!alarmLevelDisplay}
            closeDialog={handleCloseDialog}
            sx={dialogAcceptanceButtonStyle}
            blisterId={blisterId}
          />
          <Button sx={dialogAcceptanceButtonStyle} onClick={handleCloseDialog}>
            Decline
          </Button>
        </Box>
      )}
    </Dialog>
  );
};
