import { useCallback } from 'react';

const DEFAULT_CUBOID_COLOR = 0xffffaa;
const SELECTED_CUBOID_COLOR = 0xffffff;

type Options = {
  isSelected: boolean;
};
export const useGetAnalysisCuboidColor = () => {
  const getCuboidColor = useCallback((options?: Options) => {
    const { isSelected } = options || {};
    if (isSelected) {
      return SELECTED_CUBOID_COLOR;
    }
    return DEFAULT_CUBOID_COLOR;
  }, []);

  return getCuboidColor;
};
