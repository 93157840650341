import { useRecoilValue } from 'recoil';
import { Box, Checkbox, Divider, FormControlLabel } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTemplateFields, useTriggerPoiEditing } from '../../hooks';
import { TemplateFieldsForm } from '../TemplateFieldsForm';
import * as poiState from '../../../../state';
import { SaveCancelFormButtons } from '../SaveCancelFormButtons';
import { useVerifyPointOfInterestMutation } from '@/__generated__/graphql';
import { PointOfInterestDocument } from '@/__generated__/graphql';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import { saveCancelButtonCommonStyles } from '../../../styles';
import { FieldViewer } from '../../FieldViewer';
import { GenericAccordion } from '@/components/shared/GenericAccordion';
import { convertTemplateDataIntoFieldsArray } from '../../utils';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';
import { localiseBlisterHeight, localiseMillimetreMeasurement } from '@/utils/unitSystem';

export const VerifyTemplateFields = () => {
  const { inputAdormantHandlerMap, cancelMeasuring } = useMeasurementTool();
  const { cancelEditing } = useTriggerPoiEditing();
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const poi = editPointOfInterest?.pointOfInterest;
  const { showSnackBar } = useSnackBarMessage({});
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(!!poi?.isVerified);
  const unitSystem = useRecoilValue(unitSystemState);

  const [mutate] = useVerifyPointOfInterestMutation();

  const template = editPointOfInterest?.template;

  const dataHook = useTemplateFields({
    selectedPoiInfo: poi,
    templateFields: (template?.fields || []).filter((field) => field.name !== 'Comments'),
    fieldType: 'verifiedValue',
  });

  const onCancel = useCallback(() => {
    cancelMeasuring();
    cancelEditing();
  }, [cancelEditing, cancelMeasuring]);

  const verifyPoi = useCallback(() => {
    if (!poi?.id) {
      showSnackBar('Could not select a point of interest', 'error');
      return;
    }
    setLoading(true);

    const verifiedBlisterMaxHeight =
      dataHook.state['verified-max-height'] ??
      localiseBlisterHeight(unitSystem, dataHook.blister?.maxHeight);

    const verifiedThickness =
      dataHook.state['verified-thickness'] ??
      localiseMillimetreMeasurement(unitSystem, dataHook.blister?.residualThickness?.value);
    mutate({
      variables: {
        input: {
          pointOfInterestId: poi?.id,
          verifiedData: Object.entries(dataHook.state)
            .filter(([, value]) => Boolean(value) && value !== 'NA')
            .map(([key, value]) => ({
              id: key,
              verifiedValue: value,
            })),
          verified,
          verifiedMaxHeight: verifiedBlisterMaxHeight,
          verifiedResidualThickness: verifiedThickness,
        },
      },
      refetchQueries: [PointOfInterestDocument],
    })
      .then(() => {
        showSnackBar('Point of interest verified', 'success');
        cancelEditing();
      })
      .catch(() => {
        showSnackBar(
          'Error verifying point of interest. Please contact support if error persists',
          'error'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    cancelEditing,
    dataHook.blister?.maxHeight,
    dataHook.blister?.residualThickness?.value,
    dataHook.state,
    mutate,
    poi?.id,
    showSnackBar,
    unitSystem,
    verified,
  ]);

  const digitalInspectionData = convertTemplateDataIntoFieldsArray({
    selectedPoi: poi,
    templateFields: template?.fields || [],
    unitSystem,
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        verifyPoi();
      }}
    >
      <GenericAccordion
        openByDefault
        summary="Verify Template Fields"
        summaryStyles={{
          fontWeight: 500,
        }}
      >
        <TemplateFieldsForm
          inputAdormantHandlerMap={inputAdormantHandlerMap}
          dataHook={dataHook}
          template={template}
          formState={editPointOfInterest?.formState}
          // User cant input any more values
          // if the verify check is off
          allDisabled={!verified}
        />
      </GenericAccordion>

      <GenericAccordion
        summary="Pre Inspection Data"
        summaryStyles={{
          fontWeight: 500,
        }}
      >
        <FieldViewer fields={digitalInspectionData} />
      </GenericAccordion>
      <Divider />
      <Box
        sx={{
          position: 'absolute',
          bottom: '1rem',
          width: '100%',
          px: 2,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={verified}
              onChange={(event) => {
                const value = !!event.target.checked;
                setVerified(value);
              }}
            />
          }
          label="Mark point of interest as verified"
        />

        <SaveCancelFormButtons
          cancelButtonLabel="Cancel"
          buttonLabel="Save"
          onCancel={onCancel}
          loading={loading}
          sx={{ ...saveCancelButtonCommonStyles.sx, position: 'block' }}
          saveButtonStyles={saveCancelButtonCommonStyles.saveButtonStyles}
          cancelButtonStyles={saveCancelButtonCommonStyles.cancelButtonStyles}
        />
      </Box>
    </form>
  );
};
