import { Box, Stack, Typography, styled as muiStyled } from '@mui/material';
import styled from 'styled-components';
import { abyssColors } from '../../../../../theme/colors';

export const HorizontalBar = styled.div`
  content: '';
  height: 1px;
  background-color: #000;
  min-width: 100%;
  margin: 10px 0 10px 0;
`;

export const EquipmentDetailsTypography = muiStyled(Typography)(() => ({
  fontSize: '1.4rem',
  color: abyssColors.primary[400],
}));

export const BoxStyles = { my: 3 };
export const ViewpointsAccordianStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

export const EquipmentRowStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  my: 1,
};

export const LineBreak = muiStyled(Box)(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: abyssColors.primary[200],
}));

export const EquipmentDetail = muiStyled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '1.6rem',
}));

export const DetailLabel = muiStyled(Typography)(() => ({
  fontSize: '1.2rem',
  color: abyssColors.primary[400],
  fontWeight: 500,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const DetailValue = muiStyled(Typography)(() => ({
  fontSize: '1.4rem',
  color: abyssColors.primary[500],
  fontWeight: 500,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'right',
}));

export const ColorIndicator = muiStyled(Box)(() => ({
  borderRadius: '50%',
  height: '10px',
  width: '10px',
}));
