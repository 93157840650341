import { UserProfile } from '@auth0/nextjs-auth0';
import { Maybe, User } from '@/__generated__/graphql';
import { EXTERNAL_ROLES, NOT_APPLICABLE_LABEL } from '@/constants';
import { UserRoles } from '@/types';

type Props = {
  viewer: UserProfile | undefined;
  creator: Maybe<User> | undefined;
};
export const useCreatedBy = ({ viewer, creator }: Props) => {
  if (!viewer) return NOT_APPLICABLE_LABEL;
  const userRoles = viewer?.['http://abyss.com/user/roles'] as Array<UserRoles>;

  if (!userRoles) return NOT_APPLICABLE_LABEL;

  const roleIds = creator?.roleIds;
  if (!roleIds) return NOT_APPLICABLE_LABEL;

  const viewerIsExternalUser = userRoles.some((role) => EXTERNAL_ROLES.includes(role as UserRoles));

  const creatorIsExternalUser = roleIds
    ?.map((role) => role?.name)
    .some((role) => EXTERNAL_ROLES.includes(role as UserRoles));

  const creatorEmail = creator.email ?? NOT_APPLICABLE_LABEL;

  // external themselves: they can see each others email
  if (viewerIsExternalUser && creatorIsExternalUser) {
    return creatorEmail;
  }
  // external view internal: they should view added by abyss

  if (viewerIsExternalUser && !creatorIsExternalUser) {
    return 'Added by Abyss';
  }

  // internal themselves: they can see each others email
  if (!viewerIsExternalUser && !creatorIsExternalUser) {
    return creatorEmail;
  }

  // internal view external: they should be able to see external emails
  if (!viewerIsExternalUser && creatorIsExternalUser) {
    return creatorEmail;
  }

  return NOT_APPLICABLE_LABEL;
};
