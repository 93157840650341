import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import * as state from '@/components/Analysis/state';
import * as poiState from '../state';
import { SelectedTab } from '@/types';
import { abyssColors } from '@/theme/colors';

type AllEquipmentBackButtonProps = {
  changeTab: (selectedTabIndex: number) => void;
  retainAssemblyView?: boolean;
  customLabel?: string;
};

export const AllEquipmentBackButton = ({
  changeTab,
  customLabel,
  retainAssemblyView = false,
}: AllEquipmentBackButtonProps) => {
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const setHoveredAssemblyId = useSetRecoilState(state.hoveredAssemblyId);
  const setSelectedAssemblyInfo = useSetRecoilState(state.selectedAssemblyInfo);
  const [selectedAssemblyId, setSelectedAssemblyId] = useRecoilState(state.selectedAssemblyId);
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const setSelectedAssemblyName = useSetRecoilState(state.selectedAssemblyName);

  const clickHandler = useCallback(() => {
    if (!retainAssemblyView) {
      setSelectedSpherical(undefined);
      setHoveredAssemblyId(undefined);
    }
    setSelectedAssemblyName(undefined);
    setCameraTarget(undefined);
    cancelPOIAdd(undefined);
    setSelectedAssemblyId(undefined);
    setSelectedAssemblyInfo(undefined);
    changeTab(SelectedTab.COMPONENT);
  }, [
    retainAssemblyView,
    setSelectedAssemblyName,
    setCameraTarget,
    cancelPOIAdd,
    setSelectedAssemblyId,
    setSelectedAssemblyInfo,
    changeTab,
    setSelectedSpherical,
    setHoveredAssemblyId,
  ]);

  if (!selectedAssemblyId) return <></>;

  return (
    <Box
      data-testid="equipment-back-button"
      onClick={clickHandler}
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: '3.2rem',
        mb: '2.4rem',
        mx: '2rem',
        cursor: 'pointer',
      }}
    >
      <ArrowBack sx={{ color: abyssColors.primary[500], fontSize: '1.8rem' }} />
      <Typography
        fontSize="1.4rem"
        lineHeight="normal"
        fontWeight={500}
        sx={{ ml: 1 }}
        color={abyssColors.primary[500]}
      >
        {customLabel || 'All Components'}
      </Typography>
    </Box>
  );
};
