import React, { useCallback, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { paintDiameterSharedState } from './state';
import {
  DisplayConfig,
  Maybe,
} from '@/__generated__/graphql';
import { INCHES_TO_METERS_FACTOR } from '@/utils/unitSystem';
import { useGetAssemblyPipeSpecLazyQuery } from '@/__generated__/graphql';
import * as state from '@/components/Analysis/state';

export type Props = {
  onFieldValueChange?: (value: string) => void;
  title?: string;
  displayConfig?: Maybe<DisplayConfig>;
};

export const ProposedPaintDiameter = ({ title, displayConfig, onFieldValueChange }: Props) => {
  const [paintDiameter, setPaintDiameter] = useRecoilState(paintDiameterSharedState);
  const rows = displayConfig?.textAreaRows || 4;
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);

  const [getAssemblyPipeSpecLazyQuery] = useGetAssemblyPipeSpecLazyQuery();

  const handlePaintDiameter = useCallback(
    (value: string) => {
      setPaintDiameter(value);
      if (onFieldValueChange !== undefined) {
        onFieldValueChange(String(Number(value) * INCHES_TO_METERS_FACTOR));
      }
    },
    [onFieldValueChange, setPaintDiameter]
  );

  useEffect(() => {
    if (selectedAssemblyId) {
      getAssemblyPipeSpecLazyQuery({
        variables: {
          assemblyId: selectedAssemblyId,
        },
      }).then((response) => {
        const size = response.data?.assembly?.pipeSpec?.size;
        if (size) {
          setPaintDiameter(size);
          handlePaintDiameter(size);
        }
      });
    }
  }, [selectedAssemblyId]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          '& .MuiTextField-root': { width: '100%' },
        }}
        component="form"
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={`TextArea-${title}`}
            label={`${title} (in)`}
            multiline={false}
            rows={rows}
            value={paintDiameter}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              handlePaintDiameter(event.target.value)
            }
          />
        </div>
      </Box>
    </>
  );
};
