import { SxProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import { CustomFieldCommonProps, ReadModeProps } from './types';
import { ReadOnlyTemplateField } from './ReadOnlyTemplateField';

type EditModeProps = {
  disabled?: boolean;
  onFieldValueChange?: (value: string, id?: string) => void;
  mode: 'Edit';
  sx?: SxProps;
} & CustomFieldCommonProps;

type Props = EditModeProps | ReadModeProps;

const datePickerStyles = {
  width: '100%',
  '& .MuiInputBase-input': {
    padding: 1,
    paddingLeft: 0,
  },
  my: 2,
};

const EditModeComponent = ({
  disabled = false,
  id,
  title,
  value: initialValue,
  onFieldValueChange,
  sx: styles,
  ...rest
}: EditModeProps) => {
  const [value, setValue] = useState<Date | null | undefined>();

  useEffect(() => {
    const dateValue = typeof initialValue === 'string' ? new Date(initialValue) : initialValue;
    setValue(dateValue);
  }, [initialValue]);

  const handleChange = useCallback(
    (event: unknown) => {
      const date = event as Date;
      if (!(date instanceof Date)) {
        return;
      }
      setValue(date);
      if (onFieldValueChange) {
        onFieldValueChange(String(date), id);
      }
    },
    [id, onFieldValueChange]
  );

  return (
    <DatePicker
      value={value}
      label={title}
      onChange={(event) => handleChange(event)}
      disabled={disabled}
      slotProps={{ textField: { variant: 'standard' } }}
      sx={{ ...datePickerStyles, ...styles }}
      {...rest}
    />
  );
};

export const DatePickerField = ({ ...props }: Props) => {
  switch (props.mode) {
    case 'Edit':
      return <EditModeComponent {...props} />;
    case 'ReadOnly':
      return <ReadOnlyTemplateField {...props} />;
    default:
      return <></>;
  }
};
