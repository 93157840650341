import { useRecoilState, useSetRecoilState } from 'recoil';
import * as state from '../state/atoms';
import {
  AnalysisCuboid,
  CuboidClickMode,
  CuboidTransformAction,
  PointOfInterestWithCuboids,
} from '@/types';
import { CUBOID_CONTROL_MODES, VOI_POI_TYPES } from '@/constants';
import * as poiState from '../../pointOfInterest/state';
import { StateMap } from '@/utils/stateMap';
import { PointOfInterest } from '@/__generated__/graphql';
import { PoiCuboid } from '@/__generated__/graphql';

export const useAnalysisCuboidActions = () => {
  const setCuboidClickMode = useSetRecoilState(state.cuboidClickMode);
  const [currentCuboid, setCurrentCuboid] = useRecoilState(state.currentCuboid);
  const setIsCuboidEditorEnabled = useSetRecoilState(state.isCuboidEditorEnabled);
  const setCuboidTransformMode = useSetRecoilState(state.cuboidTransformMode);
  const setCuboidControlsType = useSetRecoilState(state.cuboidControlsType);
  const cancelPOIAdd = useSetRecoilState(poiState.cancelPOIAdd);
  const setCuboids = useSetRecoilState(state.cuboids);
  const [editingCuboids, setEditingCuboids] = useRecoilState(state.editingCuboids);

  const startAddingCuboid = () => {
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(true);
    setCuboidClickMode(CuboidClickMode.AddCuboid);
  }

  const stopAddingCuboid = () => {
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(false);
    setCuboidClickMode(CuboidClickMode.Default);
    setEditingCuboids(new StateMap<string, AnalysisCuboid>());
  }

  const toggleTransformMode = (action: CuboidTransformAction) => {
    const [controlsType, transformMode] = CUBOID_CONTROL_MODES[action];
    setCuboidControlsType(controlsType);
    if (transformMode !== undefined) {
      setCuboidTransformMode(transformMode);
    }
  };

  const cancelAddingCuboid = () => {
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(false);
    setEditingCuboids(new StateMap<string, AnalysisCuboid>());

    // clearing out the poi editing state
    cancelPOIAdd(undefined);
  };

  const exitEditing = () => {
    setIsCuboidEditorEnabled(false);
  };

  const removeCurrentCuboidFromCuboids = () => {
    if (!currentCuboid) return;

    setCuboids((current) => {
      const copy = current.asMutableStateMap();
      copy?.delete(currentCuboid.id);
      return copy.asStateMap();
    });
  };

  const initializeCuboids = (allPois: PointOfInterestWithCuboids[]) => {
    const vois = allPois.filter((poi) => VOI_POI_TYPES.includes(poi.type));
    const voidsCuboidsFlatMap = vois
      .flatMap((poi) =>
        poi.cuboids?.map((cuboid) => ({
          ...cuboid,
          poiId: poi.id,
        }))
      )
      .filter((cuboid) => !!cuboid);
    const voiMap = new StateMap<string, AnalysisCuboid>().asMutableStateMap();

    voidsCuboidsFlatMap.map((voi) => {
      if (!voi) return;

      voiMap.set(voi.id, {
        id: voi.id,
        position: voi.position as [number, number, number],
        rotation: voi.rotation as [number, number, number],
        scale: voi.scale as [number, number, number],
        poiId: voi?.poiId,
      });
    });

    setCuboids(voiMap.asStateMap());
  };

  const doneAddingCuboid = () => {
    setIsCuboidEditorEnabled(false);
  };

  const setEditingCuboidAsCurrent = (id: string) => {
    const cuboid = editingCuboids.get(id);
    if (!cuboid) return;

    setCurrentCuboid(cuboid);
    setIsCuboidEditorEnabled(true);
  };

  const shiftAllEditingCuboidsToCuboids = () => {
    setCuboids(editingCuboids);
    setEditingCuboids(new StateMap<string, AnalysisCuboid>());
  };

  const startEditingPoiCuboids = (poi: PointOfInterest | undefined) => {
    if (!poi) return;
    if (!poi?.cuboids) return;
    const poiCuboidIds = poi.cuboids.map((cuboid) => cuboid.id);
    const poiCuboids = poi?.cuboids as PoiCuboid[];

    setEditingCuboids((current) => {
      const copy = current.asMutableStateMap();
      copy.setMany(
        poiCuboids.map((cuboid) => [
          cuboid.id,
          {
            id: cuboid.id,
            position: cuboid.position as [number, number, number],
            rotation: cuboid.rotation as [number, number, number],
            scale: cuboid.scale as [number, number, number],
          },
        ])
      );

      return copy.asStateMap();
    });

    // remove these ids from cuboids
    setCuboids((current) => {
      const copy = current.asMutableStateMap();
      copy.deleteMany(poiCuboidIds);
      return copy.asStateMap();
    });
  };

  const stopEditingPoiCuboids = (poi: PointOfInterest | undefined) => {
    if (!poi) return;
    if (!poi?.cuboids) return;
    const poiCuboids = poi?.cuboids as PoiCuboid[];

    setCuboids((current) => {
      const copy = current.asMutableStateMap();
      copy.setMany(
        poiCuboids.map((cuboid) => [
          cuboid.id,
          {
            id: cuboid.id,
            position: cuboid.position as [number, number, number],
            rotation: cuboid.rotation as [number, number, number],
            scale: cuboid.scale as [number, number, number],
          },
        ])
      );

      return copy.asStateMap();
    });

    setEditingCuboids(new StateMap<string, AnalysisCuboid>());
    setCurrentCuboid(undefined);
    setIsCuboidEditorEnabled(false);
  };

  const doneEditingPoiCuboids = () => {
    setIsCuboidEditorEnabled(false);
    setCurrentCuboid(undefined);
    setEditingCuboids(new StateMap<string, AnalysisCuboid>());
  };

  const getSelectedCuboid = () => {
    return currentCuboid;
  }

  return {
    stopAddingCuboid,
    toggleTransformMode,
    cancelAddingCuboid,
    exitEditing,
    removeCurrentCuboidFromCuboids,
    initializeCuboids,
    doneAddingCuboid,
    setEditingCuboidAsCurrent,
    shiftAllEditingCuboidsToCuboids,
    startEditingPoiCuboids,
    stopEditingPoiCuboids,
    doneEditingPoiCuboids,
    startAddingCuboid,
    getSelectedCuboid
  };
};
