import { useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { AssemblyId, AssemblyInfo } from '@/types';
import { StructureStatus } from '@/__generated__/graphql';
import { structureRelationships, structures } from './atoms';

export const useAssemblyInfoFromAssemblyId = (assemblyId: AssemblyId) => {
  const [assemblyInfo, setAssemblyInfo] = useState<AssemblyInfo>();

  const getAssemblyInfo = useRecoilCallback(
    ({ snapshot }) =>
      async (assemblyIdArgument: AssemblyId) => {
        const structureRelationshipsValue = await snapshot.getPromise(structureRelationships);
        return structureRelationshipsValue?.byAssemblyId.get(assemblyIdArgument);
      },
    []
  );

  useEffect(() => {
    getAssemblyInfo(assemblyId).then((r) => {
      setAssemblyInfo(r);
    });
  }, [assemblyId]);

  return assemblyInfo;
};

export const useStructureStatusFromId = (inspectionId: string) => {
  const [structureStatus, setStructureStatus] = useState<StructureStatus>();

  const getStructureStatus = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const structuresQueryResult = await snapshot.getPromise(structures);
        return structuresQueryResult.find((structure) => structure.id === inspectionId)?.status;
      },
    [inspectionId]
  );

  useEffect(() => {
    getStructureStatus().then((r) => {
      setStructureStatus(r);
    });
  }, [inspectionId]);

  return structureStatus;
};
