import { Dialog, Stack } from '@mui/material';
import { ActionButton, DialogHeading, DialogText } from './styles';

export const InitialiseConfirmationDialog = ({
  open,
  sphericalChanged,
  handleClose,
  handleConfirm,
}: {
  open: boolean;
  sphericalChanged: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '52.6rem',
          padding: '2.4rem',
          borderRadius: '0.8rem',
        },
      }}
    >
      <Stack gap="0.8rem">
        <DialogHeading>Blister profile</DialogHeading>
        <DialogText>
          {`This action will create a new blister profile${sphericalChanged ? ', replacing the current spherical' : ''}. To create a new profile for this blister in the future, you’ll need to delete it first.`}
        </DialogText>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Stack direction="row" alignItems="center" gap="1.6rem" mt="1.2rem">
          <ActionButton onClick={handleClose}>CANCEL</ActionButton>
          <ActionButton contained onClick={handleConfirm}>
            CONTINUE
          </ActionButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
