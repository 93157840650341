import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import {
  PointPickResult,
  FloorLevelStyle,
  FloorLevelProps,
  AbyssViewerContextProvider,
  Number3,
} from '@abyss/3d-viewer';
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
// import { makeUniqueId } from '@apollo/client/utilities';
import * as cuboidState from '@/components/Analysis/modules/cuboids/state';
import { useCreateAnalysisCuboid } from '@/analysis-cuboids/hooks';
import { SceneCuboids } from '@/analysis-cuboids/components';

import { TestIds } from '../../../../../cypress/testIds';

import {
  Container,
  PictureInPictureContainer,
  ParentContainer,
  ViewerLoadingWrapper,
  ViewerLoadingSpinnerContainer,
} from './styles';
import { auth0TokenState } from '@/state';

import * as state from '@/components/Analysis/state';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { FLOOR_LEVELS_BBOX_PADDING } from '@/constants';
import { Drawers } from '../Drawers';
import {
  poiState,
  usePointOfInterestMarkers,
  useBlisterSphericalClick,
  useHandlePoiClick,
} from '@/components/Analysis/modules/pointOfInterest';
import {
  usePointCloudMaterials,
  usePointCloudClick,
} from '@/components/Analysis/modules/pointCloud';
import { CuboidClickMode, Permissions } from '@/types';

import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import { useHavePermission } from '@/hooks';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { AbyssViewer } from './AbyssViewer';
import { PageLoader } from '@/components/shared/PageLoader';
import { useBlisterPolygons } from '../hooks/useBlisterPolygons';
import { useImageSets } from '../hooks/useImageSets';
import {
  useAssemblyLazyQuery,
  useGetRecommendationsForAssemblyQuery,
  useUpdatePointOfInterestLocationMutation,
} from '@/__generated__/graphql';
import { useBlisterClickMarkers } from '../hooks/useBlisterClickMarkers';
// import { useFocusOnSelectedAssembly } from '../hooks/useFocusOnSelectedAssembly';
import {
  useFindViewpointsTool,
  useFindViewpointsMarker,
  useFindViewpointsFocusOnPoint,
} from '../../modules/findViewpointsTool/hooks';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import {
  cancelPOIAdd as cancelPOIAddState,
  selectedPointOfInterestId as selectedPointOfInterestIdState,
} from '../../modules/pointOfInterest/state';
import { useSavePoiScreenshot } from '../../modules/pointOfInterest/createOrEdit/useSavePoiScreenshot';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { pointSize } from '@/state';

// const defaultSelectedColour = formatColor(DEFAULT_COLORS.selectedColour);

const floorLevelStyle: FloorLevelStyle = {
  color: 0xffffff,
  lineWidth: 0.05,
  opacity: 0.5,
};

const CreatePoiWidgetLazy = lazy(() =>
  import('@/components/Analysis/modules/pointOfInterest/CreatePoiWidget').then(
    ({ CreatePoiWidget }) => ({ default: CreatePoiWidget })
  )
);

// const CuboidWidgetLazy = lazy(() =>
//   import('@/components/Analysis/modules/cuboids/components').then(({ CuboidWidget }) => ({
//     default: CuboidWidget,
//   }))
// );

const IsolatedAssemblyLoadHelperLazy = lazy(() =>
  import('./IsolatedAssemblyLoadHelper').then(({ IsolatedAssemblyLoadHelper }) => ({
    default: IsolatedAssemblyLoadHelper,
  }))
);

const MapLocationLazy = lazy(() =>
  import('@/components/shared/MapLocation').then(({ MapLocation }) => ({
    default: MapLocation,
  }))
);

export const Inspection3dViewer = () => {
  // const globalCallbacks = useRecoilValue(state.globalCallbacks);
  const recoilInspectionMetadata = useRecoilValue(state.inspectionMetadata);

  const [selectedAssemblyId, setSelectedAssemblyId] = useRecoilState(state.selectedAssemblyId);
  const [selectedAssemblyName, setSelectedAssemblyName] = useRecoilState(
    state.selectedAssemblyName
  );
  const setSelectedAssemblyInfo = useSetRecoilState(state.selectedAssemblyInfo);
  const token = useRecoilValue(auth0TokenState);

  const structureId = useGetStructureId();
  const waitingForPoiClick = useRecoilValue(poiState.waitingForPoiClick);
  const structureDecks = useRecoilValue(state.structureDecks);
  const areDecksVisible = useRecoilValue(state.areDecksVisible);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const viewerCurrentSpherical = useRecoilValue(state.viewerCurrentSpherical);
  const setNominatedBlister = useSetRecoilState(state.governingValueByAssemblyId);

  const currentCuboid = useRecoilValue(cuboidState.currentCuboid);
  const cuboidClickMode = useRecoilValue(cuboidState.cuboidClickMode);

  const focalPoint = useRecoilValue(state.focalPointsByAssemblyId);
  const [cameraTarget, setCameraTarget] = useRecoilState(state.cameraTarget);
  const drawerLeft = useRecoilValue(state.drawerLeft);
  const structureRelationships = useRecoilValue(state.structureRelationship);
  // const structureLocations = useRecoilValue(state.structureLocations);
  const userCanUpdateAsset = useHavePermission(Permissions.UPDATE_ASSET);
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);
  const selectedPointOfInterestId = useRecoilValue(selectedPointOfInterestIdState);
  const cancelPOIAdd = useSetRecoilState(cancelPOIAddState);
  const setAllPOI = useSetRecoilState(poiState.structurePois);
  const [updatePoiLocation] = useUpdatePointOfInterestLocationMutation();
  const { handleSavePoiScreenshot } = useSavePoiScreenshot();
  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });

  const setPointSize = useSetRecoilState(pointSize);

  const { leftDrawerWidth } = useDrawerWidths();

  const { createCuboid } = useCreateAnalysisCuboid();

  const nodesPerAssemblyPath = useMemo(
    () => recoilInspectionMetadata?.nodesPerAssemblyPath,
    [recoilInspectionMetadata]
  );

  const [nodesToShow, setNodesToShow] = useState<Set<string> | undefined>(undefined);

  const [assemblyLazyQuery] = useAssemblyLazyQuery();

  const isolatedAssemblyNodesUrl = useMemo<string | undefined>(() => {
    if (!structureRelationships || !nodesPerAssemblyPath) {
      return undefined;
    }
    if (!selectedAssemblyId || selectedSpherical) {
      return undefined;
    }
    if (!structureRelationships.assemblyIdToAssembly3d) {
      return undefined;
    }
    const assembly3d = structureRelationships.assemblyIdToAssembly3d[selectedAssemblyId];
    if (assembly3d === undefined || assembly3d === null) {
      return undefined;
    }
    return getCloudfrontUrl(`${nodesPerAssemblyPath}/${assembly3d}.json`);
  }, [structureRelationships, selectedAssemblyId, selectedSpherical, nodesPerAssemblyPath]);

  useEffect(() => {
    if (!isolatedAssemblyNodesUrl) {
      setNodesToShow(undefined);
    }
  }, [isolatedAssemblyNodesUrl]);

  const { data: assemblyRecommendation } = useGetRecommendationsForAssemblyQuery({
    variables: {
      assemblyId: selectedAssemblyId!,
    },
    fetchPolicy: 'network-only',
    skip: !selectedAssemblyId,
  });
  // Temporary workaround for slow loading of bin and image files in Pakistan
  // 1. Get the point cloud and image files
  //     - Download horn-mountain-pd.zip from https://drive.google.com/drive/folders/13hdwkiJqbS0prPZi0WtW21av0UcHTm7h?usp=sharing
  //     - Extract to a folder e.g. C:/ - you should see 2 folders inside "horn-mountain-pd" - "images" with jpg files and "octree" with bin files
  //     - Rename the containing folder to 6298b4cf34349ba5acb519d4
  // 2. Host these files on a server within your own machine
  //     - Download and install LTS version of npm releavant to your OS https://nodejs.org/en/download/
  //     - From a command line run: npm install --global http-server
  //     - From a command line, navigate to the folder horn-mountain-pd
  //     - From within horn-mountain-pd in command line run: http-server --cors='*'
  //     - You should see some output saying Available on: http://127.0.0.1:8080
  // 3. Enable Fabric V2 to load files from your own machine
  //     - Go to Fabric V2 temporary version here, stay on Platforms list page for now - https://d2gplkryt7cldz.cloudfront.net/
  //     - Open Chrome dev tools (usually Ctrl+Shift+i)
  //     - Open Console tab (usually second from the left after Elements)
  //     - Run the following in Console tab (paste this in then hit Enter): localStorage.setItem("files-base-url", "http://localhost:8080")
  //     - Select Horn Mountain PD platform - this should now load point cloud and image files from your local machine

  // To use a http server instead of local machine
  // 1. Set up file/folder structure on http server as follows
  // /6298b4cf34349ba5acb519d4 - horn mountain PD
  //    /images - should contain all the .jpg files for horn mountain PD sphericals
  //    /octree - should contain octree_hierarch.json and .bin files for horn mountain PD
  // /61e7ca258f1a3cc4411029cc - marlin UD - lower priority, once horn mountain (folder above) is working end to end
  //    /images - should contain all the .jpg files for marlin UD sphericals
  //    /octree - should contain octree_hierarch.json and .bin files for marlin UD

  // 2. Enable Fabric V2 to load files from your own machine - this only works for Horn Mountain PD
  //     - Go to Fabric V2 temporary version here and log in, stay on Platforms list page for now - https://d2gplkryt7cldz.cloudfront.net/
  //     - Open Chrome dev tools (usually Ctrl+Shift+i)
  //     - Open Console tab (usually second from the left after Elements)
  //     - Run the following in Console tab and replace http://localhost:8080 with your http server url (paste this in then hit Enter): localStorage.setItem("files-base-url", "http://localhost:8080")
  //     - Select any platform - this should now load point cloud and image files from your local machine

  const octreePointCloudsJsonPath = useMemo(() => {
    const filesBaseUrl = localStorage.getItem('files-base-url');
    if (filesBaseUrl) {
      return [
        {
          path: `${filesBaseUrl}/${structureId}/octree/octree_hierarchy.json`,
          nodesToShow,
          contents: [{ 'part-id': true }, { 'corrosion-id': true }, { 'rusting-id': true }],
        },
      ];
    }
    if (recoilInspectionMetadata?.octreeResourcesPath) {
      return [
        {
          path: getCloudfrontUrl(recoilInspectionMetadata.octreeResourcesPath),
          nodesToShow,
          contents: [{ 'part-id': true }, { 'corrosion-id': true }, { 'rusting-id': true }],
        },
      ];
    }
    return [];
  }, [structureId, recoilInspectionMetadata, nodesToShow]);

  const { sphericalImages, rectilinearImages } = useImageSets(structureId);

  /**
   * Sets camera target to look at the entire selected assembly, only when assembly id changed or image view is closed
   */
  useEffect(() => {
    if (selectedSpherical) return;
    const currentFocalPoint =
      !cameraTarget && selectedAssemblyName ? focalPoint[selectedAssemblyName] : undefined;
    if (currentFocalPoint) {
      const { x, y, z } = currentFocalPoint;
      setCameraTarget({
        position: [x, y + 15, z + 5],
        lookAt: [x, y, z],
      });
    }
  }, [
    cameraTarget,
    focalPoint,
    selectedAssemblyId,
    selectedAssemblyName,
    selectedSpherical,
    setCameraTarget,
  ]);

  useEffect(() => {
    if (selectedSpherical !== undefined) {
      setPointSize(4);
    }
  }, [selectedSpherical, setPointSize]);

  useEffect(() => {
    const governingValueForAssembly =
      assemblyRecommendation?.assembly?.recommendation?.governingValue;

    if (governingValueForAssembly && selectedAssemblyId) {
      setNominatedBlister((current) => {
        return {
          ...current,
          [selectedAssemblyId]: governingValueForAssembly,
        };
      });
    }
  }, [
    assemblyRecommendation?.assembly?.recommendation?.governingValue,
    selectedAssemblyId,
    setNominatedBlister,
  ]);
  // const assemblyAnnotation3dArray = useMemo(() => {
  //   if (!selectedAssemblyId) return undefined;
  //   if (!selectedSpherical) return undefined;
  //   if (!structureRelationships?.assemblyIdAndLocationIdToAnnotation3dArray) return undefined;

  //   const locationId = structureLocations?.find(
  //     (location) => location.name === selectedSpherical.name
  //   )?.id;
  //   if (!locationId) return undefined;

  //   const assemblyEntry =
  //     structureRelationships?.assemblyIdAndLocationIdToAnnotation3dArray[selectedAssemblyId];

  //   return assemblyEntry && Object.hasOwn(assemblyEntry, locationId)
  //     ? assemblyEntry[locationId]
  //     : [];
  // }, [selectedAssemblyId, selectedSpherical, structureRelationships, structureLocations]);

  // useFocusOnSelectedAssembly(
  //   selectedAssemblyId,
  //   assemblyAnnotation3dArray,
  //   selectedSpherical,
  //   globalCallbacks,
  //   setCameraTarget
  // );

  const toggleSelectedAssembly = useCallback(
    (assemblyId?: string) => {
      if (!assemblyId) return;

      setSelectedAssemblyInfo((previousSelectedAssemblyInfo) => {
        if (previousSelectedAssemblyInfo === assemblyId) {
          return undefined;
        }

        return assemblyId;
      });
    },
    [setSelectedAssemblyInfo]
  );

  const handlePOIClick = useHandlePoiClick();
  const { annotation3dToAssemblyId } = usePointCloudClick();
  const { measurements, isMeasurementMenuVisible, handleMeasurementsOnPointSelect } =
    useMeasurementTool();

  const { isFindViewpointsToolActive, onPointSelect: handleFindLocationsOnPointSelect } =
    useFindViewpointsTool();

  const isCuboidToolActive = !currentCuboid && cuboidClickMode === CuboidClickMode.AddCuboid;

  const getPosition = useCallback((pickResult: PointPickResult) => {
    return pickResult
      .getPointAttributes(pickResult.pointIndex, ['position'])
      .get('position') as number[];
  }, []);

  const handleUpdatePoiLocation = useCallback(
    async (pickResult: PointPickResult) => {
      if (!selectedPointOfInterestId) {
        showSnackBar('No Point of Interest selected. Contact Support', 'error');
        return;
      }
      const position = getPosition(pickResult);
      const [x, y, z] = position;

      try {
        const { data } = await updatePoiLocation({
          variables: {
            input: {
              pointOfInterestId: selectedPointOfInterestId,
              point: { x, y, z },
              locationId: viewerCurrentSpherical?.id
            },
          },
        });
        const updatedPoi = data?.updatePointOfInterestLocation;
        if (updatedPoi) {
          setAllPOI((previous) =>
            previous.map((poi) => (poi.id === updatedPoi.id ? updatedPoi : poi))
          );
          showSnackBar('Point of Interest location updated successfully', 'success');
          await handleSavePoiScreenshot({ poiId: updatedPoi.id, refetchPoi: true });
        }
      } catch {
        showSnackBar('Something went wrong while updating location. Please try again', 'error');
      }
    },
    [
      getPosition,
      handleSavePoiScreenshot,
      selectedPointOfInterestId,
      setAllPOI,
      showSnackBar,
      updatePoiLocation,
      viewerCurrentSpherical?.id,
    ]
  );

  const onPointSelect = useCallback(
    (pickResult: PointPickResult) => {
      const { pointIndex, getPointAttributes } = pickResult;
      const attributeNames = ['position', 'annotation'];
      const pointAttributes = getPointAttributes(pointIndex, attributeNames);

      const annotation3dReference = pointAttributes.get('annotation');

      if (editPointOfInterest.formState === 'Update-Location') {
        cancelPOIAdd(undefined);
        handleUpdatePoiLocation(pickResult);
        return;
      }

      if (isCuboidToolActive) {
        const position = getPosition(pickResult);
        if (position && Array.isArray(position) && position.length === 3) {
          createCuboid(position as Number3);
        }
      }

      if (isMeasurementMenuVisible) {
        const position = pointAttributes.get('position');
        handleMeasurementsOnPointSelect(position);
        return;
      }
      if (isFindViewpointsToolActive) {
        const position = pointAttributes.get('position');
        handleFindLocationsOnPointSelect(position);
        return;
      }

      if (annotation3dReference && !Array.isArray(annotation3dReference)) {
        const assemblyId = annotation3dToAssemblyId(annotation3dReference);

        const fetchAssemblyName = async () => {
          try {
            const { data } = await assemblyLazyQuery({
              variables: {
                assemblyId,
              },
            });

            setSelectedAssemblyName(data?.assembly?.tagName);
          } catch {
            setSelectedAssemblyName(undefined);
          }
        };

        if (assemblyId !== selectedAssemblyId && editPointOfInterest.state !== 'Inactive') return;

        if (!selectedSpherical) toggleSelectedAssembly(assemblyId);
        if (assemblyId === selectedAssemblyId) {
          handlePOIClick(pickResult);
          return;
        }

        if (assemblyId && selectedSpherical) {
          setSelectedAssemblyId(assemblyId);
          fetchAssemblyName();
        }
      }
    },
    [
      handleUpdatePoiLocation,
      cancelPOIAdd,
      getPosition,
      editPointOfInterest.formState,
      isCuboidToolActive,
      createCuboid,
      isMeasurementMenuVisible,
      handleMeasurementsOnPointSelect,
      isFindViewpointsToolActive,
      handleFindLocationsOnPointSelect,
      annotation3dToAssemblyId,
      selectedAssemblyId,
      editPointOfInterest.state,
      selectedSpherical,
      toggleSelectedAssembly,
      handlePOIClick,
      setSelectedAssemblyId,
      assemblyLazyQuery,
      setSelectedAssemblyName,
    ]
  );

  const floorLevels = useMemo(() => {
    if (structureDecks && areDecksVisible) {
      const mappedFloorLevels: FloorLevelProps[] = structureDecks.map(
        ({ name, cadFloorHeight }) => ({
          name,
          label: {
            color: '#888888',
            fontSize: 16,
          },
          labelOffset: [1, 1, 1],
          zLevel: cadFloorHeight ?? 0,
          style: { ...floorLevelStyle },
          gridCellSize: 8,
          bboxPadding: FLOOR_LEVELS_BBOX_PADDING,
        })
      );
      return mappedFloorLevels;
    }
    return [];
  }, [structureDecks, areDecksVisible]);

  const poiMarkerSets = usePointOfInterestMarkers(selectedSpherical);
  const { material, pointCloudMaterials } = usePointCloudMaterials();
  const blisterPolygons = useBlisterPolygons();
  const blisterMarkers = useBlisterClickMarkers();
  const handleBlisterSphericalClick = useBlisterSphericalClick();
  const [assemblyLoading, setAssemblyLoading] = useState(false);
  const navbarHeight = useNavbarHeight();

  const findViewpointsMarker = useFindViewpointsMarker();
  useFindViewpointsFocusOnPoint();

  const markers = useMemo(() => {
    const actualBlisterMarkers = selectedSpherical && blisterMarkers ? blisterMarkers : [];
    return findViewpointsMarker
      ? [...actualBlisterMarkers, findViewpointsMarker]
      : actualBlisterMarkers;
  }, [selectedSpherical, blisterMarkers, findViewpointsMarker]);

  return (
    <>
      {!recoilInspectionMetadata && (
        <ViewerLoadingWrapper>
          <ViewerLoadingSpinnerContainer navbarHeight={navbarHeight}>
            <PageLoader background="dark" />
          </ViewerLoadingSpinnerContainer>
        </ViewerLoadingWrapper>
      )}

      {recoilInspectionMetadata && token && (
        <ParentContainer navbarHeight={navbarHeight}>
          <Container data-test-id={TestIds.PLATFORM_EXPLORER_INSIGHTS}>
            <Drawers onAssemblyLoading={setAssemblyLoading} />
            <AbyssViewerContextProvider>
              <AbyssViewer
                assemblyLoading={assemblyLoading}
                octreePointCloudsJsonPath={octreePointCloudsJsonPath}
                pointCloudMaterials={pointCloudMaterials}
                sphericalImages={sphericalImages}
                rectilinearImages={rectilinearImages}
                onSphericalViewClick={handleBlisterSphericalClick}
                svgMarkerSets={poiMarkerSets}
                floorLevels={floorLevels}
                measurementLines={measurements}
                material={material}
                onPointSelect={onPointSelect}
                polygons={blisterPolygons}
                markers={markers}
              >
                <SceneCuboids />
              </AbyssViewer>

              {isolatedAssemblyNodesUrl && (
                <Suspense>
                  <IsolatedAssemblyLoadHelperLazy
                    assemblyNodesUrl={isolatedAssemblyNodesUrl}
                    setNodesToShow={setNodesToShow}
                  />
                </Suspense>
              )}
            </AbyssViewerContextProvider>
            {selectedSpherical && (
              <PictureInPictureContainer
                openState={drawerLeft?.visibility}
                editState={waitingForPoiClick}
                horizontalOffset={leftDrawerWidth}
              >
                <Suspense>
                  <MapLocationLazy />
                </Suspense>
              </PictureInPictureContainer>
            )}
            {selectedAssemblyId && userCanUpdateAsset && (
              <Suspense>
                <CreatePoiWidgetLazy />
              </Suspense>
            )}
          </Container>
        </ParentContainer>
      )}
    </>
  );
};
