import { useCallback } from 'react';
import { CurrentImageProps, ImageType, Point3d } from '@abyss/3d-viewer';
import { GetStructureLocationsForInspectionDataLoaderQuery as AnalysisLocations } from '@/__generated__/graphql';
import { GetStructureLocationsForTaggingDataLoaderQuery as TaggingLocations } from '@/__generated__/graphql';
import { getCloudfrontUrl } from '@/utils/cloudfront';

const DEFAULT_DISTANCE = 500;
type Location = AnalysisLocations['allLocations'][0] | TaggingLocations['allLocations'][0];
export const mapLocationToCurrentImage = (
  location: Location | undefined,
  lookAt?: Point3d | undefined,
  fov?: number
): CurrentImageProps | undefined => {
  if (!location) return undefined;

  const { imageSensor, id, name, resourcePath, pose, distance } = location;
  if (!pose) return undefined;
  const baseImage: CurrentImageProps = {
    id,
    name,
    url: resourcePath ? getCloudfrontUrl(resourcePath) : '',
    position: [pose.x, pose.y, pose.z],
    rotation: [pose.roll, pose.pitch, pose.yaw],
    imageType: ImageType.Spherical,
    ...(lookAt ? { lookAt } : {}),
    ...(fov ? { fov } : {}),
  };

  if (imageSensor) {
    return {
      ...baseImage,
      imageType: ImageType.Rectilinear,
      width: imageSensor?.width,
      height: imageSensor?.height,
      focalLength: imageSensor?.focalLength,
      cx: imageSensor?.cx,
      cy: imageSensor?.cy,
      distance: distance ?? DEFAULT_DISTANCE,
    };
  }
  return {
    ...baseImage,
    imageType: ImageType.Spherical,
    imageYawOffset: pose?.yawOffset,
  };
};

export const useImageFromLocationId = (allViewpoints: Location[] | undefined) => {
  return useCallback(
    (viewpointId: string): CurrentImageProps | undefined => {
      const selectedViewpoint = allViewpoints?.find((viewpoint) => viewpoint.id === viewpointId);
      return mapLocationToCurrentImage(selectedViewpoint);
    },
    [allViewpoints]
  );
};
