/* eslint-disable react/jsx-one-expression-per-line */
import { Suspense, useState } from 'react';
import {
  Button,
  Tooltip,
  ClickAwayListener,
  styled,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import History from '@mui/icons-material/History';
import { PartSummary } from '@/types';

import { AllHistoriesQuery, useAllHistoriesQuery } from '@/__generated__/graphql';
import {
  PartsAddedToAssemblyEntry,
  PartSplitEntry,
  PartReviewedEntry,
  PartHiddenEntry,
} from './HistoryEntries';

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

type ToolTipContentEntryProps = {
  history: AllHistoriesQuery['allHistories'][0];
};

const ToolTipContentEntry = ({ history }: ToolTipContentEntryProps) => {
  switch (history.eventName) {
    case 'partsAddedToAssembly':
      return <PartsAddedToAssemblyEntry history={history} />;

    case 'partSplit':
      return <PartSplitEntry history={history} />;

    case 'partsHiddenFlagUpdated':
      return <PartHiddenEntry history={history} />;

    case 'partsReviewedFlagUpdated':
      return <PartReviewedEntry history={history} />;

    default:
      // eslint-disable-next-line unicorn/no-null
      return null;
  }
};

type ToolTipContentProps = {
  histories: AllHistoriesQuery;
};

const ToolTipContent = ({ histories }: ToolTipContentProps) => {
  return (
    <>
      {histories.allHistories.length > 0 ? (
        histories.allHistories.map((history) => {
          return <ToolTipContentEntry key={history.id} history={history} />;
        })
      ) : (
        <p>No history...</p>
      )}
    </>
  );
};

type PartHistoryProps = {
  selectedPart: PartSummary;
};

export const PartHistory = ({ selectedPart }: PartHistoryProps) => {
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const handleTooltipClose = () => {
    setHistoryOpen(false);
  };

  const { data, loading } = useAllHistoriesQuery({
    variables: {
      referenceId: selectedPart.id,
      eventNames: [
        'partsAddedToAssembly',
        'partSplit',
        'partsHiddenFlagUpdated',
        'partsReviewedFlagUpdated',
      ],
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <Suspense fallback={<p>History is on it`&apos;`s way!</p>}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <WhiteTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={historyOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={!loading && data ? <ToolTipContent histories={data} /> : <p>Loading...</p>}
          >
            <Button onClick={() => setHistoryOpen(!historyOpen)}>
              <History />
            </Button>
          </WhiteTooltip>
        </div>
      </ClickAwayListener>
    </Suspense>
  );
};
