// import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

// import { Checkbox, FormControlLabel } from '@mui/material';
import { Dock } from '@/components/shared/Dock';
// import { DockPanel } from '@/components/shared/DockPanel';
// import { DockPanelItem } from '@/components/shared/DockPanelItem';
import { Inspection3dViewer } from './Inspection3dViewer';
import { Navigation } from './Panels/Navigation';
import * as state from '@/state';
// import { PartId } from '@/types';
import { SelectedPart } from './Panels/SelectedPart';
import { IsolatedPart } from './Panels/IsolatedPart';
import { Visibility } from './Panels/Visibility';
import { ZoneComments } from './Panels/ZoneComments';
import { useNormalVisibilityRangeEffect } from './effects';
// import { useAddPartsToAssemblyInputMutation } from '@/__generated__/graphql';

export const InspectionExplorer = () => {
  const recoilInspectionMetadata = useRecoilValue(state.inspectionMetadata);
  const selectedZone = useRecoilValue(state.selectedZone);
  const token = useRecoilValue(state.auth0TokenState);
  const panelsMode = useRecoilValue(state.panelsMode);

  const shouldShowComments = useMemo(() => {
    return selectedZone;
  }, [selectedZone]);

  useNormalVisibilityRangeEffect();

  const topSidebarTabs = useMemo(() => {
    const defaultTabs = [
      { content: <Navigation />, id: 'zone_selector', title: 'Navigation' },
      { content: <Visibility />, id: 'visibility', title: 'Visibility' },
      // { content: <TaggingDebugging />, id: 'debuggin', title: 'Debugging' },
    ];
    if (shouldShowComments) {
      defaultTabs.push({ content: <ZoneComments />, id: 'comments', title: 'Comments' });
    }
    return panelsMode === 'IsolatePart'
      ? [{ content: <IsolatedPart />, id: 'isolated_part', title: 'Part' }]
      : defaultTabs;
  }, [panelsMode, shouldShowComments]);

  const bottomSidebarTabs = useMemo(() => {
    return panelsMode === 'IsolatePart'
      ? []
      : [{ content: <SelectedPart />, id: 'part_selector', title: 'Selected Part' }];
  }, [panelsMode]);

  return (
    <>
      {recoilInspectionMetadata && token && (
        <Dock topSidebarTabs={topSidebarTabs} bottomSidebarTabs={bottomSidebarTabs}>
          <Inspection3dViewer />
        </Dock>
      )}
    </>
  );
};

// const NO_PARTS_TO_TAG = 14000;
// const TAG_INTERVAL_LENGTH = 2000;

// const TaggingDebugging = () => {
//   const structureId = useRecoilValue(state.selectedStructureId);
//   const [debuggingOn, setDebuggingOn] = useState(false);

//   const checkboxCB = useCallback(() => setDebuggingOn((oldValue) => !oldValue), [setDebuggingOn]);

//   const structureRelationships = useRecoilValue(state.structureRelationships);

//   const [addPartsToAssembly] = useAddPartsToAssemblyInputMutation();

//   useEffect(() => {
//     let intervalId: ReturnType<typeof setInterval>;
//     if (!structureRelationships || !debuggingOn) return;
//     const partIdArray = structureRelationships && [...structureRelationships?.byPartId.keys()];

//     let rand: number;
//     const partArray: PartId[] = [];
//     for (let index = 0; index < NO_PARTS_TO_TAG; index++) {
//       rand = Math.random();
//       partArray.push(partIdArray[Math.floor(rand * partIdArray.length)]);
//     }

//     rand = Math.random();
//     const assemblyArray = [...structureRelationships.byAssemblyId.keys()];
//     const assemblyId = assemblyArray[Math.floor(rand * assemblyArray.length)];

//     console.log(`about to add ${partArray.length} parts to an assembly.`);

//     intervalId = setInterval(
//       () =>
//         addPartsToAssembly({
//           variables: {
//             input: {
//               partIds: partArray,
//               assemblyId,
//               structureId: structureId!,
//             },
//           },
//         }),
//       TAG_INTERVAL_LENGTH
//     );

//     return () => {
//       intervalId && clearInterval(intervalId);
//     };
//   }, [debuggingOn, addPartsToAssembly]);

//   return (
//     <DockPanel>
//       <DockPanelItem>
//         <FormControlLabel
//           control={<Checkbox checked={debuggingOn} onChange={checkboxCB} />}
//           label="Turn on tagging debugging"
//         />
//       </DockPanelItem>
//     </DockPanel>
//   );
// };
