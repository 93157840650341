/* eslint-disable unicorn/prefer-dom-node-remove */
/* eslint-disable unicorn/prefer-dom-node-append */
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as state from '@/state';
import { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as localState from './state';
import { LINE_FIXING_CSV_HEADERS } from '@/constants';

const downloadCsvFromArrayOfObjects = (arrayOfObjects: object[]) => {
  // Convert array of objects to CSV string

  const headers = ['tagName', 'originalTagname', 'superCategory'];
  const csvContent = [
    LINE_FIXING_CSV_HEADERS.join(','),
    ...arrayOfObjects.map((object) =>
      headers
        .map((header) => {
          const value = object[header as keyof typeof object];
          return JSON.stringify(value === undefined ? '' : value);
        })
        .join(',')
    ),
  ].join('\n');

  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a download link and trigger the download
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'fixed_assemblies.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const paperStyles = {
  width: '80%',
  height: '80%',
  padding: '2%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const FixedAssembliesTable = () => {

  const allAssemblies = useRecoilValue(state.allAssemblies);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [lineFixingState, setLineFixingState] = useRecoilState(localState.lineFixingState);

  const handleClose = () => {
    setLineFixingState({
      ...lineFixingState,
      state: 'closed',
    });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };
  // Any assembly that has an original tag name was updated via the line fixing feature
  const assembliesThatHaveBeenFixed = useMemo(() => {
    return allAssemblies.filter((assembly) => !!assembly?.originalTagname);
  }, [allAssemblies]);

  const paginatedAssemblies = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return assembliesThatHaveBeenFixed.slice(startIndex, startIndex + rowsPerPage);
  }, [assembliesThatHaveBeenFixed, page, rowsPerPage]);

  return (
    <Modal open={lineFixingState.state === 'table-modal-open'} onClose={handleClose}>
      <Paper sx={paperStyles}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" fontSize={20} fontWeight={600}>
            Tagging Management Sheet
          </Typography>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="body1" fontSize={16} mt={2} mb={3}>
          <Typography variant="body1" fontSize={16} fontWeight={600} sx={{ display: 'inline' }}>
            Note:
          </Typography>
          The table below contains a list of all the tags.
        </Typography>
        <Box sx={{ tableLayout: 'fixed', height: '70%', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {LINE_FIXING_CSV_HEADERS.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAssemblies.map((assembly) => (
                <TableRow key={assembly.id}>
                  <TableCell>{assembly.tagName}</TableCell>
                  <TableCell>{assembly.originalTagname}</TableCell>
                  <TableCell>{assembly.superCategory}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={assembliesThatHaveBeenFixed.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          sx={{ py: 1 }}
        />

        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Done
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              downloadCsvFromArrayOfObjects(assembliesThatHaveBeenFixed);
            }}
          >
            Download CSV
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
