import { Box, Button } from '@mui/material';
import { useMemo } from 'react';
import { DownloadIcon } from '@/assets/icons/DownloadIcon';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { FileNameTypes, FileTypes, GetAssemblyDetailsQuery } from '@/__generated__/graphql';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';

type Props = {
  assembly?: GetAssemblyDetailsQuery['assembly'];
};
export const IsometricDownloadButton = ({ assembly }: Props) => {
  const { showSnackBar } = useSnackBarMessage({});

  const isometricDrawing = useMemo(() => {
    return assembly?.files?.find(
      ({ name, type }) => name === FileNameTypes.IsometricDrawing && type === FileTypes.Pdf
    );
  }, [assembly?.files]);
  const handleDownload = async (url: string, fileName: string) => {
    const s3Url = getCloudfrontUrl(url);
    if (!s3Url) return;
    // Perform a HEAD request to check file accessibility
    const response = await fetch(s3Url, {
      method: 'HEAD',
      credentials: 'include', // it will add the signed cookies, without this cloudfront will throw 403 forbidden error
    });
    if (response.ok) {
      // Create a temporary link element to facilitate the download
      const link = document.createElement('a');
      link.href = s3Url;
      link.target = '_blank';
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
    } else {
      showSnackBar('Failed to download Isometric Drawing. Please contact support', 'error');
    }
  };
  return isometricDrawing ? (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        sx={{
          width: '90%',
          mx: 'auto',
        }}
        startIcon={<DownloadIcon />}
        onClick={() => {
          return handleDownload(isometricDrawing.resourcePath, isometricDrawing.fileName);
        }}
      >
        Isometric Drawing
      </Button>
    </Box>
  ) : (
    <></>
  );
};
