import { useMemo } from 'react';
import { useWindowSize } from './useWindowSize';

export const useDrawerWidths = () => {
  const screenSize = useWindowSize();
  const drawerWidths = useMemo(() => {
    const { width } = screenSize;
    if (width && width <= 1280) {
      return {
        leftDrawerWidth: 220,
        rightDrawerWidth: 240,
        doublePanelWidth: 540,
        riskDrawerWidth: 220,
      };
    }
    if (width && width <= 1728) {
      return {
        leftDrawerWidth: 230,
        rightDrawerWidth: 260,
        doublePanelWidth: 580,
        riskDrawerWidth: 230,
      };
    }
    return {
      leftDrawerWidth: 280,
      rightDrawerWidth: 328,
      doublePanelWidth: 828,
      riskDrawerWidth: 280,
    };
  }, [screenSize]);
  return drawerWidths;
};
