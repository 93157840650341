import { SyntheticEvent, useCallback } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { joinStringBy } from '@/utils';

const Dot = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1rem;
`;

// TODO: put settings to DB
const POITypeColourMap: { [poiType: string]: string } = {
  Hotbolting: '#3C58C7',
  Cups: '#6252A4',
  Other: '#798FC3',
  Paint_Damage: '#8137A6',
};

type Props = {
  id?: string;
  title?: string;
  options?: string[];
  initValue?: string;
  onAutoCompleteFieldChange?: (value: string, id?: string) => void;
  disabled?: boolean;
  optionsLimit?: number;
  sx?: object;
  disableClearable?: boolean;
};

export const AutoCompleteField = ({
  id,
  options,
  title,
  onAutoCompleteFieldChange,
  initValue,
  disabled,
  disableClearable = false,
  optionsLimit = 10,
  sx = {},
}: Props) => {
  const handleAutoCompleteFieldChange = useCallback(
    (_: SyntheticEvent<Element, Event>, value: string | null) => {
      if (onAutoCompleteFieldChange) {
        const selectedValue: string = value ?? '';
        onAutoCompleteFieldChange(selectedValue, id);
      }
    },
    [id, onAutoCompleteFieldChange]
  );
  const filterOptions = createFilterOptions<string>({ limit: optionsLimit });
  return (
    <Box
      sx={{
        width: '100%',
        ...sx,
      }}
    >
      <Autocomplete
        id="poi-type-selector"
        isOptionEqualToValue={(option, value) => option === value}
        options={options || []}
        filterOptions={filterOptions}
        defaultValue={initValue}
        disableListWrap
        disabled={disabled}
        autoHighlight
        onChange={handleAutoCompleteFieldChange}
        disableClearable={disableClearable}
        renderTags={(tags, getTagProps) => {
          return tags.map((tag, index) => <Chip {...getTagProps({ index })} label={tag} />);
        }}
        renderOption={(props, option) => {
          const joinedOption = joinStringBy(option, ' ', '_');
          return (
            option && (
              <Typography {...props}>
                <Dot style={{ backgroundColor: POITypeColourMap[joinedOption] }} />
                {option}
              </Typography>
            )
          );
        }}
        renderInput={(parameters) => <TextField {...parameters} label={title} variant="standard" />}
      />
    </Box>
  );
};
