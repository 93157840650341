import { atom } from 'recoil';
import {
  AbyssViewerCallbacks,
  CameraTargetProps,
  CurrentImageProps,
  VisibilityBoxProps,
} from '@abyss/3d-viewer';

import { GoverningValueBlister, PointOfInterest, AlarmLevels } from '@/__generated__/graphql';
import {
  GetAllDecksQuery,
  GetStructureLocationsForInspectionDataLoaderQuery,
  GetStructureForInspectionDataLoaderQuery,
  LocationLayer,
} from '@/__generated__/graphql';
import {
  Point3d,
  SnackbarMessage,
  Viewpoint,
  ToggleableDefectLegend,
  AllPointOfInterestTemplate,
  AnalysisDrawerRight,
  AnalysisDrawerLeft,
  AssemblyTemplateQuery,
  TemplateWithPOIs,
  AnalysisStructureRelationships,
  AssemblyTemplatesQuery,
} from '@/types';
import { DEFAULT_VISIBILITY_BOX } from '@/constants';
import {
  StructureForRiskAssessmentQuery,
  AllAssemblyTemplatesForRiskAssessmentQuery,
  GetTemplateByTypeQuery,
} from '@/__generated__/graphql';
import { UnitSystemEnum } from '@/__generated__/graphql';
import { ReportDetailsQuery, AllReportsListQuery } from '@/__generated__/graphql';
import { GetStructureFeaturesQuery } from '@/__generated__/graphql';

export const selectedStructureRiskAssessment = atom<
  StructureForRiskAssessmentQuery['structure'] | undefined
>({
  key: 'selectedStructureRiskAssessment',
  default: undefined,
});

export const inspectionMetadata = atom<
  GetStructureForInspectionDataLoaderQuery['structure'] | undefined
>({
  key: 'AnalysisInspectionMetadata',
  default: undefined,
});

export const partLegends = atom<{ [key: string]: string }>({
  key: 'PartLegends',
  default: {},
});

export const structureLocationsMap = atom<
  Map<string, GetStructureLocationsForInspectionDataLoaderQuery['allLocations'][0]> | undefined
>({
  key: 'AnalysisStructureLocationsMap',
  default: undefined,
});

export const structureLocations = atom<
  GetStructureLocationsForInspectionDataLoaderQuery['allLocations'] | undefined
>({
  key: 'AnalysisStructureLocations',
  default: undefined,
});

export const imageLayer = atom<LocationLayer | undefined>({
  key: 'AnalysisImageLayer',
  default: undefined,
});

export const structureDecks = atom<GetAllDecksQuery['allDecks']>({
  key: 'StructureDecks',
  default: undefined,
});

export const areSphericalsVisible = atom<boolean>({
  key: 'AnalysisAreSphericalsVisible',
  default: true,
});

export const areDecksVisible = atom<boolean>({
  key: 'areDecksVisible',
  default: false,
});

export const arePoisVisible = atom<boolean>({
  key: 'ArePoisVisible',
  default: false,
});

export const snackbarMessage = atom<SnackbarMessage>({
  key: 'AnalysisSnackbarMessage',
  default: { shouldShow: false },
});

export const globalCallbacks = atom<AbyssViewerCallbacks | undefined>({
  default: undefined,
  key: 'GlobalCallbacks',
});

export const cameraTarget = atom<CameraTargetProps | undefined>({
  default: undefined,
  key: 'AnalysisCameraTarget',
});

export const viewerCurrentSpherical = atom<CurrentImageProps | undefined>({
  default: undefined,
  key: 'AnalysisViewerCurrentSpherical',
});

export const viewerNextCurrentSpherical = atom<CurrentImageProps | undefined>({
  default: undefined,
  key: 'AnalysisViewerMextCurrentSpherical',
});

export const selectedAssemblyId = atom<string | undefined>({
  default: undefined,
  key: 'AnalysisSelectedAssemblyId',
});

export const selectedAssemblyIds = atom<string[]>({
  default: [],
  key: 'ESSheetSelectedAssemblyId',
});

export const unselectedAssemblyIds = atom<string[]>({
  default: [],
  key: 'ESSheetUnselectedAssemblyId',
});

export const selectedAssemblyName = atom<string | undefined>({
  default: undefined,
  key: 'AnalysisSelectedAssemblyName',
});

export const showPointCloudInSpherical = atom<boolean>({
  default: true,
  key: 'AnalysisshowSphericalInPointCloud',
});

export const showPointsOutsideViewpoint = atom<boolean>({
  default: false,
  key: 'AnalysisShowPointsOutsideViewpoint',
});

export const pointSize = atom<number>({
  default: 2,
  key: 'AnalysisPointSize',
});

export const hoveredAssemblyId = atom<string | undefined>({
  default: undefined,
  key: 'AnalysisHoveredAssemblyId',
});

export const unitSystem = atom<UnitSystemEnum>({
  default: UnitSystemEnum.Imperial,
  key: 'AnalysisUnitSystem',
});

export const systemTest = atom<boolean>({
  default: false,
  key: 'SystemTest',
});

export const viewpointsByAssemblyId = atom<{ [assemblyId: string]: Viewpoint[] }>({
  default: {},
  key: 'AnalysisViewpointsByAssemblyId',
});

export const focalPointsByAssemblyId = atom<{ [assemblyId: string]: Point3d }>({
  default: {},
  key: 'AnalysisFocalPointsByAssemblyId',
});

export const blisterPolygonVisible = atom<boolean>({
  default: true,
  key: 'AnalysisBlisterPolygonVisible',
});

export const governingValueByAssemblyId = atom<{
  [assemblyId: string]: {
    blister?: Partial<PointOfInterest> | null;
    calculations?: GoverningValueBlister | null;
    pointOfInterest?: { id: string } | null;
  };
}>({
  default: {},
  key: 'AnalysisGoverningValueByAssemblyId',
});

export const alarmLevelByAssemblyId = atom<{
  [assemblyId: string]: {
    alarmLevel: AlarmLevels;
    wasAnalysisAlarmLevelManuallySet?: boolean | null;
  };
}>({
  default: {},
  key: 'AnalysisAlarmLevelByAssemblyId',
});

export const allAssemblies = atom<unknown[]>({
  default: [],
  key: 'AnalysisAllAssemblies',
});

export const filteredAssemblyIds = atom<string[]>({
  default: [],
  key: 'AnalysisFilteredAssemblyIds',
});

export const displayAnimationEditor = atom<boolean>({
  default: false,
  key: 'AnalysisDisplayAnimationEditor',
});

export const selectedStructureDefectsLegends = atom<Array<ToggleableDefectLegend> | undefined>({
  default: undefined,
  key: 'AnalysisSelectedStructureDefectsLegends',
});

export const allPointOfInterestTemplates = atom<AllPointOfInterestTemplate>({
  default: [],
  key: 'AllPointOfInterestTemplates',
});

export const poiTemplateIcons = atom<{ name: string; icon: string; color: string }[]>({
  default: [],
  key: 'poiTemplateIcons',
});

export const allAssemblyTemplates = atom<AssemblyTemplatesQuery>({
  default: undefined,
  key: 'AllAssemblyTemplates',
});

export const assemblyTemplate = atom<AssemblyTemplateQuery>({
  default: undefined,
  key: 'AssemblyTemplate',
});

export const selectedAssemblyTemplateId = atom<string | undefined>({
  default: undefined,
  key: 'SelectedTemplateId',
});

export const visitedViewpointIDs = atom<Array<string>>({
  default: [],
  key: 'lastSelectedSphericalID',
});

export const selectedAssemblyInfo = atom<string | undefined>({
  default: undefined,
  key: 'SelectedPart',
});

export const drawerLeft = atom<AnalysisDrawerLeft>({
  key: 'AnalysisDrawerLeft',
  default: {
    visibility: false,
    query: true,
  },
});

export const drawerRight = atom<AnalysisDrawerRight>({
  key: 'AnalysisDrawerRight',
  default: {
    equipment: true,
    aitInsight: false,
    measuringTool: false,
    findViewpointsTool: false,
  },
});

export const drawerRightPreviousState = atom<keyof AnalysisDrawerRight>({
  default: 'equipment',
  key: 'DrawerRightPreviousState',
});

export const reportsList = atom<AllReportsListQuery['allReports']>({
  key: 'AnalysisAllReports',
  default: [],
});

export const filteredReportsList = atom<AllReportsListQuery['allReports']>({
  key: 'AnalysisFilteredReports',
  default: [],
});

export const selectedReport = atom<ReportDetailsQuery['report'] | undefined>({
  key: 'AnalysisSelectedReport',
  default: undefined,
});

export const selectedReportId = atom<string>({
  key: 'AnalysisSelectedReportId',
  default: '',
});

export const currentVisibilityBox = atom<VisibilityBoxProps>({
  default: DEFAULT_VISIBILITY_BOX,
  key: 'AnalysisCurrentVisibilityBox',
});

export const selectedTemplateFiltersState = atom<{ id: string; value: string[] }[]>({
  default: [],
  key: 'SelectedTemplateFiltersState',
});

export const selectedFilteredDeckIds = atom<string[]>({
  default: [],
  key: 'SelectedFilteredDeckIds',
});

export const selectedFilteredPaintRegionIds = atom<string[]>({
  default: [],
  key: 'SelectedFilteredPaintRegionIds',
});

export const applyFilters = atom<boolean>({
  default: false,
  key: 'ApplyFiltersState',
});

export const selectedReportEquipment = atom<{
  id: string;
  name: string;
}>({
  key: 'AnalysisSelectedEquipment',
  default: {
    id: '',
    name: '',
  },
});

export const showPaintRegionsState = atom<boolean>({
  key: 'ShowPaintRegions',
  default: false,
});

export const showCameraPositionsState = atom<boolean>({
  key: 'ShowCameraPositions',
  default: true,
});

export const heatmapValue = atom<string>({
  key: 'HeatmapValue',
  default: '',
});

export const markerScaleSize = atom<number>({
  default: 2,
  key: 'MarkerScaleSize',
});

export const heatmapSvgState = atom<string>({
  key: 'HeatmapSvgState',
  default: '',
});

export const allGroups = atom<{ name: string; value: string }[]>({
  key: 'UserManagementGroups',
  default: [],
});

export const allRoles = atom<{ name: string; value: string }[]>({
  key: 'UserManagementRoles',
  default: [],
});

export const paintRegionOutline = atom<string>({
  key: 'PaintRegionOutline',
  default: '',
});

export const hasRiskAssessment = atom<boolean>({
  default: false,
  key: 'hasRiskAssessment',
});

export const isUncontextualised = atom<boolean>({
  default: undefined,
  key: 'isUncontextualised',
});

export const hasRgb = atom<boolean>({
  default: false,
  key: 'hasRgb',
});

export const structureRelationship = atom<AnalysisStructureRelationships>({
  default: {
    annotationIdToAssemblyId: {},
    annotationIdToAnnotation3d: {},
    annotation3dToAnnotationId: {},
    assemblyIdToAnnotation3dArray: {},
    assemblyIdAndLocationIdToAnnotation3dArray: {},
    assemblyIdToAssembly3d: {},
    partsByAnnotationId: {},
    parts: {},
  },
  key: 'AnalysisStructureRelationships',
});

export const blendOpacity = atom<number>({
  default: 255, // no opacity, no rgb (green point cloud)
  key: 'blendOpacity',
});

export const corrosionOctreeAttributeName = atom<string>({
  default: 'defect',
  key: 'octreeAttributeName',
});

export const pointOfInterestTemplates = atom<TemplateWithPOIs[]>({
  default: [],
  key: 'pointOfInterestTemplates',
});

export const selectedRiskAssessmentFilters = atom<{ id: string; value: string }[]>({
  default: [],
  key: 'SelectedRiskAssessmentFilters',
});

export const riskAssessmentSearchTerm = atom<string>({
  default: '',
  key: 'RiskAssessmentSearchTerm',
});

export const enableViewpoints = atom<boolean | undefined>({
  default: undefined,
  key: 'enableViewpoints',
});

export const riskAssessmentAssemblyTemplate = atom<
  AllAssemblyTemplatesForRiskAssessmentQuery['allTemplates'][0] | undefined
>({
  default: undefined,
  key: 'RiskAssessmentAssemblyTemplate',
});

export const structureTemplate = atom<GetTemplateByTypeQuery['allTemplates'][0] | undefined>({
  default: undefined,
  key: 'StructureTemplate',
});

export const isTemplateFieldDataUpdated = atom<boolean>({
  default: false,
  key: 'templateFieldDataUpdated',
});

export const maintainSelectedEquipments = atom<boolean>({
  default: false,
  key: 'maintainSelectedEquipments',
});

export const isAlarmLevelDialogOpen = atom<boolean>({
  default: false,
  key: 'isAlarmLevelDialogOpen',
});

export const assembliesCount = atom<number>({
  default: 0,
  key: 'assembliesCount',
});

export const assembliesLoading = atom<boolean>({
  default: undefined,
  key: 'assembliesLoading',
});

export const pickingMapsUpdateSequence = atom<number>({
  default: 0,
  key: 'PickingMapsUpdateSequence',
});

export const structureFeatures = atom<GetStructureFeaturesQuery['structure']>({
  default: undefined,
  key: 'StructureFeatures',
});
