import {
  GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery,
  GetAssembliesWithTemplateFieldDataQuery,
  GetAssemblyDetailsQuery,
  useGetAssembliesByRiskAssessmentFiltersLazyQuery,
  useGetAssembliesWithTemplateFieldDataLazyQuery,
  useGetAssemblyContextualizedStatusQuery,
} from '@/__generated__/graphql';
import { useAssemblyVisibilityBox } from '@/components/Analysis/modules/assembly';
import { AllEquipmentBackButton } from '@/components/Analysis/modules/pointOfInterest';
import * as state from '@/components/Analysis/state';
import { selectedTemplateFiltersState } from '@/components/Analysis/state';
import { useResetSelectedFilters } from '@/hooks';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { abyssColors } from '@/theme/colors';
import { EmptyStatePlaceholder } from '../EmptyStatePlaceholder';
import { EquipmentCard } from './EquipmentCard';
import { EquipmentDetails } from './EquipmentDetails';
import { IsometricDownloadButton } from './EquipmentDetails/IsometricDownloadButton';
import { EquipmentTabSkeleton } from './EquipmentTabSkeleton';
import { RiskEquipmentCard } from './RiskEquipmentCard';

type Assemblies =
  | GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery['getAssembliesByTemplateFieldsFilters']
  | undefined;

type CurrentAssemblies =
  | GetAssembliesWithTemplateFieldDataQuery['getAssembliesByTemplateFieldsFilters']
  | [];

type EquipmentsTabProps = {
  assemblies: Assemblies;
  changeTab: (selectedTabIndex: number) => void;
  loading?: boolean;
  assemblyDetails?: GetAssemblyDetailsQuery['assembly'];
  assemblyDetailsLoading?: boolean;
};
const selectedAssemblyCardStyles = {
  backgroundColor: '#3C3C3C',
  color: 'white',
  mb: '15',
};

export const EquipmentsTab = ({
  assemblies,
  changeTab,
  loading,
  assemblyDetails,
  assemblyDetailsLoading,
}: EquipmentsTabProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedAssemblyId, setSelectedAssemblyId] = useRecoilState(state.selectedAssemblyId);
  const setSelectedAssemblyName = useSetRecoilState(state.selectedAssemblyName);
  const selectedAssemblyTemplate = useRecoilValue(state.riskAssessmentAssemblyTemplate);
  const allTemplates = useRecoilValue(state.allAssemblyTemplates);
  const riskSearchTerm = useRecoilValue(state.riskAssessmentSearchTerm);
  const selectedTemplateFilters = useRecoilValue(selectedTemplateFiltersState);
  const [sortBy, setSortBy] = useState('HighToLow');
  const [selectedAssemblyInfo, setSelectedAssemblyInfo] = useRecoilState(
    state.selectedAssemblyInfo
  );
  const setSelectedTemplateFilters = useSetRecoilState(state.selectedTemplateFiltersState);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const selectedAssemblyTemplateId = useRecoilValue(state.selectedAssemblyTemplateId);
  const structureId = useGetStructureId();
  const [fetchAssembliesData, { data, loading: assembliesDataLoading }] =
    useGetAssembliesWithTemplateFieldDataLazyQuery();
  const [fetchAssemblyData, { data: selectedAssemblyData }] =
    useGetAssembliesWithTemplateFieldDataLazyQuery();
  const [currentAssemblies, setCurrentAssemblies] = useState<CurrentAssemblies>([]);
  const selectedFilters = useRecoilValue(state.selectedRiskAssessmentFilters);
  const [fetchFilteredAssemblies, { data: riskAssemblies }] =
    useGetAssembliesByRiskAssessmentFiltersLazyQuery();
  const router = useRouter();
  useAssemblyVisibilityBox(assemblies);
  useResetSelectedFilters();

  const selectedTemplate = useMemo(
    () => allTemplates?.find((template) => template.id === selectedAssemblyTemplateId),
    [allTemplates, selectedAssemblyTemplateId]
  );

  const isAtInsightsPage = useMemo(() => router.pathname.includes('insights'), [router.pathname]);
  const isAtESSheet = useMemo(() => router.pathname.includes('risk'), [router.pathname]);

  const isAtViewerPage = useMemo(() => router.pathname.includes('viewer'), [router.pathname]);

  const assemblyCount = useMemo(() => {
    if (isAtESSheet) {
      return riskAssemblies?.getRiskAssembliesCount?.count ?? 0;
    }
    if (assemblies?.length) {
      return assemblies.length;
    }
    return 0;
  }, [assemblies?.length, isAtESSheet, riskAssemblies?.getRiskAssembliesCount]);

  const assembliesOnCurrentPage: Assemblies = useMemo(
    () => assemblies?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [assemblies, page, rowsPerPage]
  );

  const isSelectedAssemblyExistOnPointCloud = useMemo(() => {
    if (
      selectedAssemblyInfo &&
      assemblies?.find((assembly) => assembly.id === selectedAssemblyInfo)
    ) {
      return true;
    }
    return false;
  }, [selectedAssemblyInfo, assemblies]);

  const { data: assemblyContextualizedStatus, loading: contextloading } =
    useGetAssemblyContextualizedStatusQuery({
      variables: {
        assemblyId: selectedAssemblyId!,
      },
      skip: !selectedAssemblyId,
    });

  useEffect(() => {
    if (
      assemblyContextualizedStatus?.assembly?.isUncontextualized &&
      !contextloading &&
      isAtViewerPage
    ) {
      setSelectedAssemblyId(undefined);
      setSelectedAssemblyName(undefined);
      setSelectedAssemblyInfo(undefined);
      setSelectedTemplateFilters([]);
    }
  }, [
    assemblyContextualizedStatus?.assembly?.isUncontextualized,
    contextloading,
    setSelectedAssemblyId,
    setSelectedAssemblyName,
    isAtViewerPage,
    setSelectedAssemblyInfo,
    setSelectedTemplateFilters,
  ]);

  useEffect(() => {
    if (
      selectedAssemblyInfo &&
      selectedAssemblyTemplateId &&
      isSelectedAssemblyExistOnPointCloud &&
      !isAtESSheet
    ) {
      fetchAssemblyData({
        variables: {
          input: {
            structureId,
            templateId: selectedAssemblyTemplateId,
            assemblyIds: [selectedAssemblyInfo],
          },
        },
      });
    }
  }, [
    fetchAssemblyData,
    selectedAssemblyInfo,
    selectedAssemblyTemplateId,
    structureId,
    isSelectedAssemblyExistOnPointCloud,
    isAtESSheet,
  ]);

  useEffect(() => {
    if (isAtESSheet && selectedAssemblyTemplate?.id) {
      fetchFilteredAssemblies({
        variables: {
          input: {
            structureId,
            templateId: selectedAssemblyTemplate.id,
            selectedFilters,
            searchText: riskSearchTerm,
          },
          pageInfo: {
            skip: page * rowsPerPage,
            limit: rowsPerPage,
            sortBy: 'templateFieldData.risk_damage_class',
            sortDir: sortBy === 'HighToLow' ? -1 : 1,
          },
        },
      });
    }
  }, [
    fetchAssemblyData,
    selectedAssemblyInfo,
    selectedAssemblyTemplateId,
    structureId,
    isSelectedAssemblyExistOnPointCloud,
    isAtESSheet,
    fetchFilteredAssemblies,
    selectedFilters,
    riskSearchTerm,
    page,
    rowsPerPage,
    selectedAssemblyTemplate?.id,
    sortBy,
  ]);

  useEffect(() => {
    setCurrentAssemblies(
      isAtESSheet
        ? riskAssemblies?.getAssembliesByRiskAssessmentFilters ?? []
        : data?.getAssembliesByTemplateFieldsFilters ?? []
    );
  }, [assemblyCount, data?.getAssembliesByTemplateFieldsFilters, isAtESSheet, riskAssemblies]);

  useEffect(() => {
    const assemblyIds = assembliesOnCurrentPage?.map((assembly) => assembly.id);
    if (selectedAssemblyTemplateId && assemblyCount > 0) {
      fetchAssembliesData({
        variables: {
          input: {
            structureId,
            templateId: selectedAssemblyTemplateId,
            assemblyIds,
          },
        },
      });
    }
  }, [
    assembliesOnCurrentPage,
    assemblyCount,
    fetchAssembliesData,
    selectedAssemblyTemplateId,
    structureId,
  ]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [selectedTemplateFilters]);

  const handleEquipmentCardClick = useCallback(
    (assemblyId: string, assemblyName: string) => {
      if (!isAtESSheet && !assemblies) return;

      setSelectedAssemblyId(assemblyId);
      setSelectedAssemblyName(assemblyName);
    },
    [assemblies, isAtESSheet, setSelectedAssemblyId, setSelectedAssemblyName]
  );

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value);
  };

  const highlightedAssemblyCardInfo = useMemo(() => {
    if (isSelectedAssemblyExistOnPointCloud) {
      return selectedAssemblyData?.getAssembliesByTemplateFieldsFilters?.[0];
    }
    return undefined;
  }, [selectedAssemblyData, isSelectedAssemblyExistOnPointCloud]);

  if (!selectedAssemblyId && (isAtInsightsPage || (isAtViewerPage && selectedSpherical))) {
    return (
      <Box sx={{ marginTop: '60%' }}>
        <EmptyStatePlaceholder type={isAtInsightsPage ? 'equipmentTable' : 'equipmentViewer'} />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: abyssColors.primary[50] }}>
      {loading && <EquipmentTabSkeleton />}

      {!loading && (
        <Box
          sx={{
            padding: 0,
            overflowY: 'auto',
            height: 'calc(100vh / var(--zoom) - 11rem)',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
          data-testid="equipment-tab-container"
        >
          {selectedAssemblyId ? (
            <>
              <AllEquipmentBackButton
                changeTab={changeTab}
                retainAssemblyView={!!selectedSpherical}
                customLabel={isAtESSheet ? "All equipment's" : ''}
              />
              <EquipmentDetails
                assembly={assemblyDetails}
                isLoadingAssemblyDetails={assemblyDetailsLoading}
                isUncontextualized={!!assemblyDetails?.isUncontextualized}
              />
              {assemblyDetails && !assemblyDetails?.isUncontextualized && (
                <IsometricDownloadButton assembly={assemblyDetails} />
              )}
            </>
          ) : (
            <>
              <Box
                data-testid="equipment-cards-container"
                sx={{
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                  '&::-webkit-scrollbar': { display: 'none' },
                }}
              >
                <Box margin="2.4rem">
                  <Typography
                    fontSize="2.4rem"
                    color={abyssColors.primary[500]}
                    fontWeight={500}
                    lineHeight="normal"
                    display="inline-flex"
                    alignItems="flex-end"
                    gap="0.8rem"
                  >
                    {assemblyCount}
                    <span>
                      <Typography fontSize="1.4rem" color={abyssColors.primary[400]}>
                        {`Total ${isAtESSheet ? 'equipment' : 'components'} found`}
                      </Typography>
                    </span>
                  </Typography>
                </Box>
                {/* Display is set to none for now, will enable again when data is available for proper functionality */}
                <Box display={isAtESSheet ? 'block' : 'none'}>
                  <FormControl
                    variant="standard"
                    sx={{ px: '2.4rem', mb: '3.2rem', width: '100%', position: 'relative' }}
                  >
                    <InputLabel sx={{ marginLeft: '2.4rem' }}>Sort by</InputLabel>
                    <Select
                      defaultValue="HighToLow"
                      value={sortBy}
                      label="Sort by"
                      onChange={handleSortChange}
                    >
                      <MenuItem value="HighToLow">{`${isAtESSheet ? 'DC value' : 'Alarm level'} high to low`}</MenuItem>
                      <MenuItem value="LowToHigh">{`${isAtESSheet ? 'DC value' : 'Alarm level'} low to hight`}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Stack gap={isAtESSheet ? '0.2rem' : 0}>
                  {highlightedAssemblyCardInfo && (
                    <EquipmentCard
                      sx={selectedAssemblyCardStyles}
                      key={highlightedAssemblyCardInfo.id}
                      assembly={highlightedAssemblyCardInfo}
                      handleClick={() => {
                        handleEquipmentCardClick(
                          highlightedAssemblyCardInfo.id,
                          highlightedAssemblyCardInfo.tagName
                        );
                      }}
                    />
                  )}
                  {assembliesDataLoading && <EquipmentTabSkeleton />}
                  {!assembliesDataLoading &&
                    !isAtESSheet &&
                    currentAssemblies.map((assembly) => (
                      <EquipmentCard
                        equipmentCardTemplate={selectedTemplate?.equipmentCard}
                        key={assembly.id}
                        assembly={assembly}
                        handleClick={() => handleEquipmentCardClick(assembly.id, assembly.tagName)}
                      />
                    ))}
                  {!assembliesDataLoading &&
                    isAtESSheet &&
                    currentAssemblies.map((assembly) => (
                      <RiskEquipmentCard
                        key={assembly.id}
                        assembly={assembly}
                        handleClick={() => {
                          handleEquipmentCardClick(assembly.id, assembly.tagName);
                        }}
                      />
                    ))}
                </Stack>
                <TablePagination
                  rowsPerPageOptions={[
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '100', value: 100 },
                    { label: 'All', value: assemblyCount },
                  ]}
                  count={assemblyCount ?? 0}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage="Rows"
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    '.MuiTablePagination-toolbar': { pl: 1.5 },
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
